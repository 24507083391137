import React from "react";
import { Label, Pie, PieChart, Legend } from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart";
import UserIcon from "../../assets/images/piechart-icon-one.svg";
const chartData = [
  { browser: "Returning", customers: 250, fill: "var(--color-safari)" },
  { browser: "New", customers: 100, fill: "var(--color-chrome)" },
];

const chartConfig = {
  customers: {
    label: "Customers",
  },
  chrome: {
    label: "Returning",
    color: "#E9EDF0", // Default chrome color
  },
  safari: {
    label: "New",
    color: "#1A2233", // Safari color
  },
};

function CustomLegend({ payload }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "32px",
        marginTop: "24px",
        marginBottom: "24px",
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`legend-item-${index}`}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: entry.color,
              borderRadius: "100%",
            }}
          ></div>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "700",
              leading: "16px",
              color: "#767676",
            }}
          >
            {entry.value}{" "}
            <span className="text-[#404040]">
              {entry.payload.customers.toLocaleString()}
            </span>
          </span>
        </div>
      ))}
    </div>
  );
}

export function Component() {
  const totalCustomerRetention = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.customers, 0);
  }, []);

// Percentage calculations for New and Returning customers
const percentages = React.useMemo(() => {
  return chartData.map((data) => ({
    ...data,
    percentage: ((data.customers / totalCustomerRetention) * 100).toFixed(1), // Round to 1 decimal
  }));
}, [totalCustomerRetention]);
  return (
    <Card className="flex flex-col 2xl:flex-1 w-[25vw] 2xl:w-[17vw] h-[310px]">
      <CardHeader className="flex justify-center items-center">
        <CardTitle className="text-center font-[700] text-[14px] leading-[18px] text-[#2E2E30]">
          Customer Retention Rate
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-1 items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square w-full max-w-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              startAngle={0}
              endAngle={180}
              dataKey="customers"
              nameKey="browser"
              innerRadius={65} // Inner radius for the donut shape
              outerRadius={82} // Outer radius for the donut shape
              //   strokeWidth={5}
              cornerRadius={10}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <>
                        {/* Total Customer Retention */}
                        {/* User Icon */}
                        <image
                          href={UserIcon}
                          x={viewBox.cx - 19} // Centering the icon
                          y={viewBox.cy - 30} // Centering the icon
                          width="37"
                          height="37"
                          alt="User Icon"
                        />

                        {/* Total */}
                        <text
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 25} // Adjusted Y position for text below the emoji
                          textAnchor="middle"
                          dominantBaseline="middle"
                          className="fill-[#767676] text-[12px] font-[700] leading-[16px]"
                        >
                          Total  {totalCustomerRetention.toLocaleString()}
                        </text>
                        <text
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 55} // Adjusted Y position for text above the emoji
                          textAnchor="middle"
                          dominantBaseline="middle"
                          className="fill-muted-foreground text-[26px] font-[700] leading-[34px] text-[#404040] "
                        >
                           {
                            percentages.find((d) => d.browser === "New")
                              ?.percentage
                          }%
                        </text>
                      </>
                    );
                  }
                }}
              />
            </Pie>

            <Legend
              align="left"
              verticalAlign="bottom"
              content={<CustomLegend />}
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
