import * as React from "react";
import UserIcon from "../../assets/images/piechart-icon-one.svg"
import { Label, Pie, PieChart, Legend, ResponsiveContainer } from "recharts";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart";

// Chart data with custom colors for Chrome and Safari
const chartData = [
  { browser: "Returning", customers: 250, fill: "var(--color-safari)" },
  { browser: "New", customers: 100, fill: "var(--color-chrome)" },
];

const chartConfig = {
  customers: {
    label: "Customers",
  },
  chrome: {
    label: "Returning",
    color: "#E9EDF0", // Default chrome color
  },
  safari: {
    label: "New",
    color: "#1A2233", // Safari color
  },
};
function CustomLegend({ payload }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "32px",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        {payload.map((entry, index) => (
          <div
            key={`legend-item-${index}`}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                borderRadius: "100%",
              }}
            ></div>
            <span style={{ fontSize: "12px", fontWeight: "700", leading: "16px", color: "#767676" }}>
              {entry.value} <span className="text-[#404040]">{entry.payload.customers.toLocaleString()}</span>
            </span>
          </div>
        ))}
      </div>
    );
  }

export function Component() {
  const totalCustomers = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.customers, 0);
  }, []);

  return (
    <Card className="flex flex-col 2xl:flex-1 w-[25vw] 2xl:w-[17vw] h-[310px]">
      <CardHeader className="flex justify-center items-center ">
        <CardTitle className="text-center font-[700] text-[14px] leading-[18px] text-[#2E2E30]">
          Customers
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
        <ResponsiveContainer>
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              startAngle={-70}
              endAngle={250}
              dataKey="customers"
              nameKey="browser"
              innerRadius={65} // Inner radius for the donut shape
              outerRadius={82} // Outer radius for the donut shape
            //   strokeWidth={5}
              cornerRadius={10}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                        <>
                        <image
                          href={UserIcon}
                          x={viewBox.cx - 12} // Centering the icon
                          y={viewBox.cy - 24} // Centering the icon
                          width="24"
                          height="24"
                          alt="User Icon"
                        />
                        <text
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 32}
                          textAnchor="middle"
                          dominantBaseline="middle"
                          className="fill-muted-foreground text-[30px] font-[700] leading-[39px] text-[#404040]"
                        >
                          {totalCustomers.toLocaleString()}
                        </text>
                      </>
                    );
                  }
                }}
              />
              
            </Pie>
            <Legend
              align="left"
              verticalAlign="bottom"
              content={<CustomLegend />}
            />
          </PieChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
