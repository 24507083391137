import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { verifyEmail } from "../store/authSlice";
import { toast } from "sonner";
import { HelmetProvider, Helmet } from "react-helmet-async";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams(); // Extract query parameters
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = searchParams.get("token"); // Get 'token' from the URL

    if (token) {
      dispatch(verifyEmail(token))
        .unwrap() // Wait for the action to resolve or reject
        .then(() => {
          toast.success("Email verified successfully!");
          navigate("/login"); // Redirect to login page after verification
        })
        .catch((err) => {
          toast.error(err || "Verification failed");
          navigate("/login"); // Redirect to login even on failure
        });
    } else {
      toast.error("Invalid or missing verification token.");
      navigate("/login"); // Redirect to login if token is missing
    }
  }, [dispatch, searchParams, navigate]);

  return (
     <HelmetProvider>
          <Helmet>
            <title>Hamzaa Verify Email</title>
            <meta name="description" content="Hamzaa Verify Email page" />
          </Helmet>
    <div className="flex justify-center items-center h-[100vh] pt-[50px] bg-[#F8FAFF]">
      <h2 className="text-center text-[30px] text-primary">Verifying Your Email...</h2>
    </div>
    </HelmetProvider>
  );
};

export default VerifyEmail;
