import React from "react";
import Button from "./button";
import NewsOne from "../assets/images/news-one.png"
import NewsTwo from "../assets/images/news-two.png"

const NewsInsights = () => {
  const news = [
    {
      id: 1,
      name: "Automotive Industry News",
      description:
        "“Electric Truck Wars Heat Up: Ford Announces New F-series Lightning Pro Features ...",
      link: "",
      image: NewsOne,
    },
    {
      id: 2,
      name: "Automotive Industry News",
      description:
        "“Electric Truck Wars Heat Up: Ford Announces New F-series Lightning Pro Features ...",
      link: "",
      image: NewsTwo
    },
    // {
    //   id: 3,
    //   name: "Automotive Industry News",
    //   description:
    //     "“Electric Truck Wars Heat Up: Ford Announces New F-series Lightning Pro Features ...",
    //   link: "",
    // },
  ];
  return (
    <div className="w-full h-[339px] rounded-[4px] shadow-md bg-[#E8EAED] relative overflow-hidden font-dmsans">
      <h2 className="pl-[18px] pt-[18px] font-[500] text-[16px] leading-[20px] text-[#161414]">
        News & Insights
      </h2>
      <div className="pt-[16px] px-[16px] flex flex-col gap-2  overflow-y-auto no-scrollbar">
        {news.map((item, index) => (
          <div key={index} className=" flex gap-2 h-[99px] w-full rounded-[8px] py-[13px] px-[12px] border border-[#000B14] shadow-sm bg-[#F8F8F8]  ">
          <img src={item.image} alt={`${item.name}`} className="w-[46px] h-[72px]"/>
            <div className="flex flex-col gap-[8px]" >
                <h3 className="font-[500] text-[12px] leading-[16px]  text-black">
                  {item.name}
                </h3>
              <p className="font-[400] text-[12px] leading-[16px]  text-[#8E8E93]">
                {item.description} <button className="text-[#00325E] font-[500] border-none bg-none">Read</button>
              </p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="absolute bottom-0 w-full bg-gradient-to-t from-white via-white/50 to-transparent h-[80px]"></div>
      <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center pb-4">
        <button className="flex justify-center items-center w-[91px] h-[34px] rounded-[4px] py-2 px-6 bg-[#00325E] text-[#FFFCFC] font-[500] text-[12px] leading-[18px] text-center">
          View all
        </button>
      </div> */}
      <div className="flex justify-center items-center pb-[18px] pt-[16px]">
      <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="XXS"
              label=" View All"
              className="w-[91px] "
             
            />
       
      </div>
    </div>
  );
};

export default NewsInsights;
