import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts"
import Emoji from "../../assets/images/smiley.svg"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart"
const chartData = [{ customer: "satisfaction", desktop: 45, mobile: 15 }]

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
} 

export function Component() {
  const totalCustomerSatisfaction = chartData[0].desktop + chartData[0].mobile

  return (
    <Card className="flex flex-col 2xl:flex-1 w-[25vw] 2xl:w-[17vw] h-[310px]">
      <CardHeader className="flex justify-center items-center">
        <CardTitle  className="text-center font-[700] text-[14px] leading-[18px] text-[#2E2E30]">Customer Satisfaction Rate</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-1 items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square w-full max-w-[250px]"
        >
          <RadialBarChart
            data={chartData}
            endAngle={180}
            innerRadius={80}
            outerRadius={130}
          >
        
    
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
            <Label
  content={({ viewBox }) => {
    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
      return (
        <>
          {/* Total Customer Satisfaction */}
          <text
            x={viewBox.cx}
            y={(viewBox.cy || 0) - 15} // Adjusted Y position for text above the emoji
            textAnchor="middle"
            dominantBaseline="middle"
            className="fill-muted-foreground text-[30px] font-[700] leading-[39px] text-[#404040] "
          >
            {totalCustomerSatisfaction.toLocaleString()}%
          </text>

          {/* Emoji Icon */}
          <image
            href={Emoji}
            x={viewBox.cx - 12} // Center the icon horizontally
            y={viewBox.cy + 30} // Position the emoji between texts
            width="24"
            height="24"
            alt="Satisfied emoji Icon"
          />

          {/* "Doing good!" Text */}
          <text
            x={viewBox.cx}
            y={(viewBox.cy || 0) + 75} // Adjusted Y position for text below the emoji
            textAnchor="middle"
            dominantBaseline="middle"
            className="fill-[#767676] text-[12px] font-[700] leading-[16px]"
          >
            Doing good!
          </text>
        </>
      );
    }
  }}
/>
            </PolarRadiusAxis>
            <RadialBar
              dataKey="desktop"
              stackId="a"
            //   cornerRadius={5}
              fill="#E9EDF0"
              className="stroke-transparent stroke-2"
            />
            
            <RadialBar
              dataKey="mobile"
              fill="#1A2233"
              stackId="a"
            //   cornerRadius={5}
              className="stroke-transparent stroke-2"
        
            />
            
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
    
    </Card>
  )
}


