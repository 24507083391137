const inventory = [
{
    id: 1,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 2,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 3,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 4,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 5,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 6,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 7,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 8,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 9,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
{
    id: 10,
    inventoryId: '012345',
    itemName: 'Engine Oil Filter (ACME)',
    category: 'Brake System',
    qtyOrder: '200',
    qtyStock: '80',
    supplier: 'Acme Auto Parts'

},
];
export default inventory