import * as React from "react";
import { Label, Pie, PieChart, Legend, ResponsiveContainer } from "recharts";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

// Chart data with custom colors for Chrome and Safari
const chartData = [
  { browser: "Profit", customers: 80, fill: "var(--color-safari)" },
  { browser: "Cost", customers: 20, fill: "var(--color-chrome)" },
];

const chartConfig = {
  customers: {
    label: "Customers",
  },
  chrome: {
    label: "Cost",
    color: "#57B0FF", // Default chrome color
  },
  safari: {
    label: "Profit",
    color: "#000B14", // Safari color
  },
};
function CustomLegend({ payload }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: "10px",
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`legend-item-${index}`}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: entry.color,
              borderRadius: "100%",
            }}
          ></div>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "700",
              leading: "16px",
              color: "#767676",
            }}
          >
            {entry.value}{" "}
            <span className="text-[#404040]">
              {entry.payload.customers.toLocaleString()}%
            </span>
          </span>
        </div>
      ))}
    </div>
  );
}

export function Component() {
  //   const totalCustomers = React.useMemo(() => {
  //     return chartData.reduce((acc, curr) => acc + curr.customers, 0);
  //   }, []);

  return (
    <Card className="flex flex-col flex-1 w-[50vw] h-[310px]">
      <CardHeader className="flex justify-center items-center ">
        <CardTitle className="text-center font-[700] text-[14px] leading-[18px] text-[#2E2E30]">
          Profit Margin
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0 flex justify-center items-center">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[200px] w-full"
        >
          <ResponsiveContainer>
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Pie
                data={chartData}
                //   startAngle={-50}
                //   endAngle={250}
                dataKey="customers"
                nameKey="browser"
                innerRadius={50} // Inner radius for the donut shape
                outerRadius={90} // Outer radius for the donut shape
                //   strokeWidth={5}
                //   cornerRadius={10}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <>
                          <text
                            x={viewBox.cx}
                            y={viewBox.cy - 10}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            className="fill-[#B7B7B7] text-[14px] font-[600] leading-[18px]"
                          >
                            Total
                          </text>
                          <text
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 10}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            className="fill-[#1A2233] text-[12px] font-[700] leading-[16px]"
                          >
                            $678,409. <tspan
    className="text-[10px]"
    fill="#1A2233" // Optional: Use same or different fill color
    fontWeight="700" // Optional: Adjust font weight for better visual balance
  >
    09
  </tspan>
                          </text>
                        </>
                      );
                    }
                  }}
                />
              </Pie>
              <Legend
                align="left"
                verticalAlign="left"
                content={<CustomLegend />}
              />
            </PieChart>
          </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
