import React from 'react'
import { IoIosClose } from "react-icons/io";
import { toast } from 'sonner';
import { Icon } from '@iconify/react';

const referrals = [
  {
    title: "My referrals",
    value: "14",
    icon: <Icon icon="mdi:users-group" className='xl:w-[40px] xl:h-[40px] w-[32px] h-[32px] text-[#FAFDFF]'/>,
    backgroundColor: "#00325E",
    textColor: "#FAFDFF"
  },
  {
    title: "Total earned",
    value: "$150",
    icon: <Icon icon="tdesign:money-filled" className='xl:w-[40px] xl:h-[40px] w-[32px] h-[32px] text-[#00325E]'/>,
     backgroundColor: "#F2F2F7",
    textColor: "#00325E"
  },
]
const ReferralBox = ({referral}) => {
 
  return (
    <div className={`w-full xl:w-[21vw] h-[116px] xl:h-[157px] p-6 rounded-[8px] flex flex-row flex-1 gap-8 items-center bg-[${referral.backgroundColor}] text-[${referral.textColor}]`}>
<div>{referral.icon}</div>
<div className='flex flex-col gap-4'>
  <h2 className='font-[400] text-[20px] leading-[26px] xl:text-[24px] xl:leading-[31px]'>{referral.title}</h2>
  <h3 className='font-[600] text-[32px] leading-[42px] xl:text-[48px] lg:leading-[63px]'>{referral.value}</h3>
</div>
    </div>
  )
}

const ReferralModal = ({closeModal}) => {
  const referralLink = 'hamzaa.ca/ref=1728373476';
  const handleReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast("Referral Link copied");
  };
  return (
    <div
    className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans"
    onClick={closeModal}
  >
    <div
      className="bg-white shadow-lg lg:px-[40px] pb-[24px] w-full h-auto md:w-[50vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col"
      onClick={(e) => e.stopPropagation()}
    >
     <div className="flex justify-center px-0 lg:px-6  lg:justify-between pt-8 pb-[40px] lg:pb-[56px] bg-white sticky top-0  z-30">
          <h1 className="font-[500] text-[20px] lg:text-[32px] leading-[32px] -tracking-[3%] text-[#1C1C1C]">
           Referral 
          </h1>
          <button
            className="absolute right-4 lg:static shadow-md md:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
            onClick={closeModal}
          >
            <IoIosClose className="w-full h-full" />
          </button>
        </div>
        <div className='px-6'>
        <div className='flex flex-col gap-4 xl:flex-row xl:justify-between flex-wrap'>
          {
            referrals.map((referral, index) => (
<ReferralBox referral={referral}/>
            ))
          }
        </div>
        <div className='pt-[48px] pb-4 md:pb-[32px] flex flex-col gap-2'>
<h3 className='font-[500] text-[16px] leading-[21px] md:text-[24px] md:leading-[31px] text-[#1C1C1C]'>My referral link</h3>
<div className='flex justify-between items-center border border-[#8080808C] py-4 px-2 xl:px-6 rounded-[8px] w-full h-[48px] md:h-[71px]'>
<p className='font-[400] text-[14px] leading-[18px] md:text-[18px] xl:text-[24px] xl:leading-[31px] text-[#1C1C1C]'>{referralLink}</p>
<button className='flex gap-2 xl:gap-5 items-center w-auto py-1 px-2 border border-[#8080808C] rounded-[4px] h-[28px] md:h-[39px]' onClick={handleReferralLink}>
  <span className='font-[400] text-[14px] leading-[18px] xl:text-[24px] xl:leading-[31px] text-[#1C1C1C]'>Copy link</span>
  <Icon icon="fluent:copy-28-filled" className='w-5 h-5 text-[#00325E]'/>
</button>
</div>
        </div>
       <div className='mb-[40px]'><p className='text-[#074C7F] text-[14px] leading-[18px] xl:text-[24px] xl:leading-[31px]'>Get <span className='font-[700]'>$200 CAD</span> for each invited user that <span className='font-[700]'>subscribes</span></p></div> 
       </div>
    </div>
    </div>
  )
}

export default ReferralModal