import React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'

const QuickBooks = () => {
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | QuickBooks</title>
                <meta name="description" content="Hamzaa QuickBooks page" />
              </Helmet>
    <div className='absolute top-[90px] pt-[60px] w-[calc(100%-319px)] z-10 px-[48px] pb-[40px] min-h-[100vh] font-dmsans  bg-[#F8FAFF]'>
     <h1 className="font-[700] text-[20px] leading-[32px] text-primary pb-[40px]">QuickBooks</h1>
   </div>
   </HelmetProvider>
  )
}

export default QuickBooks