import React from "react";
import { IoIosClose } from "react-icons/io";

const ServiceDetailsModal = ({ closeModal, customer }) => {
  if (!customer) return null;

  return (
    <div
      className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg  lg:p-[44px] w-full xl:w-[50vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center lg:justify-between lg:pb-[56px] bg-white sticky top-0 py-6 z-30">
          <h1 className="font-[500] text-[20px] lg:text-[32px] leading-[44px] -tracking-[3%] text-[#1C1C1C]">
            Service Details
          </h1>
          <button
            className="absolute right-4 lg:static shadow-md md:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
            onClick={closeModal}
          >
            <IoIosClose className="w-full h-full" />
          </button>
        </div>
        <div className="flex flex-col gap-8 p-8 md:py-8 lg:px-0">
          <div className="flex justify-center items-center gap-[12px] lg:hidden">
            <h2 className="font-montserrat font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-strokeColor">
              Status
            </h2>
            <button
              className={`w-auto h-[33px] rounded-[20px] py-[8px] px-[19px] font-montserrat text-nowrap  flex justify-center items-center font-[400] text-[14px] leading-[17px]
      ${
        customer.status === "Completed"
          ? "text-[#027A48] bg-[#ECFDF3]" // Styles for 'completed'
          : customer.status === "In-progress"
          ? "text-[#FF9500] bg-progressBg" // Styles for 'inprogress'
          : "text-gray-500 bg-gray-200" // Default styles for any other status
      }`}
            >
              {customer?.status}
            </button>
          </div>
          <div className="flex md:gap-[178px]">
            <div className="flex flex-col gap-[18px] w-full">
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Services:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap ">
                  {customer?.service}
                </p>
              </div>
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Date:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap ">
                  {customer?.date}
                </p>
              </div>
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Cost:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap ">
                  ${customer?.cost}
                </p>
              </div>
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Notes:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap ">
                  {customer?.notes}
                </p>
              </div>
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Date of Collection:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap ">
                  {customer?.dateCollection}
                </p>
              </div>
              <div className="flex justify-between lg:justify-normal w-full lg:gap-2">
                <h2 className="font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-customerNameColor text-nowrap">
                  Set Reminder:
                </h2>
                <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[13px] tracking-[0.5px]  text-nowrap">
                  {customer?.reminder}
                </p>
              </div>
            </div>
            <div className="hidden lg:flex flex-col items-center gap-[29px]">
              <div className="flex flex-col items-center gap-[9px]">
                <h2 className="font-montserrat font-[400] text-[14px] leading-[13px] tracking-[0.5px] text-strokeColor">
                  Status
                </h2>
                <button
                  className={`w-auto h-[33px] rounded-[20px] py-[8px] px-[19px] font-montserrat text-nowrap  flex justify-center items-center font-[400] text-[14px] leading-[17px]
      ${
        customer.status === "Completed"
          ? "text-[#027A48] bg-[#ECFDF3]" // Styles for 'completed'
          : customer.status === "In-progress"
          ? "text-[#FF9500] bg-progressBg" // Styles for 'inprogress'
          : "text-gray-500 bg-gray-200" // Default styles for any other status
      }`}
                >
                  {customer?.status}
                </button>
              </div>
              <button className="flex justify-center items-center w-[151px] h-[48px] rounded-[7px] py-[12px] px-[16px] bg-[#00325E] font-montserrat font-[500] text-[14px] leading-[24px] text-white -tracking-[0.3px]">
                Generate Invoice
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-[18px] mt-[20px] lg:mt-0">
            <h1 className="text-[#273240] font-[700] text-[16px] leading-[24px] md:text-[20px] md:leading-[32px] lg:text-left text-center ">
              Parts used
            </h1>
            <div className="lg:w-[252px] h-auto flex justify-center lg:justify-normal flex-wrap gap-4">
              {customer?.parts.map((part, index) => (
                <div key={index}>
                  <button className="bg-[#DBE2EF] w-auto h-[44px] rounded-[12px] p-[10px] flex justify-center items-center font-[400] text-[12px] leading-[24px] text-[#273240]">
                    {part}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <button className="lg:hidden flex justify-center  mt-[40px] items-center w-full h-[48px] rounded-[7px] py-[12px] px-[16px] bg-[#00325E] font-montserrat font-[500] text-[14px] leading-[24px] text-white -tracking-[0.3px]">
            Generate Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsModal;
