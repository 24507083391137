import React from 'react';

const Switch = ({ toggleSwitch, isOn, appearance=false }) => {
	return (
		<div className='flex items-center gap-2'>
		 {appearance && (
        <span className="text-[12px] leading-[16px] md:text-[16px] md:leading-[21px] font-[400] text-strokeColor">
          {isOn ? 'Dark' : 'Light'}
        </span>
      )}
		<button
			onClick={toggleSwitch}
			className={`w-[40px] h-[24px] flex items-center rounded-full p-[2px] transition duration-300 ${
				isOn ? 'bg-[#21333E]' : 'bg-[#AAB1AF]'
			}`}>
			<div
				className={`bg-white w-[20px] h-[20px] rounded-full transform transition-transform duration-300 ${
					isOn ? 'translate-x-4' : ''
				}`}></div>
		</button>
		</div>
	);
};

export default Switch;
