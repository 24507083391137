import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Legend } from "recharts"
import { IoIosArrowDown } from "react-icons/io";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart"
const chartData = [
  { month: "Mon",  Maintenance: 186, Repair: 80, Others: 50 },
  { month: "Tue", Maintenance: 305, Repair: 200,Others: 30 },
  { month: "Wed",  Maintenance: 237, Repair: 120,Others: 40 },
  { month: "Thur",  Maintenance: 73, Repair: 190,Others: 30 },
  { month: "Fri", Maintenance: 209, Repair: 130,Others: 50 },
  { month: "Sat",  Maintenance: 214, Repair: 140,Others: 30 },
  { month: "Sun", Maintenance: 214, Repair: 140,Others: 30 },
]

const chartConfig = {
  PartOne: {
    label: " Maintenance",
    color: "#000B14",
  },
  PartTwo: {
    label: "Repair",
    color: "#00325E",
  },
  PartThree: {
    label: "Others",
    color: "#57B0FF",
  },
}
function CustomLegend({ payload }) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "32px", marginTop: "24px", marginLeft: "10px" }}>
        {payload.map((entry, index) => (
          <div key={`legend-item-${index}`} style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                
              }}
            ></div>
            <span style={{ fontSize: "12px", color: "#1C1C1C" }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
export function Component() {
  return (
    <Card style={{ height: '350px', width: '100%', background: "#F8FAFF",  borderColor: "#D9D9D9", paddingRight: "24px", borderRadius: "4px"   }}>
    <div className="flex justify-between items-center">
      <CardHeader>
        <CardTitle className="font-[500] text-[16px] leading-[21px] text-[#161414]"> Number of Appointments</CardTitle>
      </CardHeader>
      <div className="flex items-center gap-1 ">
         <span className="font-[500] text-[12px] leading-[16px]  text-[#161414]">
            This week
          </span>         <button className="flex justify-center items-center">
           <IoIosArrowDown className="text-[#161414]" />
         </button>
       </div>
      </div>
      <CardContent>
        <ChartContainer config={chartConfig} style={{ height: '250px', width: '100%' }}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
             <YAxis
                          tickLine={false}
                          axisLine={false}
                          tickMargin={10}
                          tickFormatter={(value) => `${value.toFixed(0)}`}
                        />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="Maintenance" fill="#000B14"  />
            <Bar dataKey="Repair" fill="#00325E"  />
            <Bar dataKey="Others" fill="#57B0FF"  />
            <Legend align="left" verticalAlign="bottom" content={<CustomLegend />} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

