import React, {useState, useEffect, useRef} from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'sonner'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { resetPassword, validateResetToken } from '../store/authSlice'
import InputField from '../components/inputField'
import AuthHeader from '../components/authHeader'

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const token = searchParams.get("token");
  const isTokenChecked = useRef(false);


  useEffect(() => {
    const validateToken = async () => {
      setIsVerifying(true);
        try {
          
await dispatch(validateResetToken(token)).unwrap();
                setIsValidToken(true);
                toast.success("Token validated. You can reset your password.");
           
        } catch (error) {
            toast.error(error || "An error occurred. Please try again.");
            navigate("/error");
        } finally {
            setIsVerifying(false);
        }
    };
    if (token && !isTokenChecked.current) {
      isTokenChecked.current = true; // Ensure only one call per token.
      validateToken();
    } else if (!token) {
      toast.error('No token provided.');
      navigate('/error');
    }
  
}, [token, navigate, dispatch]);




  const handleSubmit = async (e) => {
    e.preventDefault();
 
    // Ensure passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    setIsLoading(true);

    try {
      // Dispatch the resetPassword action
      const resultAction = await dispatch(
        resetPassword({ token, password })
      ).unwrap();

      // Show success message and redirect
      toast.success(resultAction.message || "Password reset successful!");
      navigate("/signin"); // Redirect to login page
    } catch (error) {
      // Handle any errors
      toast.error(error || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  if (isVerifying) {
    // Display this while verifying the token
    return (
      <div className="flex justify-center items-center h-[100vh] pt-[50px] bg-[#F8FAFF]">
      <h2 className="text-center text-[30px] text-primary">Verifying token...</h2>
    </div>
    );
  }
  if (!isValidToken) {
    return null; // Prevent rendering if token is invalid
  }
  return (
    <HelmetProvider>
    <Helmet>
						<title>
						 Hamzaa New Password
						</title>
						<meta
							name='description'
							content= "Hamzaa New Password page"
						/>
					</Helmet>
                    <div className="flex w-full min-h-[100vh] font-dmsans">
      {/* Background image container */}
      <div className="fixed top-0 left-0 md:w-[30vw] h-[100vh] bg-signin-image bg-cover bg-no-repeat md:flex justify-end hidden">
        {/* Content box aligned at the bottom */}
        <div className="hidden absolute bottom-5 left-5  w-[20vw]  min-h-[35vh] border-[2px] bg-signup-gradient backdrop-blur-[40px] border-[#F5F5F533]  rounded-[20px] md:flex justify-center items-center p-[20px] lg:p-[45px] text-white font-[400] text-[16px] leading-[24px] 2xl:text-[20px] 2xl:leading-[28px]">
        “I really appreciate the scheduling feature, and the reminders keep me
          updated on my vehicle's status, helping me avoid unexpected
          emergencies."
        </div>
      </div>
      <div className="md:absolute w-full mt-[50px] md:ml-[30%] md:w-[70%] flex flex-col md:p-[80px]  px-6 overflow-hidden">
        <AuthHeader/>
        <div className="flex flex-col gap-[6px] mt-[60px] mb-[52px]">
          <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] tracking-[0.3px] text-[#1C1C1C]">
          Create new password
          </h2>
          <p className="font-[400] text-[12px] leading-[18px] md:text-[16px] md:leading-[28px] tracking-[0.09px] text-lightGreyBg">
          We recommend using passwords with an uppercase, lowercase and special characters
          </p>
        </div>
        <form onSubmit={handleSubmit} className='w-full md:w-[80%] flex flex-col gap-4 justify-center items-center'>
        <InputField
            label="New Password"
            placeholder="Enter your password"
            value={password}
            handleValue={setPassword}
            name="password"
            type="password"
            className="w-full flex flex-col gap-2"
             inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
          />
        
        <InputField
            label="Confirm Password"
            placeholder="Enter your password"
            value={confirmPassword}
            handleValue={setConfirmPassword}
            name="confirmPassword"
            type="password"
           className="w-full flex flex-col gap-2"
            inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
          />
      <div className="flex justify-center items-center w-full mt-[48px] mb-[20px]">
            <button disabled={isLoading} className={` ${isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                } mt-[28px] w-full h-[52px] md:h-[60px] border-[2px] rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-[#007BD1] border-[#0A0A0A] font-[700] text-[16px] leading-[24px] text-center text-white`}>
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>

      </div>
    </div>
    </HelmetProvider>
  )
}

export default ResetPassword