import React from "react";

const Avatar = ({
  firstName,
  lastName,
  width = "w-[53px]",
  height = "h-[52px]",
  text = "text-[22px]",
  leading="leading-[24px]"
}) => {
  const imageSrc = null;
  const initials = `${firstName?.charAt(0) || ""}${
    lastName?.charAt(0) || ""
  }`.toUpperCase();
  return (
    <div
      className={`flex items-center justify-center ${width} ${height} py-[19px] px-[15px] rounded-full bg-avatarBg`}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={`${initials}`}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <span className={`text-[#1A2233] ${text} ${leading} font-[700] text-center -tracking-[0.3px]`}>
          {initials}
        </span>
      )}
    </div>
  );
};

export default Avatar;
