import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import services from '../utils/dummyData/serviceData'
import Table from '../components/Table'

const RecentServices = () => {
  const headers = ["S/N", "Customers", "Services", "Cost", "Date", "Status"];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
      style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}>
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor px-4 py-4 my-2 text-nowrap">
        {item.customerName}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
        {item.type}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
        {item.amount}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
        {item.date}
      </td>
      <td className="py-4 text-center border-b">
        <div className="flex justify-center">
          <button
            className={`w-auto h-auto text-nowrap rounded-[16px] md:py-[2px] px-[8px] text-center md:text-right flex justify-center items-center font-[400] text-[12px] leading-[17px] ${
              item.status === "Completed"
                ? "text-[#027A48] bg-[#ECFDF3]"
                : item.status === "In-progress"
                ? "text-[#FF9500] bg-progressBg"
                : "text-gray-500 bg-gray-200"
            }`}
          >
            {item.status}
          </button>
        </div>
      </td>
    </tr>
  );
  return (
    <div className='w-full h-[703px] p-4 rounded-[4px] shadow-md font-dmsans bg-white flex flex-col'>
<div className="flex justify-between pt-2 pb-6">
        <h1 className="text-serviceTitle font-[600] text-[16px] md:text-[16px] leading-[18px] tracking-[0.5px]">
        Recent Services
        </h1>

        <div className="flex items-center gap-1 ">
          <span className="font-[400] text-[14px] md:text-[16px] leading-[26px] text-secondary">
          see all
          </span>
          <button className="flex justify-center items-center">
          <IoIosArrowForward className="text-lightGreyBg w-[18px] h-[18px] md:w-[24px] md:h-[24px]"/>
          </button>
        </div>
      </div>
      <Table headers={headers} data={services} renderRow={renderRow} boxClassName='overflow-x-auto ' />
    </div>
  )
}

export default RecentServices