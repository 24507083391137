import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"

const chartData = [
    { month: "Jan", desktop: 86 },
    { month: "Feb", desktop: 50 },
    { month: "Mar", desktop: 69 },
    { month: "Apr", desktop: 73 },
    { month: "May", desktop: 30 },
    { month: "Jun", desktop: 40 },
    { month: "Jul", desktop: 80 },
    { month: "Aug", desktop: 80 },
    { month: "Sep", desktop: 100 },
    { month: "Oct", desktop: 80 },
    { month: "Nov", desktop: 75 },
    { month: "Dec", desktop: 20 },
  ]

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#000B14",
  },
} 

export function Component() {
  return (
    <Card className="flex-1 w-[50vw]  h-[310px]">
      <CardHeader className="flex justify-center items-center">
      <CardTitle className="font-[500] text-[16px] leading-[21px] text-[#161414] text-center">Revenue</CardTitle>
       
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} style={{ height: '200px', width: '100%' }}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
              angle={-45} // Add this line to slant the X-axis labels
              textAnchor="middle" // Ensures text is properly aligned
            />
              <YAxis
                                      tickLine={false}
                                      axisLine={false}
                                      tickMargin={10}
                                      tickFormatter={(value) => `$${value.toFixed(0)} `}
                                      ticks={[0, 20, 40, 60, 80, 100]}
                                      domain={[0, 100]}
                                    />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar barSize={30} dataKey="desktop" fill="var(--color-desktop)"  />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
