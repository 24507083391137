import React, {useRef, useEffect} from 'react'
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaArrowRightLong } from "react-icons/fa6"
import { FaArrowUpFromBracket } from "react-icons/fa6";
import Avatar from './avatar';

const UserDropdown = ({onClose, openReferralModal}) => {
  const navigate = useNavigate();
    const merchant = useSelector((state) => state.auth.merchant);
    const dropdownRef = useRef(null);
const firstName= merchant?.firstName;
const lastName = merchant?.lastName;
const email = merchant?.email;
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);
  const handleReferralModal = () => {
    onClose();
    openReferralModal();
  }
  const handleSettings =() => {
    onClose();
    navigate("/settings")
  }
  return (
    <div  className='hidden fixed top-[100px] right-[10px] inset-0 z-40 lg:flex lg:items-start lg:justify-end'>
   <div ref={dropdownRef}
        className="bg-white lg:w-[20vw]  lg:h-auto flex flex-col gap-2 p-4 shadow-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex justify-between items-center pb-7'>
            <h2 className='font-montserrat font-[500] text-[12px] leading-[22px] text-[#6B6666]'>{firstName}{" "}{lastName}</h2>
           <div className='flex gap-1 items-center'><button className='text-[#F31E45] font-montserrat font-[400] text-[12px] leading-[22px]'>Log Out</button>
           <FaArrowRightLong className='w-4 h-3 text-[#6B6666]' />

           </div> 
        </div>
        <div className='flex gap-4 items-center'>
            <Avatar firstName={firstName} lastName={lastName}/>
            <div className='flex flex-col'>
                <h1 className='font-montserrat font-[600] text-[16px] leading-[22px] text-black'>{firstName}{" "}{lastName}</h1>
                <h2 className='font-montserrat font-[400] text-[12px] leading-[22px] text-black'>{email}</h2>
            </div>
        </div>
        <div className='flex justify-between items-center py-4'>
            <p className='font-dmsans font-[400] text-[14px] leading-[18px] text-[#00325E]'>Upgrade plan</p>
            <FaArrowUpFromBracket className='text-[#00325E] w-4 h-4'/>
        </div>
        <div className='pb-4 border-t w-full border-[#D1D1D6]'></div>
        <div className='pb-2'><button onClick={handleReferralModal} className='font-dmsans font-[400] text-[14px] leading-[19px] text-[#575757]'>Referral</button></div>
        <div><button onClick={handleSettings} className='font-dmsans font-[400] text-[14px] leading-[19px] text-[#575757]'>Settings</button></div>
       
      </div> 
    </div>
  )
}

export default UserDropdown