const orders = [
    {
        id: 1,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Awaiting Delivery',
    
    },
    {
        id: 3,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Cancelled',
    
    },
    {
        id: 4,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Cancelled',
    
    },
    {
        id: 5,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Cancelled',
    
    },
    {
        id: 6,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Completed',
    
    },
    {
        id: 7,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Awaiting Delivery',
    
    },
    {
        id: 8,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Completed',
    
    },
    {
        id: 9,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Cancelled',
    
    },
    {
        id: 10,
        orderId: '012345',
        date: '25 Jun, 24',
        category: 'Brake System',
        qty: '20',
        price: '50,000',
        totalAmount: '500,000',
        supplier: 'Acme Auto Parts',
        status: 'Order Completed',
    
    },
];

export default orders;