import React from "react";
import Pagination from "../components/pagination"

const Table = ({ headers, data, renderRow, boxClassName, pagination}) => {
  return (
    <div className={`${boxClassName} relative overflow-hidden`}>
    <div className="overflow-x-auto -mx-2">
      <table className="min-w-full bg-white border-none rounded-[4px]">
        <thead className="bg-[#E8EAED] rounded-[4px] sticky top-0 z-20 ">
          <tr className=" ">
            {headers.map((header, index) => (
              <th
                key={index}
                className={`py-4 px-4  border-b font-[500] text-[12px] leading-[17px] text-nowrap text-[#4F5459] ${index === 0 ? "text-left sticky left-0 bg-[#E8EAED] z-30" : "text-center"}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative h-[336px] mx-auto">
          {data.map((item, index) => (
            renderRow(item, index, index % 2 === 0 ? "bg-white" : "bg-tableBg")
          )
          )}
        
        </tbody>
        
      </table>
      {pagination && <div className=""><Pagination/></div>}
      </div>
    </div>
  );
};

export default Table;
