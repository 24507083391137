import React, {Suspense} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { FaSpinner } from 'react-icons/fa';


// const LazyApp = React.lazy(() => import('./App'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Router>
   <Suspense
						fallback={
							<FaSpinner className='animate-spin mx-auto text-[#00325E] text-4xl my-10' />
						}>
    <App />
    </Suspense>
  </Router>

  </React.StrictMode>
);



