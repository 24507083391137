import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"
const chartData = [
  { month: "Jan", desktop: 186 },
  { month: "Feb", desktop: 150 },
  { month: "Mar", desktop: 169 },
  { month: "Apr", desktop: 173 },
  { month: "May", desktop: 130 },
  { month: "Jun", desktop: 140 },
  { month: "Jul", desktop: 190 },
  { month: "Aug", desktop: 214 },
  { month: "Sep", desktop: 160 },
  { month: "Oct", desktop: 170 },
  { month: "Nov", desktop: 125 },
  { month: "Dec", desktop: 100 },
]
const chartConfig = {
  desktop: {
    label: "Customer Retention",
    color: "#717FFE",
  },
} 
export function Component() {
  return (
    <Card style={{ height: '350px', width: '100%' }}>
      <CardHeader>
        <CardTitle className="font-[600] text-[16px] leading-[21px] text-[#26262A]">Customer Retention</CardTitle>
      </CardHeader>
      <CardContent >
        <ChartContainer config={chartConfig} style={{ height: '250px', width: '100%' }}>
          <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              right: 10,
            }}
          >
           <defs>
    <linearGradient id="desktopGradient" x1="0" y1="0" x2="0" y2="1.2">
      <stop offset="5%" stopColor="rgba(5, 46, 92, 1)" stopOpacity={1.0} />
      <stop offset="95%" stopColor="rgba(218, 223, 255, 1)" stopOpacity={0.1} />
    </linearGradient>
  </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value.slice(0, 3)}
            />
             <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={15}
              tickFormatter={(value) => `${value.toFixed(0)}%`}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Area
              dataKey="desktop"
              type="natural"
             stroke="#717FFE" 
    fill="url(#desktopGradient)"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}