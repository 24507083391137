import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import customers from "../utils/dummyData/customerData";
import { FaEllipsisVertical } from "react-icons/fa6";
import Button from "./button";
import useClickOutside from "../utils/customhooks/useClickOutside";

const CustomerDetails = ({  openScheduleModal,  openCreateInvoiceModal, openEditCustomerModal }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dropDown, setDropDown] = useState(false);
  const customer = customers?.find((customer) => customer.id === Number(id));
  const dropdownRef = useRef(null);
   const buttonRef = useRef(null);
  useClickOutside([dropdownRef, buttonRef], () => setDropDown(false));
  const handleScheduleAppointmentModalOpen = () =>
    openScheduleModal()

  const handleCreateInvoiceModalOpen = () => openCreateInvoiceModal()



  if (!customer) {
    return (
      <div className="absolute top-[90px] z-10 px-[48px] w-[calc(100%-319px)] scroll-smooth font-dmsans pt-[60px] bg-[#F8FAFF] min-h-[100vh]">
        Customer not found
      </div>
    );
  }
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };
  const handleCustomerDetails = () => {
    navigate(`/customers/list/details/${customer?.id}/service-history`);
  };
  return (
    <div className=" lg:absolute lg:top-[90px] z-10 lg:pr-[283px] px-6 lg:px-[48px] w-full lg:w-[calc(100%-319px)] scroll-smooth font-dmsans pt-4 lg:pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh]">
      <div className="relative mt-[100px] flex flex-col lg:flex-row lg:justify-between lg:border border-[#D9D9D9] rounded-[10px] w-full lg:w-[50vw] lg:p-8 lg:min-h-[600px] mb-[30px]">
      <div className="lg:hidden flex gap-[14px] items-center mb-[40px]">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Schedule Appointment"
            className="w-auto "
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Service History"
            className="w-auto  h-[48px]"
            onClick={handleCustomerDetails}
          />
          <button onClick={handleDropDown} className="ml-1">
          <FaEllipsisVertical className="text-primary"/>
          </button>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Personal Information
            </h1>

            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Name:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.customerName}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Email:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.email}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Phone Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.phoneNumber}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Address:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.address}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Date Created:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.date}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Car Details
            </h1>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Make:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.make}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Model:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.model}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Year:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                ({customer?.year})
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Plate Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.plateNumber}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex flex-col items-center gap-4">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Schedule Appointment"
            className="lg:w-auto w-full"
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Service History"
            className="w-auto hidden lg:block h-[48px]"
            onClick={handleCustomerDetails}
          />
          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Create Invoice"
            className="w-auto hidden lg:block h-[48px]"
            onClick={handleCreateInvoiceModalOpen}
          />
        </div>
        <div>
          <button ref={buttonRef} onClick={handleDropDown} className="lg:flex hidden">
          <FaEllipsisVertical className="text-primary"/>
          </button>
        </div>
        {dropDown && (
        <div
          ref={dropdownRef}
          className="absolute right-0 top-[100px] md:right-[420px] lg:right-[21px] w-[117px] h-[124px] rounded-[8px] p-4 bg-[#FAFAFA] shadow-sm border border-[#D9D9D9] flex flex-col items-start justify-center gap-4"
        >
          <button onClick={() => openEditCustomerModal()} className="font-[400] text-[12px] leading-[20px] text-[#273240]">
            Edit
          </button>
          <button className="font-[400] text-[12px] leading-[20px] text-[#273240] w-[94px] h-[20px]">
            Create Reminder
          </button>
          <button className="font-[400] text-[12px] leading-[20px] text-[#EC5252]">
            Delete
          </button>
        </div>
      )}
      </div>
    
     
    </div>
  );
};

export default CustomerDetails;
