import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Legend } from "recharts"
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"
const chartData = [
  { month: "Part", Instock: 186, Lowstock: 80, OutStock: 50 },
  { month: "Part", Instock: 305, Lowstock: 200,OutStock: 30 },
  { month: "Part", Instock: 237, Lowstock: 120, OutStock: 40 },
  { month: "Part", Instock: 73, Lowstock: 190, OutStock: 30 },
  { month: "Part", Instock: 209, Lowstock: 130, OutStock: 50 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },
  { month: "Part", Instock: 214, Lowstock: 140, OutStock: 30 },

]

const chartConfig = {
  Instock: {
    label: "In Stock",
    color: "hsl(var(--chart-1))",
  },
  Lowstock: {
    label: "Low Stock",
    color: "hsl(var(--chart-2))",
  },
  OutStock: {
    label: "Out of Stock",
    color: "hsl(var(--chart-2))",
  },
}
function CustomLegend({ payload }) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "32px", marginTop: "24px", marginLeft: "10px" }}>
        {payload.map((entry, index) => (
          <div key={`legend-item-${index}`} style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                
              }}
            ></div>
            <span style={{ fontSize: "12px", color: "#1C1C1C" }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
export function Component() {
  return (
    <Card style={{ height: '350px', width: '100%', background: "rgba(0, 0, 0, 0.03)" }}>
    <div className="flex justify-between items-center">
      <CardHeader>
        <CardTitle className="font-[500] text-[16px] leading-[21px] text-[#161414]">Inventory Stats</CardTitle>
      </CardHeader>
      <div className="flex gap-2 items-center mr-1">
<button><MdArrowBackIosNew className="text-[#1C1C1E] w-4 h-4 " /></button>
<span  className="font-[500] text-[12px] leading-[15px] text- black">33 of  66</span>
<button><MdArrowForwardIos className="text-[#1C1C1E] w-4 h-4 "/></button>
      </div>
      </div>
      <CardContent>
        <ChartContainer config={chartConfig} style={{ height: '250px', width: '100%' }}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
              angle={-45} // Add this line to slant the X-axis labels
              textAnchor="middle" // Ensures text is properly aligned
            />
             <YAxis
                          tickLine={false}
                          axisLine={false}
                          tickMargin={10}
                          tickFormatter={(value) => `${value.toFixed(0)} pcs`}
                        />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="Instock" fill="#000B14"  />
            <Bar dataKey="Lowstock" fill="#00325E"  />
            <Bar dataKey="OutStock" fill="#57B0FF"  />
            <Legend align="left" verticalAlign="bottom" content={<CustomLegend />} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
