const services = [
{
    id: 1,
    customerName: 'Jane Josh',
    type: 'Oil Change & Tire  ...',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'In-progress',
},
{
    id: 2,
    customerName: 'Jane Josh',
    type: 'Engine Diagnostics',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'Completed',
},
{
    id: 3,
    customerName: 'Jane Josh',
    type: 'Oil Change & Tire  ...',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'In-progress',
},
{
    id: 4,
    customerName: 'Jane Josh',
    type: 'Oil Change & Tire  ...',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'In-progress',
},
{
    id: 5,
    customerName: 'Jane Josh',
    type: 'Engine Diagnostics',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'Completed',
},
{
    id: 6,
    customerName: 'Jane Josh',
    type: 'Engine Diagnostics',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'Completed',
},
{
    id: 7,
    customerName: 'Jane Josh',
    type: 'Brake Repair',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'Completed',
},
{
    id: 8,
    customerName: 'Jane Josh',
    type: 'Oil Change & Tire  ...',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'In-progress',
},
{
    id: 9,
    customerName: 'Jane Josh',
    type: 'Oil Change & Tire  ...',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'In-progress',
},
{
    id: 10,
    customerName: 'Jane Josh',
    type: 'Engine Diagnostics',
    amount: '3,000',
    date: '25 Jun, 24',
    status: 'Completed',
},
];

export default services