export const serviceOptions = [
    "Alignment",  
   "Oil change",
   "Oil Filter replacement",
  "Air filter replacement",
    "Spark Plugs replacement",
    "Brake Pads replacement", 
    "Brake fluids change",
    "Transmission Fluid change",
    "Tire (balancing and replacement)",
    "Battery replacement",
    "Coolant (Anti-freeze) replacement", 
    "Timing Belt/Chain replacement",
    "Cabin Air filter replacement", 
    "Fuel filter", 
    "Serpentine belt",
    "Wheel alignment adjustment",
    "Exhaust System inspection", 
    "Shocks and Struts replacement", 
    "Differential fluid replacement", 
    "Power steering fluid", 
    "Windshield wipers replacement", 
    "Diagnostics",
    "Backing Plate Replacement",
"Parking Brake Cable Replacement",
"Parking Brake Switch Replacement",
"Brake Master Cylinder Replacement",
"Brake Safety Inspection",
"Check Brake Fluid Level",
"Brake Caliper Replacement",
"Bleed Brakes",
"Brake Fluid Flush",
"Brake Booster Unit Replacement",
"Brake Pads Replacement",
"Brake Drum Replacement",
"Brake Shoes Replacement",
"Brake Pedal Linkage Lubrication",
"Brake Adjustment",
"Brake Rotor Replacement",
"Brake Line Replacement",
"Check Engine Light",
"Car Won't Start",
"Car Running Rough",
"Fluids Are Leaking",
"Warning Light",
"Diagnostic",
"Used Car Inspection",
"Front Pipe Replacement",
"Fan Shroud Assembly Replacement",
"Idle Control Valve Replacement",
"Fan Shroud Replacement",
"Distributor Replacement",
"EGR Valve Replacement",
"Ignition Wire Set Replacement",
"Cooling System Drain and Fill",
"Oil Pan Gasket Replacement",
"Intake Manifold Gasket Replacement",
"Intake Camshaft Position Sensor Replacement",
"Freeze Plug Replacement",
"Exhaust Manifold Gasket Replacement",
"Exhaust Camshaft Position Sensor Replacement",
"Tail Pipe Replacement",
"Carburetor Replacement",
"Exhaust System Replacement",
"Manual Transmission Oil Level Check",
"Check Engine Oil Level",
"Engine Oil and Filter Change",
"Engine Mount Replacement",
"Engine Coolant Temperature Sensor Replacement",
"Engine Oil Pressure Sensor Switch Replacement",
"Expansion Tank Replacement",
"Harmonic Balancer Replacement",
"Serpentine Belt Replacement",
"Drive Belt Replacement",
"Drive Belt Tensioner Replacement",
"Distributor Cap Replacement",
"Cooling Fan Replacement",
"Radiator Replacement",
"Radiator Fan Assembly Replacement",
"Radiator Fan Motor Replacement",
"Radiator Hose Replacement",
"Thermostat Replacement",
"Engine Tune-up",
"Water Pump Replacement",
"Valve Cover Gasket Replacement",
"Timing Belt Replacement",
"Emissions Failure Repair",
"Crankshaft Position Sensor Replacement",
"Coolant Drain and Fill",
"Catalytic Converter Replacement",
"Camshaft Position Sensor Replacement Intake and Exhaust",
"Antenna Mast Replacement",
"Mirror Glass Replacement",
"Door Lock Replacement",
"Headlight Door Replacement",
"Header Panel Replacement",
"License Plate Bracket Replacement",
"Emblem Replacement",
"Trunk Strut Replacement",
"Grille Insert Replacement",
"Fuel Tank Cap Replacement",
"Hood Strut Replacement",
"Splash Guard Backing Plate Replacement",
"Rear Lift Gate Locking Mechanism",
"Rear Distance Sensor Replacement",
"Door Weather Stripping Replacement",
"Power Seat Switch Replacement",
"Energy Absorber Replacement",
"Blower Motor Resistor Replacement",
"Heater Core Replacement",
"Cabin Air Filter Replacement",
"Heater and A/C Blower Motor",
"A/C Recharge",
"A/C Compressor Replacement",
"A/C Condenser Replacement",
"Defroster Grid Repair",
"A/C Line or Hose Replacement",
"Heater Core Hoses Replacement",
"HVAC Electronic Control Module Replacement",
"HVAC Blower Motor Resistor Replacement",
"HVAC Blower Motor Replacement",
"HVAC Blend Door Actuator Replacement",
"A/C Evacuate and Recharge",
"Turn Signal Switch Replacement",
"Turn Signal Bulb Replacement",
"Brake Light Bulb Replacement",
"Headlight Bulb Replacement",
"Fog Light Assembly Replacement",
"Corner Light Bulb Replacement",
"Side Marker Light Assembly Replacement",
"Turn Signal Light Assembly Replacement",
"Headlight Assembly Replacement",
"Park Light Assembly Replacement",
"Headlight Cover",
"Tail Light Bulb Replacement",
"Tail Light Assembly Replacement",
"Brake Light Switch Replacement",
"Headlight Switch",
"Fuse Replacement",
"Throttle Body Replacement",
"Throttle Position Sensor Replacement",
"Oxygen Sensor Replacement",
"Fuel Injector Replacement",
"Fuel Filter Replacement",
"Air Filter Replacement",
"Mass Airflow Sensor/Meter Replacement",
"Fuel Pressure Regulator Replacement",
"Ignition Coil Replacement",
"Spark Plugs Replacement",
"Ignition Switch Replacement",
"Ignition Lock Cylinder Replacement",
"Battery Replacement",
"Battery Cable Replacement",
"Battery Cable Battery Terminal End Service",
"Alternator Belt Replacement",
"Alternator Replacement",
"Starter Replacement",
"Power Steering Hose Replacement",
"Outer Tie Rod End Replacement",
"Power Steering Pump Replacement",
"Wheel Hub and Bearing Assembly Replacement",
"Wheel Hub Assembly Replacement",
"Control Arm Replacement",
"Check Tire Pressure",
"Check Power Steering Fluid",
"Power Steering Fluid Replacement",
"Wheel Bearing Replacement",
"ABS Control Unit Replacement",
"Wheel Cylinder Replacement",
"Ball Joint Replacement",
"Shock Absorber Replacement",
"Strut Assembly Replacement",
"Flat Tire Repair",
"New Tire Installation",
"Tire Mount and Balance",
"Tire TPMS Valves and Stems",
"Axle Assembly Replacement",
"CV Joint Boot Replacement",
"CV Joint Replacement",
"CV Axle Assembly Replacement",
"Driveshaft Replacement",
"Transfer Case Fluid Replacement",
"Transmission Fluid Change",
"Window Regulator with Motor Replacement",
"Window Belt Strip Replacement",
"Power Window Switch Replacement",
"Windshield Wiper Motor Replacement",
"Windshield Wiper Blades Replacement",
"Windshield Washer Pump Replacement",

]