import React, {useState, useRef} from "react";
import customers from "../utils/dummyData/customerData";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import Table from "./Table";
import Pagination from '../components/pagination'
import Button from "./button";
import Dropdown from "./dropdown";
import { IoSearch } from "react-icons/io5";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";

const CustomersServiceHistory = ({ openAddServiceModal }) => {
  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const statusDropdownRef = useRef(null);
  const statusButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Status dropdown
  useClickOutside([statusDropdownRef, statusButtonRef], () =>
    setIsStatusDropdownOpen(false));
  // Dropdown actions
  const dateActions = [
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
  ];

  const statusActions = [
    { label: "Completed", onClick: () => console.log("Filter: Completed") },
    { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
    { label: "Pending", onClick: () => console.log("Filter: Pending") },
  ];
  const headers = [
    "S/N",
    "Name",
    "Start of service ",
    "Service",
    "Mileage",
    "Cost",
    "Date of Completion",
    "Status",
  ];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
      style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}>
        {item.id}
      </td>
      <td className="border-b text-nowrap font-[400] text-[14px] text-center leading-[21px] text-strokeColor  px-4 py-4 my-2">
        {item.customerName}
      </td>
      <td className="py-4 px-4 text-nowrap text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor  ">
        {item.date}
      </td>
      <td className="py-4 px-4 text-nowrap text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor  ">
        {item.service}
      </td>
      <td className="py-4 px-4 text-nowrap text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor  ">
        {item.mileage}
      </td>
      <td className="py-4 px-4 text-nowrap text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor  ">
        ${item.cost}
      </td>
      <td className="py-4 px-4 text-nowrap text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor  ">
        {item.dateCollection}
      </td>
      <td className="py-4 text-center border-b">
        <div className="flex justify-center">
          <div
            className={`w-auto h-auto text-nowrap lg:h-[21px] rounded-[16px] py-[2px] px-[8px] text-right flex justify-center items-center font-[400] text-[12px] leading-[17px]
      ${
        item.status === "Completed"
          ? "text-[#027A48] bg-[#ECFDF3]" // Styles for 'completed'
          : item.status === "In-progress"
          ? "text-[#FF9500] bg-progressBg" // Styles for 'inprogress'
          : "text-gray-500 bg-gray-200" // Default styles for any other status
      }`}
          >
            {item.status}
          </div>
        </div>
      </td>
    </tr>
  );
  const handleOpenAddServiceModal = () => {
    openAddServiceModal();
  };
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Customer Service History</title>
                <meta name="description" content="Hamzaa Customer Service History page" />
              </Helmet>
    <div className="lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF] ">
        <div className="mt-[100px] lg:mt-[50px]">
     <Button
            onClick={handleOpenAddServiceModal}
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Add new service"
            className="w-full lg:w-auto font-[500]"
            iconLeft='+'
             iconLeftClassName='text-[22px] leading-[21px]'
          />
          </div>
    
      {/* Mobile Service History */}
<div className="lg:hidden flex flex-col items-center gap-[10px] mt-8 mb-4">
  <h2 className="font-[700] text-[16px] leading-[24px] text-primary text-center">Service History</h2>
</div>
      <div className="flex justify-between  pt-[50px] pb-[32px] w-full z-30 bg-white lg:bg-[#F8FAFF]">
        {/*Desktop Search input */}
        <div className="relative lg:block hidden">
          <input
            type="search"
            className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
            placeholder="Search by Name"
          />
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
            <img src={search} alt="search icon" className="w-[18px] h-[18px]" />
          </div>
        </div>

         {/* Date and Status */}
         <div className="flex items-center gap-4">
          <div className="relative">
            <button
            ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date
              </span>
              <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <Dropdown
                actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>

          <div className="relative">
            <button
            ref={statusButtonRef}
              onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                Status
              </span>
                            {isStatusDropdownOpen? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]"/> :<IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isStatusDropdownOpen && (
              <div ref={statusDropdownRef}>
              <Dropdown
                actions={statusActions}
                onClose={() => setIsStatusDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>
        </div>
         {/* Mobile Search */}
         <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
          <IoSearch className="text-[#FAFDFF] w-full h-full" />
          </div>
      </div>
      <div className="overflow-hidden h-[609px] w-full ">
        <Table
          headers={headers}
          data={customers}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full"
        />
      </div>
      <Pagination/>
    </div>
    </HelmetProvider>
  );
};

export default CustomersServiceHistory;
