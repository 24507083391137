// import { clsx, type ClassValue } from "clsx"
// import { twMerge } from "tailwind-merge"

// export function cn(...inputs: ClassValue[]) {
//   return twMerge(clsx(inputs))
// }

import clsx from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines class names using clsx and tailwind-merge.
 * @param  {...any} inputs Class names or conditional class objects.
 * @returns {string} Merged class names.
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
