import React, {useState, useRef} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import customers from "../utils/dummyData/customerData";
import Table from "./Table";
import Pagination from '../components/pagination'
import { IoSearch } from "react-icons/io5";
import Button from "./button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Dropdown from "./dropdown";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";

const InvoiceList = () => {
  const navigate = useNavigate();
  const handleCreateInvoice= () => {
    navigate('/customers/invoice/create')
  }
    // State for dropdown visibility
    const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
    const dateDropdownRef = useRef(null);
      const dateButtonRef = useRef(null);
      const statusDropdownRef = useRef(null);
  const statusButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Status dropdown
  useClickOutside([statusDropdownRef, statusButtonRef], () =>
    setIsStatusDropdownOpen(false));

  // Dropdown actions
  const dateActions = [
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
  ];

  const statusActions = [
    { label: "Completed", onClick: () => console.log("Filter: Completed") },
    { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
    { label: "Pending", onClick: () => console.log("Filter: Pending") },
  ];
  
  const headers = [
    "S/N",
    "Invoice ID",
    "Customer Name ",
    "Phone Number",
    "Date Issued",
    "Amount Paid",
    "Service",
    "Payment Status",
  ];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
      style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}>
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-nowrap text-strokeColor  px-4 py-4 my-2">
        {item.customerId}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.customerName}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.phoneNumber}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.date}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.amountPaid}
      </td>
      <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-nowrap text-strokeColor  ">
        {item.service}
      </td>
      <td className="py-4 text-center border-b">
        <div className="flex justify-center">
        <Link to={`/customers/invoice/details/${item.id}`}> <button
            className={`w-auto h-auto text-nowrap rounded-[16px] md:py-[2px] px-[8px] text-center md:text-right flex justify-center items-center font-[400] text-[12px] leading-[17px] ${
              item.status === "Completed"
                ? "text-[#027A48] bg-[#ECFDF3]"
                : item.status === "In-progress"
                ? "text-[#FF9500] bg-progressBg"
                : "text-gray-500 bg-gray-200"
            }`}
          >
            {item.status}
          </button>
          </Link>
        </div>
      </td>
    </tr>
  );
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Customer Invoice</title>
                <meta name="description" content="Hamzaa Customer Invoice page" />
              </Helmet>
    <div className=" md:absolute md:top-[90px] w-full md:w-[calc(100%-319px)] pt-4 px-6 z-10 md:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white md:bg-[#F8FAFF] ">
     <div className="mt-[100px] md:mt-[50px]">
     <Button
          onClick={handleCreateInvoice}
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Add Invoice"
            className="w-full md:w-auto font-[500]"
            iconLeft='+'
             iconLeftClassName='text-[22px] leading-[21px]'
          />
          </div>
   
      <div className="flex justify-between  pt-[50px] pb-[32px] w-full z-30 bg-white md:bg-[#F8FAFF]">
        {/* Search input */}
        <div className="relative md:block hidden">
          <input
            type="search"
            className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
            placeholder="Search by ID or Name"
          />
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
            <img src={search} alt="search icon" className="w-[18px] h-[18px]" />
          </div>
        </div>
        
         {/* Date and Status */}
         <div className="flex items-center gap-4">
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date
              </span>
              <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <Dropdown
                actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>

          <div className="relative">
            <button ref={statusButtonRef}
              onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                Status
              </span>
                           {isStatusDropdownOpen? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]"/> :<IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isStatusDropdownOpen && (
              <div ref={statusDropdownRef}>
              <Dropdown
                actions={statusActions}
                onClose={() => setIsStatusDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>
        </div>
         {/* Mobile Search */}
         <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
          <IoSearch className="text-[#FAFDFF] w-full h-full" />
          </div>
        </div>


      <div className="overflow-hidden h-[609px] w-full ">
        <Table
          headers={headers}
          data={customers}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full"
        />
      </div>
      <Pagination/>
    </div>
    </HelmetProvider>
  );
};

export default InvoiceList;
