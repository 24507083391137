import React from "react";
import { IoIosClose } from "react-icons/io";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";

const EditInventory = ({closeModal}) => {
  return (
    <div
      className="fixed inset-0 z-40 flex justify-center items-center md:items-start md:justify-end bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg w-full md:w-[60vw] rounded-l-[10px] h-[90%] md:h-full overflow-y-auto  scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth flex flex-col gap-8"
        onClick={(e) => e.stopPropagation()}
      >
      <ModalHeader
        header='Edit Inventory'
        onClick={closeModal}
        icon={ <IoIosClose className="w-full h-full" />}
      />
       
        <form className="flex flex-col gap-[26px] px-6 md:px-[60px] w-full pt-[20px] md:mt-0 bg-white">
          <div className="flex flex-col gap-[12px] md:flex-row md:gap-[20px] w-full">
            <InputField
              label="Item Name"
              name="itemName"
              type="text"
              asteriks={true}
              placeholder="Brake Pads"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
            <InputField
              label="Item Category"
              name="itemCategory"
              type="text"
              asteriks={true}
              placeholder="Brake System"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] md:gap-[20px] w-full">
            <InputField
              label="Quantity on Order"
              name="quantityOrder"
              type="text"
              asteriks={true}
              placeholder="BP12345"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
            <InputField
              label="Quantity in Stock"
              name="quantityStock"
              type="number"
              asteriks={true}
              placeholder="100"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-[12px] md:gap-[20px] w-full">
            <InputField
              label="Car Model"
              name="carModel"
              type="text"
              asteriks={true}
              placeholder="e.g Honda Civic (2019)"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
            <InputField
              label="Supplier Name"
              name="supplierName"
              type="text"
              asteriks={true}
              placeholder="ABC Supplies"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
            />
          </div>
          <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
            <Button
              type="secondary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Cancel"
              className="w-[119px] hidden md:block"
            />
            <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Submit"
              className="md:w-[186px] w-full"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditInventory