import React from "react";
import { IoArrowRedoOutline } from "react-icons/io5";
import Receipt from "../assets/images/receipt.svg";
import { LuPrinter } from "react-icons/lu";
import { BsDownload } from "react-icons/bs";

const Table = ({ headers, data, renderRow, boxClassName }) => {
  console.log("data", data);
  return (
    <div className={`${boxClassName} relative overflow-hidden`}>
      <div className="overflow-x-auto  ">
        <table className="min-w-full bg-white border md:border-none rounded-[4px]">
        <colgroup>
    <col style={{ width: "15%" }} />
    <col style={{ width: "15%" }} />
    <col style={{ width: "70%" }} />
  </colgroup>
          <thead className="bg-[#00325E] rounded-[4px] sticky top-0 z-20 ">
            <tr className=" ">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`py-4 px-4 border-b font-[500] text-[12px] leading-[30px] text-nowrap text-[#FFFFFF] ${
                    index === 0 
                      ? "text-left"
                      : "text-center"
                  }   ${index === 2 
                      && "text-right pr-[30px] lg:pr-[100px]"
                     
                  }`
                 
                  }
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="relative h-auto">
            {data.map((item, index) => renderRow(item, index))}
            <div className="my-[200px]">
    <div className="absolute right-0 bottom-0 flex lg:gap-[200px] py-4 lg:pr-[60px] bg-white">
      <div className="flex flex-col px-4">
        <h2 className="font-[500] text-[12px] leading-[40px] text-[#1C1C1C]">Sub Total</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-lightGreyBg">Discount</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-lightGreyBg">Tax (7.5%)</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-[#1C1C1C]">Total</h2>
       
      </div>
      <div className="flex flex-col px-4">
      <h2 className="font-[500] text-[12px] leading-[40px] text-[#1C1C1C]">₦6,000,000</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-lightGreyBg">-</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-lightGreyBg">₦2,000</h2>
        <h2 className="font-[500] text-[12px] leading-[40px] text-[#1C1C1C]">₦6,002,000</h2>
      </div>
    </div>
    </div>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const InvoiceDetails = () => {
  const services = [{ id: 1, service: "Oil Change", amount: "3,000,000" },
    { id: 2, service: "Tire Replaced", amount: "3,000,000" }
  ];
  const options = [
    {
      icon: <IoArrowRedoOutline className="text-[#1C1C1C] w-5 h-5" />,
      title: "Share Invoice",
    },
    {
      icon: <BsDownload className="text-[#1C1C1C] w-5 h-5" />,
      title: "Download Invoice",
    },
    {
      icon: <LuPrinter className="text-[#1C1C1C] w-5 h-5" />,
      title: "Print Invoice",
    },
  ];
  const details = [
    {
      title: "Invoice ID",
      value: "INV-10022004-001",
    },
    {
      title: "Currency",
      value: "Dollar",
    },
    {
      title: "Date Issued",
      value: "Feb 12, 2024",
    },
    {
      title: "Time",
      value: "10:00 PM",
    },

    {
      title: "Name",
      value: "Oge Ege",
    },
    {
      title: "Email",
      value: "aogejogn@gmail.com",
    },
    {
      title: "Phone Number",
      value: "012223345",
    },
  ];
  const headers = ["Serial Number", "Service", "Amount"];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[500] text-[12px] leading-[30px] text-[#1C1C1C] px-6 py-4 text-left text-nowrap`}
      >
        {item.id}
      </td>
      <td className=" py-4 px-4  border-b font-[500] text-[12px] text-center leading-[30px] text-[#1C1C1C] text-nowrap ">
        {item.service}
      </td>
      <td className="py-4  px-4 pr-[20px] lg:pr-[80px] text-right border-b font-[500] text-[12px] leading-[30px] text-[#1C1C1C] text-nowrap ">
      ₦{item.amount}
      </td>
    </tr>
  );
  return (
    <div className=" lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF]">
      <div className="flex justify-center md:justify-normal items-center gap-2 pt-[100px] lg:pt-[20px]">
        <img src={Receipt} alt="Receipt icon" className="w-6 h-6" />
        <h3 className="font-[500] text-[18px] leading-[23px] text-[#1C1C1C]">
          Invoice
        </h3>
      </div>
      <div className="flex justify-center md:justify-normal gap-3 md:gap-[34px] items-center pt-[14px] pb-[24px]">
        <h1 className="font-[500] text-[#1C1C1C] text-[24px] leading-[32px] md:text-[36px] md:leading-[58px] text-nowrap">
          INV-10022004-001
        </h1>
        <span className="text-[#63ABFD] text-[14px] leading-[18px] md:text-[18px] font-[500] md:leading-[23px] text-nowrap">
          for $1500
        </span>
      </div>
      <div className="flex justify-around md:justify-normal md:gap-2 items-center pb-[44px]">
        {options.map((option, index) => (
          <div
            key={index}
            className="flex items-center justify-center gap-[16px] border border-[#D9D9D9] py-[12px] px-4 bg-[#FAFDFF] h-[44px] w-auto rounded-[8px]"
          >
            <div>{option.icon}</div>
            <button className="hidden md:block">{option.title}</button>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 md:gap-6">
        <h3 className="font-[500] text-[18px] leading-[24px] text-[#1C1C1C]">
          Summary
        </h3>
        <div className="w-full border border-[#D9D9D9]"></div>
        <div className="flex flex-col gap-4 w-full lg:px-0">
          <div className="flex flex-col gap-4 lg:flex-row xl:gap-[142px] w-full">
            <div className="flex gap-8 md:justify-between w-full">
              <div className="flex flex-col  gap-4 w-full">
                {details.slice(0, 4).map((detail, index) => (
                  <div key={index} className="w-full">
                    <h2 className="font-[500] text-[16px] leading-[20px] tracking-[0.5px] text-[#575758] text-nowrap">
                      {detail.title}
                    </h2>
                  </div>
                ))}
              </div>
              <div className="flex flex-col  gap-4 w-full">
                {details.slice(0, 4).map((detail, index) => (
                  <div key={index} className="w-full">
                    <p className="text-[#1C1C1C] font-[500] text-[16px] leading-[20px] tracking-[0.5px] text-nowrap">
                      {detail.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex gap-8 md:justify-between w-full">
              <div className="flex flex-col  gap-4 w-full">
                {details.slice(-3).map((detail, index) => (
                  <div key={index} className="w-full">
                    <h2 className="font-[500] text-[16px] leading-[20px] tracking-[0.5px] text-[#575758] text-nowrap">
                      {detail.title}
                    </h2>
                  </div>
                ))}
              </div>
              <div className="flex flex-col  gap-4 w-full">
                {details.slice(-3).map((detail, index) => (
                  <div key={index} className="w-full">
                    <p
                      className={`${
                        index === 0 ? "text-[#63ABFD]" : "text-[#1C1C1C]"
                      } font-[500] text-[16px] leading-[20px] tracking-[0.5px] text-nowrap`}
                    >
                      {detail.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-[44px] mb-[70px]">
            <Table
              headers={headers}
              data={services}
              renderRow={renderRow}
              boxClassName="overflow-y-auto h-full overflow-x-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
