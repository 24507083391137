import React, {  useState } from 'react';

const Dropdown = ({ actions, onClose, position, width, searchable }) => {
	const [searchQuery, setSearchQuery] = useState('');
	

 // Filter actions based on search query
//  const filteredActions = actions.filter((action) =>
//     action.label.toLowerCase().includes(searchQuery.toLowerCase())
//   );
// Example adjustment in Dropdown
const filteredActions = actions.filter((action) => {
	if (typeof action.label === "string") {
	  return action.label.toLowerCase().includes(searchQuery.toLowerCase());
	}
	return true; // Include all non-string labels
  });
  
	return (
		<div
			className={`absolute ${position} bg-white border border-gray-200 rounded-lg shadow-lg z-30 w-full`}
			style={{ minWidth: '40px' }}
			onClick={(e) => e.stopPropagation()}>
			   {/* Search Input */}
			   <div className="p-2">
			   {searchable && (
        <div className="p-2">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
            placeholder="Search..."
          />
        </div>
      )}
      </div>
			<div className='flex flex-col w-full items-center gap-2 p-2 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth'>
				{filteredActions.length > 0 && filteredActions?.map((action, index) => (
					<button
					type='button'
						key={index}
						className={`${width} bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] hover:bg-[#FAFAFA] leading-[18px] -tracking-[3%] text-left text-nowrap`}
						onClick={() => {
							action.onClick();
							onClose();
						}}>
						{action.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default Dropdown;

