import React from 'react';

const AnalyticsDropdown = ({ actions, onClose, position, width, exportButton= false, }) => {

  return (
   
			<div className={`absolute ${position}  flex flex-col w-full items-center`}onClick={(e) => e.stopPropagation()}>
				{actions.length > 0 && actions?.map((action, index) => (
					<button
					type='button'
						key={index}
						className={`${width} bg-white shadow-lg z-30  ${exportButton ? "border-none rounded-[2px]" : "border border-gray-200 rounded-[8px]"}  py-3 px-7 text-[500] text-primary text-[16px] hover:bg-[#FAFAFA] leading-[18px] -tracking-[3%] text-left text-nowrap`}
						onClick={() => {
							action.onClick();
							onClose();
						}}>
						{action.label}
					</button>
				))}
			</div>
	
  )
}

export default AnalyticsDropdown