import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import customers from "../utils/dummyData/customerData";
import Dropdown from "../components/dropdown"; 
import Table from "../components/Table";
import Pagination from "../components/pagination";
import Button from "../components/button";
import { Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
import DownloadDropdown from "../components/downloadDropdown";
import { HelmetProvider, Helmet } from "react-helmet-async";


const CustomerList = ({ openAddCustomerModal }) => {
  const headers = [
    "S/N",
    "Customer ID",
    "Customer Name ",
    "Phone Number",
    "Start of Service",
    "Car Model",
    "Phone Number",
  ];

  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.customerId}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.customerName}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.phoneNumber}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.date}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.carModel}
      </td>
      <td className="lg:py-4 text-center border-b">
        <div className="flex justify-center">
          <Link to={`/customers/list/details/${item.id}`}>{item.plateNumber}</Link>
        </div>
      </td>
    </tr>
  );

  const handleOpenAddCustomerModal = () => {
    openAddCustomerModal();
  };

  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  // Dropdown actions
  const dateActions = [
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
  ];

  const statusActions = [
    { label: "Completed", onClick: () => console.log("Filter: Completed") },
    { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
    { label: "Pending", onClick: () => console.log("Filter: Pending") },
  ];

  const downloadActions = [
    { label: "Download as PDF", onClick: () => console.log("Download as PDF") },
    { label: "Download as Plain Text", onClick: () => console.log("Download as Plain Text") },
    { label: "Download as Microsoft word", onClick: () => console.log("Download as Microsoft word") },
  ];
  const downloadMobileActions = [
    { label: "PDF", onClick: () => console.log("Download as PDF") },
    { label: "Plain Text", onClick: () => console.log("Download as Plain Text") },
    { label: "Microsoft word", onClick: () => console.log("Download as Microsoft word") },
  ];

  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Customer List</title>
                <meta name="description" content="Hamzaa Customer List page" />
              </Helmet>
    <div className="lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF] ">
      <div className="mt-[100px] lg:mt-[50px] flex gap-5 lg:gap-0 lg:justify-between items-center">
        <Button
          onClick={handleOpenAddCustomerModal}
          type="tertiary"
          borderRadius="rounded-[5px]"
          size="XS"
          label="Add new customer"
          className="w-full lg:w-auto font-[500]"
          iconLeft="+"
          iconLeftClassName="text-[22px] leading-[21px]"
        />
        <div className="relative">
           <button  onClick={() => setIsDownloadDropdownOpen((prev) => !prev)} className="flex justify-between lg:justify-center items-center gap-1 border-[1px] border-[#D9D9D9] w-full lg:w-auto h-[50px] rounded-[5px] py-[4px] px-[8px] shadow-sm bg-[#FAFDFF]">
                    <div className="block lg:hidden"><BsDownload className='text-[#1C1C1C] w-4 h-4'/></div>
                    <div className="flex items-center gap-8 lg:gap-1">
                    <span className="text-center text-[#1C1C1C]">
                     <span className="md:block hidden font-dmsans font-[400] text-[12px] leading-[18px]"> Download </span> 
                     {/* <span className="block lg:hidden font-montserrat font-[400] text-[14px] leading-[21px] ">PDF</span> */}
                    </span>
                    <button className="flex justify-center items-center">
                     {isDownloadDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />  : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
                    </button>
                    </div>
                  </button>
                  <div className="hidden md:block">
                  {isDownloadDropdownOpen && (
              <DownloadDropdown
                actions={downloadActions}
                onClose={() => setIsDownloadDropdownOpen(false)}
                position="top-[60px] right-[10px]"
                searchable={false} 
                  width="w-full"
              />
            )}
            </div>
                  <div className="block md:hidden">
                  {isDownloadDropdownOpen && (
              <DownloadDropdown
                actions={downloadMobileActions}
                onClose={() => setIsDownloadDropdownOpen(false)}
                position="top-[60px] right-[10px]"
                searchable={false} 
                  width="w-full"
              />
            )}
            </div>
                  </div>
      </div>

      <div className="flex justify-between pt-[50px] pb-[32px] w-full z-30 bg-white lg:bg-[#F8FAFF]">
        {/* Search input */}
        <div className="relative lg:block hidden">
          <input
            type="search"
            className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
            placeholder="Search by Customer ID or Name"
          />
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
            <img src={search} alt="search icon" className="w-[18px] h-[18px]" />
          </div>
        </div>

        {/* Date and Status */}
        <div className="flex items-center gap-4">
          <div className="relative">
            <button
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date
              </span>
              <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
            </button>
            {isDateDropdownOpen && (
              <Dropdown
                actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
            )}
          </div>

          <div className="relative">
            <button
              onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                Status
              </span>
                          {isStatusDropdownOpen? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]"/> :<IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isStatusDropdownOpen && (
              <Dropdown
                actions={statusActions}
                onClose={() => setIsStatusDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
            )}
          </div>
            {/* Mobile Search */}
            <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
          <IoSearch className="text-[#FAFDFF] w-full h-full" />
          </div>
        </div>
      </div>

      <div className="h-[609px] w-full bg-white ">
        <Table
          headers={headers}
          data={customers}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
        />
      </div>
      <Pagination />
    </div>
    </HelmetProvider>
  );
};

export default CustomerList;
