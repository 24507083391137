import React from 'react'
import { Link } from 'react-router-dom';
import { RiMenu4Fill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";

const MobileHeader = ({openNavbarModal}) => {
  return (
    <div className='lg:hidden flex justify-between items-center fixed top-0 w-full h-[80px] rounded-br-[8px] rounded-bl-[8px] p-6 bg-primary z-30'>
    <button onClick={openNavbarModal} className='flex justify-center items-center cursor-pointer'>
<RiMenu4Fill className='text-[#FAFDFF] w-[24px] h-[24px]'/>
</button>
<h2 className='font-[400] font-miltonian text-[20px] leading-[24px] text-[#FAFDFF]'>Hamzaa</h2>
<Link to='/notifications'>
<button className='flex justify-center items-center cursor-pointer'>
<IoMdNotificationsOutline className='text-white w-6 h-6' />
</button>
</Link>
    </div>
  )
}

export default MobileHeader