
import vinDark from "../../assets/images/vinDark.svg";
import vinWhite from "../../assets/images/vinWhite.svg";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import { LayoutDashboard } from "lucide-react";
import { UserGroupIcon } from "hugeicons-react";
import { CalendarCheckIn01Icon } from "hugeicons-react";
import { Icon } from "@iconify/react";
import { Car01Icon } from "hugeicons-react";
import { Analytics01Icon } from "hugeicons-react";
import { HelpSquareIcon } from "hugeicons-react";
import { Login03Icon } from "hugeicons-react";

const handleLogout = () => {
  // Clear authentication-related data (e.g., localStorage)
  localStorage.removeItem("authToken");
  localStorage.removeItem("merchant");
  // Navigate to the login page
  window.location.href = "/signin";
};
const tabs = [
  { subHeading: "Menu",  },
  {
    tabName: "Dashboard",
    headerTitle: "Overview",
    activeIcon: <LayoutDashboard className="text-white w-5 h-5" />,
    inActiveIcon: <LayoutDashboard className="text-primary w-5 h-5" />,
    path: "/",
    subHeading: "Menu",
  },
  {
    tabName: "Customers",
    headerTitle: "Customer",
    activeIcon: <UserGroupIcon className="text-white w-5 h-5"  variant={"stroke"}/>,
    inActiveIcon: <UserGroupIcon className="text-primary w-5 h-5"  variant={"stroke"}/>,
    path: "/customers/list",
    subHeading: "Menu",
    subTabs: [
      { name: "Customer List", path: "/customers/list" },
      { name: "Service History", path: "/customers/service-history" },
      { name: "Invoice", path: "/customers/invoice" },
    ],
  },
  { subHeading: "Manage" },
  {
    tabName: "Appointments",
    headerTitle: "Appointments",
    path: "/appointments",
    activeIcon:  <CalendarCheckIn01Icon className="text-white w-5 h-5"  variant={"stroke"}/>,
    inActiveIcon: <CalendarCheckIn01Icon className="text-primary w-5 h-5"  variant={"stroke"}/>,
    subHeading: "Menu",
  },
  {
    tabName: "Inventory",
    headerTitle: "Inventory",
    path: "/inventory",
    activeIcon: <Icon icon="charm:clipboard-tick" className="text-white w-5 h-5" /> ,
    inActiveIcon: <Icon icon="charm:clipboard-tick" className="text-primary w-5 h-5" />,
    subHeading: "Menu",
  },
  {
    tabName: "Part Ordering",
    headerTitle: "Part Ordering",
    activeIcon: <Car01Icon className="text-white w-5 h-5"  variant={"stroke"}/>,
    inActiveIcon: <Car01Icon className="text-primary w-5 h-5"  variant={"stroke"}/>,
    path: "/part-ordering",
    subHeading: "Menu",
    subTabs: [
      { name: "Order History", path: "/part-ordering/history" },
      { name: "Supplier", path: "/part-ordering/supplier" },
    ],
  },
  {
    tabName: "VIN Lookup",
    headerTitle: "VIN Lookup",
    path: "/vin-lookup",
    activeIcon: vinWhite,
    inActiveIcon: vinDark,
    subHeading: "Menu",
  },
  {
    tabName: "Reports",
    headerTitle: "Reports",
    path: "/reports/analytics",
    activeIcon: <Analytics01Icon className="text-white w-5 h-5"/>,
    inActiveIcon:  <Analytics01Icon className="text-primary w-5 h-5"/>,
    subHeading: "Menu",
    subTabs: [
      { name: "Analytics", path: "/reports/analytics",  
        subTabs: [
        { name: "Customers", path: "/reports/analytics/customers" },
        { name: "Inventory", path: "/reports/analytics/inventory" },
        { name: "Shop Performance", path: "/reports/analytics/shop-performance" },
        { name: "Finance", path: "/reports/analytics/finance" },
        { name: "Parts Ordering", path: "/reports/analytics/parts-ordering" },
      ], },
      { name: "QuickBooks", path: "/reports/quickbooks" },
    ],
  },
  {
      tabName: "Upgrade Plan",
      headerTitle: "Upgrade Plan",
      path: "/",
      activeIcon: <HiOutlineArrowUpTray className="text-[#141B34] w-5 h-5" />,
      inActiveIcon: <HiOutlineArrowUpTray className="text-[#141B34] w-5 h-5" />,
    
    },
  {
    tabName: "Help",
    headerTitle: "Help",
    path: "/help",
    activeIcon: <HelpSquareIcon className="text-[#141B34] w-5 h-5"/>,
    inActiveIcon: <HelpSquareIcon className="text-[#141B34] w-5 h-5"/>,
    subHeading: null,
  },
  {
    tabName: "Logout",
    headerTitle: null,
  onClick: handleLogout,
    activeIcon: <Login03Icon className="text-[#C21616] w-5 h-5"/>,
    inActiveIcon: <Login03Icon className="text-[#C21616] w-5 h-5"/>,
    subHeading: null,
  },
];

export default tabs;
