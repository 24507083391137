import React from "react";
import { IoIosClose } from "react-icons/io";
import Button from "./button";
import { LuPrinter } from "react-icons/lu";
import { BsDownload } from "react-icons/bs";

const PlanOrderReceipt = ({ closeModal }) => {
    const info = [
        {
            text: "Date",
            value: "30th September, 2024"
        },
        {
            text: "Order Number",
            value: "HMZA293879-8374-23"
        },
        {
            text: "Payment Method",
            value: "Card(XXXX XXXX XXXX XXXX 3546)"
        },
        {
            text: "Retailer",
            value: "Hamzaa Inc."
        },
        {
            text: "VAT ID",
            value: "485748957-4958-21"
        },
        {
            text: "Product",
            value: "Hamzaa Pro"
        },
        {
            text: "VAT(10%)",
            value: "$15.00"
        },
        {
            text: "Total Tax",
            value: "$15.00"
        },
        {
            text: "Total",
            value: "$50.00"
        },

    ]
  return (
    <div
      className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg lg:px-[44px] w-full lg:w-[50vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center bg-white  sticky top-0 pt-[44px] z-30">
          <h1 className="font-[500] text-[20px] lg:text-[32px] leading-[44px] -tracking-[3%] text-[#1C1C1C]">
            Receipt
          </h1>
          <button
            className="absolute right-4  shadow-md lg:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
            onClick={closeModal}
          >
            <IoIosClose className="w-full h-full" />
          </button>
        </div>
        <div className="pt-[64px] xl:pt-[250px] 2xl:pt-[184px] 3xl:pt-[84px] bg-white flex justify-center flex-col gap-8 items-center max-h-[450px] overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth w-full px-[25px] 2xl:px-[70px] 3xl:px-[100px]">
        {info.map((info, index) => (
            <div key={index} className="flex justify-between w-full">
            <p className="font-[500] text-[16px] leading-[18px] text-serviceTitle">
             {info.text}
            </p>
            <h3 className="font-[400] text-customerNameColor text-[16px] leading-[18px] text-nowrap">
             {info.value}
            </h3>
          </div>
        ))}
          
        </div>
        <div className=" flex justify-center gap-[60px] items-center mt-[50px] mb-[40px]">
          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Print"
            className="w-auto  h-[48px]"
            iconLeft={<LuPrinter className="text-[#1C1C1C] w-5 h-5" />}
          />
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label="Download"
            className="w-auto "
            iconLeft={<BsDownload className="text-[#FAFDFF] w-5 h-5" />}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanOrderReceipt;
