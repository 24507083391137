import React, {useState} from "react";
import Table from "../Table";
import { FaPlus } from "react-icons/fa6";
import { GoTrash } from "react-icons/go";
import Visa from "../../assets/images/visa.png";
import Master from "../../assets/images/master-card.png";
import Button from "../button";
import { HelmetProvider, Helmet } from "react-helmet-async";

const CardBox = ({ image, name, number, selected, onSelect }) => {
  return (
    <div  className={`flex justify-between items-center px-5 py-4 border ${
      selected ? "border-primary" : "border-[#E4E8F1]"
    } rounded-[8px] w-full`} onClick={onSelect}>
      <div className="flex items-center gap-[12px] w-full">
        <input
          type="radio"
          checked={selected}
          readOnly
          className="form-radio h-[15px] w-[15px] accent-[#1A2233] focus:ring-0  border-[#E4E8F1]"
        />
        <div className="flex gap-2 items-center w-full">
          <img src={image} alt="" className="h-auto w-[38px]" />
          <div className="flex flex-col gap-[2px]">
            <h2 className="font-[700] text-[16px] leading-[21px] text-[#1C1C1C]">
              {name}
            </h2>
            <p className="font-[300] text-[16px] leading-[21px] text-cardNumberColor">
              {number}
            </p>
          </div>
        </div>
      </div>
      <button>
        <GoTrash className="w-6 h-6" />
      </button>
    </div>
  );
};

const PlanBillings = ({openReceiptModal, openUpgradePlanModal}) => {
  const [selectedCardId, setSelectedCardId] = useState(1);
  const card = [
    { id: 1, image: Visa, name: "Jane Daniel", number: "***** 7856" },
    { id: 2, image: Master, name: "Jane Daniel", number: "***** 7856" },
    { id: 3, image: Master, name: "Jane Daniel", number: "***** 7856" },
  ];
  const customers = [
    {
      id: 1,
      name: "Hamzaa Pro",
      status: "Pending",
      receipt: "View",
      cost: "$550",
    },
    {
      id: 2,
      name: "Hamzaa Basic",
      status: "Pending",
      receipt: "View",
      cost: "$550",
    },
    {
      id: 3,
      name: "Hamzaa Pro",
      status: "Successful",
      receipt: "View",
      cost: "$550",
    },
    {
      id: 4,
      name: "Hamzaa Basic",
      status: "Pending",
      receipt: "View",
      cost: "$550",
    },
    {
      id: 5,
      name: "Hamzaa Pro",
      status: "Failed",
      receipt: "View",
      cost: "$550",
    },
  ];
  const headers = ["S/N", "Description", "Cost ", "Status", "Receipt"];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.name}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.cost}
      </td>
      <td className="py-4 text-center border-b">
        <div className="flex justify-center">
          <button
            className={`w-auto h-auto text-nowrap rounded-[16px] md:py-[2px] px-[8px] text-center md:text-right flex justify-center items-center font-[400] text-[12px] leading-[17px] ${
              item.status === "Successful"
                ? "text-[#027A48] bg-[#ECFDF3]"
                : item.status === "Pending"
                ? "text-[#FF9500] bg-progressBg"
                : item.status === "Failed"
                ? "bg-failedBg text-[#EC5252]"
                : "text-gray-500 bg-gray-200"
            }`}
          >
            {item.status}
          </button>
        </div>
      </td>
      <td className="lg:py-4 text-center border-b">
        <div className="flex justify-center underline font-[400] text-[14px] leading-[18px]">
          <button onClick={openReceiptModal}>{item.receipt}</button>
        </div>
      </td>
    </tr>
  );
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Settings - Plan & Billings</title>
                <meta name="description" content="Hamzaa Settings Plan & Billings page" />
              </Helmet>
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="w-full h-[340px] overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth rounded-[8px] mt-8 mb-4 flex flex-col  bg-white p-6 shadow-md">
          <h2 className="font-[600] text-[16px] leading-[21px] text-[#BCBCBC]">
            Current Plan
          </h2>
          <div className="flex justify-between xl:gap-[50px] 2xl:gap-[150px] w-full mt-4 mb-6">
            <div className="flex flex-col">
              <h3 className="text-[#091B3D] text-[20px] leading-[25px] font-[700]">
                Basic
              </h3>
              <p className="text-planDescription font-[400] text-[14px] leading-[18px]">
                For smaller shops that wants to streamline workflows
              </p>
            </div>
            <h3 className="text-[#007BD1] text-[24px] font-[700] leading-[30px]">
              $50
              <span className="text-[#091B3D] text-[14px] leading-[17px] font-[400]">
                /Month
              </span>
            </h3>
          </div>
          <div className="flex flex-col gap-4 mb-8">
            <div className="flex justify-between">
              <h3 className="font-[400] text-customerNameColor text-[16px] leading-[18px]">
                Activation date:
              </h3>
              <p className="font-[500] text-[16px] leading-[18px] text-serviceTitle">
                18-10-2024
              </p>
            </div>
            <div className="flex justify-between">
              <h3 className="font-[400] text-customerNameColor text-[16px] leading-[18px]">
                Expiry date:
              </h3>
              <p className="font-[500] text-[16px] leading-[18px] text-serviceTitle">
                18-11-2024
              </p>
            </div>
            <div className="flex justify-between">
              <h3 className="font-[400] text-customerNameColor text-[16px] leading-[18px]">
                Days left:
              </h3>
              <p className="font-[500] text-[16px] leading-[18px] text-serviceTitle">
                29day(s)
              </p>
            </div>
          </div>
          <Button type="primary"
              borderRadius="rounded-[8px]"
              size="M"
              label="Upgrade Plan"
              className="w-full"
              onClick={openUpgradePlanModal}
              />
        </div>
        <div className="w-full h-[340px] border border-[#BCBCBC] rounded-[8px] mt-8 mb-4 flex flex-col bg-white p-6 ">
          <div className="flex justify-between items-center w-full pb-6">
            <h2 className="font-[600] text-[16px] leading-[21px] text-[#BCBCBC]">
              Payment method
            </h2>

            <button className="flex justify-center items-center gap-[2px]">
              <FaPlus className="text-[#007AFF] w-4 h-4" />
              <span className="text-center font-[500] text-[14px] leading-[18px] text-[#007AFF] text-nowrap">
                Add new card
              </span>
            </button>
          </div>
          <div className="flex flex-col w-full gap-[10px] max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth">
            {card.map((card, index) => (
              <CardBox
                key={index}
                image={card.image}
                name={card.name}
                number={card.number}
                selected={selectedCardId === card.id}
                onSelect={() => setSelectedCardId(card.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 mb-6">
        <h2 className="font-[600] text-[16px] leading-[21px] text-[#26262A]">
          Plan order History
        </h2>
      </div>
      <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={customers}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
      </div>
    </div>
    </HelmetProvider>
  );
};

export default PlanBillings;
