import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Legend } from "recharts"

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"
const chartData = [
  { month: "Mon", PartOne: 186, PartTwo: 80, PartThree: 50 },
  { month: "Tue", PartOne: 305, PartTwo: 200,PartThree: 30 },
  { month: "Wed", PartOne: 237, PartTwo: 120, PartThree: 40 },
  { month: "Thur", PartOne: 73, PartTwo: 190, PartThree: 30 },
  { month: "Fri", PartOne: 209, PartTwo: 130, PartThree: 50 },
  { month: "Sat", PartOne: 214, PartTwo: 140, PartThree: 30 },
  { month: "Sun", PartOne: 214, PartTwo: 140, PartThree: 30 },
]

const chartConfig = {
  PartOne: {
    label: "Part 26",
    color: "hsl(var(--chart-1))",
  },
  PartTwo: {
    label: "Part 67",
    color: "hsl(var(--chart-2))",
  },
  PartThree: {
    label: "Part 16",
    color: "hsl(var(--chart-2))",
  },
}
function CustomLegend({ payload }) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "32px", marginTop: "24px", marginLeft: "10px" }}>
        {payload.map((entry, index) => (
          <div key={`legend-item-${index}`} style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                
              }}
            ></div>
            <span style={{ fontSize: "12px", color: "#1C1C1C" }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
export function Component() {
  return (
    <Card style={{ height: '350px', width: '100%', background: "rgba(0, 0, 0, 0.03)" }}>
    <div className="flex justify-between items-center">
      <CardHeader>
        <CardTitle className="font-[500] text-[16px] leading-[21px] text-[#161414]">Top Ordered Parts</CardTitle>
      </CardHeader>
      </div>
      <CardContent>
        <ChartContainer config={chartConfig} style={{ height: '250px', width: '100%' }}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
             <YAxis
                          tickLine={false}
                          axisLine={false}
                          tickMargin={10}
                          tickFormatter={(value) => `${value.toFixed(0)} pcs`}
                        />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="PartOne" fill="#000B14"  />
            <Bar dataKey="PartTwo" fill="#00325E"  />
            <Bar dataKey="PartThree" fill="#57B0FF"  />
            <Legend align="left" verticalAlign="bottom" content={<CustomLegend />} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}
