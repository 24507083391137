import React from 'react'
import coins from '../assets/images/coins.png'


const ReferHamzaa = ({openReferralModal}) => {

  return (
    <>
          <div className="lg:bg-[#00325E] bg-primary relative w-full h-[111px] md:h-[167px] rounded-[8px] flex flex-col p-4 font-dmsans  overflow-hidden">
  
  <div className="flex flex-col items-center justify-center gap-[11px] pb-2">
    <h2 className="text-[#FAFDFF] font-[600]  text-[16px] leading-[20px] md:text-[18px] md:leading-[23px] text-center">Refer on <span className="bg-[#63ABFD] shadow-sm w-[62px] h-[21px] md:w-[74px] md:h-[27px] rounded-[4px] p-[2px]">Hamzaa</span> and Earn</h2>
    <button onClick={openReferralModal} className="border border-[#F5F5F5] w-[90px] h-[24px] md:h-[28px] rounded-[6px] py-[4px] px-[8px] flex justify-center items-center text-[#F2F2F7] font-[500] text-[12px] leading-[16px] md:text-[10px] md:leading-[13px]">Refer Now</button>
  </div>
  <div className="absolute bottom-0 md:hidden">
  <img src={coins} alt="Falling Coins" className="w-[90px] md:w-[126px] h-[76px] "/>
  </div>
  <div className="hidden md:flex flex-col md:items-center md:justify-center">
  <img src={coins} alt="Falling Coins" className="w-[90px] md:w-[126px] h-[76px] "/>
  </div>
  </div>
    </>
  )
}

export default ReferHamzaa