import React, { useState, useEffect} from "react";
import InputField from "../components/inputField";
import mail from "../assets/images/mail.svg";
import { HelmetProvider, Helmet } from "react-helmet-async";
import AuthHeader from "../components/authHeader";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/authSlice";
import {toast} from "sonner";
import { FaSpinner } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useScrollTop from "../custom-hooks/scrollToTop";


const SignIn = () => {
const {scrollToTop} = useScrollTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const[email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
  
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true); // Optionally set the Remember Me checkbox if credentials are found
    }
  }, []);


const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  try {
    // Dispatch the login action and unwrap the result
    const result = await dispatch(loginUser({ email, password })).unwrap();

    // Access token and merchant data from the result
    const { token, merchant } = result.data;

    // Store token and credentials based on "Remember Me"
    if (rememberMe) {
      localStorage.setItem('authToken', token);
      localStorage.setItem('savedEmail', email);
      localStorage.setItem('savedPassword', password);
    } else {
      sessionStorage.setItem('authToken', token);
    }

    toast.success('Login successful');
                 setTimeout(() => {
                 toast.success(`Welcome back, ${merchant.firstName}!`);
                    }, 2000);
                    navigate("/");
                    scrollToTop();
  } catch (error) {
    // Handle errors returned from the thunk
    toast.error(error.message || 'Login failed. Please check your credentials.');
  } finally {
    setIsLoading(false); // Stop loading state regardless of success or failure
  }
};

const handleCheckboxChange = (e) => {
  setRememberMe(e.target.checked);
};


  return (
    <HelmetProvider>
    <Helmet>
						<title>
						 Hamzaa Sign in
						</title>
						<meta
							name='description'
							content= "Hamzaa Sign in page"
						/>
					</Helmet>
    <div className="flex w-full min-h-[100vh] font-dmsans">
      {/* Background image container */}
      <div className="fixed top-0 left-0 md:w-[30vw] h-[100vh] bg-signin-image bg-cover bg-no-repeat hidden md:flex justify-end">
        {/* Content box aligned at the bottom */}
        <div className="hidden absolute bottom-5 left-5 w-[25vw]  min-h-[35vh] border-[2px] bg-signup-gradient backdrop-blur-[40px] border-[#F5F5F533]  rounded-[20px] md:flex justify-center items-center p-[20px] lg:p-[45px] text-white font-[400] text-[16px] leading-[24px] 2xl:text-[20px] 2xl:leading-[28px]">
          “I really appreciate the scheduling feature, and the reminders keep me
          updated on my vehicle's status, helping me avoid unexpected
          emergencies."
        </div>
      </div>
      <div className="md:absolute w-full mt-[50px] md:mt-0 md:ml-[30%] md:w-[70%] flex flex-col md:p-[80px] overflow-hidden">
      <div className="md:px-0 px-6"><AuthHeader/></div>
        <div className="flex flex-col px-6 md:px-0 gap-[6px] mt-[44px] mb-[50px] md:mt-[60px] md:mb-[80px]">
          <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] tracking-[0.3px] text-[#1C1C1C]">
            Welcome back
          </h2>
          <p className="font-[400] text-[12px] leading-[18px] md:text-[16px] md:leading-[28px] tracking-[0.09px] text-lightGreyBg">
            Login to your account
          </p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col px-6 md:px-0 gap-4 w-full md:w-[80%]">
        <InputField
            label="Email"
            name="Email"
            type="email"
            placeholder="Enter your email"
            postfix={
              <img src={mail} alt="Email icon" className="w-[20px] h-[16px]" />
            }
            className="w-full flex flex-col gap-2"
             inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
             value={email}
             handleValue={setEmail}
          />
         <InputField
            label="Password"
            placeholder="Enter your password"
            value={password}
            handleValue={setPassword}
            name="password"
            type="password"
            className="w-full flex flex-col gap-2"
             inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
          />
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-2 md:gap-4">
              <div>
              <input
               checked={rememberMe}
               onChange={handleCheckboxChange}
                type="checkbox"
                className="form-checkbox h-[20px] w-[20px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#D9D9D9] text-primary focus:ring-0  border-black"
              />
              </div>
              <p className="font-[600] text-[14px] leading-[18px] text-[#6B6666]">
                Remember Me{" "}
              </p>
            </div>
            <a
              href="/forgot-password"
              className="font-[600] text-[12px] md:text-[14px] leading-[18px] text-[#074C7F]"
            >
              Forgot Password?
            </a>
          </div>
          <div className="flex justify-center items-center w-full mt-[48px] md:mt-[64px] md:mb-[20px]">
            <button className={`md:mt-[28px] w-full h-[52px] md:h-[60px] border-[2px] rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-[#007BD1] border-[#0A0A0A] font-[700] text-[16px] leading-[24px] text-center text-white ${
                  isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                } transition-all duration-300 ease-in-out}`}>
              {isLoading ? <FaSpinner className="animate-spin mx-auto"/> : "Login"} 
            </button>
          </div>
          <div className="mt-[48px] flex flex-col items-center gap-8">
<div className="flex items-center gap-[9px]">
  <div className="border-t w-[108px] border-strokeColor"></div>
  <span className="text-[#6B6666] font-[500] text-[14px] leading-[18px]">or Login with</span>
  <div className="border-t w-[108px] border-strokeColor"></div>
</div>
<div className="flex items-center gap-4">
{
[<FcGoogle className="w-full h-full" />, <FaFacebookF className="text-[#1877F2] w-full h-full"/>, <FaApple className="text-[#1C1C1C] w-full h-full" />].map((item, index) => (
<button key={index} className="w-[48px] h-[48px] border border-[#808080] flex justify-center items-center rounded-full p-3">
  {item}
</button>
)) }
</div>
<div className="mt-4 mb-8 flex gap-1 justify-center items-center text-center font-[500] text-[14px] leading-[18px] text-secondary">Don't have an account?  <a href="https://dashboard.hamzaa.ca/signup" className="text-[#00325E]">{" "} Sign Up</a></div>
          </div>
        </form>
      </div>
    </div>
    </HelmetProvider>
  );
};

export default SignIn;
