
import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import clock from "../assets/images/clock.svg";
import InputField from "./inputField";
import Button from "./button";
import ModalHeader from "./modalHeader";

const EditAppointment = ({closeModal}) => {
    const [options, setOptions] = useState([
        { label: "Oil Change", value: "Oil Change" },
        { label: "Battery Repair", value: "Battery Repair" },
        { label: "Tire Change", value: "Tire Change" },
      ]);
      const [selectedOption, setSelectedOption] = useState(null);
      //   const [selectedService, setSelectedService] = useState("");
      const [dateValue, setDateValue] = useState("");
      const handleDateClick = () => {
        console.log("Date Clicked");
      };
    
      // const [timeValue, setTimeValue] = useState("");
      // const handleTimeClick = () => {
      //   document.getElementById("hiddenTimeInput").focus();
      // };
    
      const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        // Set the selected option
        const selected = options.find((option) => option.value === selectedValue);
        setSelectedOption(selected);
        // Remove the selected option from the available options
        setOptions((prevOptions) =>
          prevOptions.filter((option) => option.value !== selectedValue)
        );
      };
      const handleDeleteSelected = () => {
        // Add the deleted option back to the options list
        setOptions((prevOptions) => [...prevOptions, selectedOption]);
        // Reset the selected option to null
        setSelectedOption(null);
      };
      // const handleSelectService = (event) => {
      //   setSelectedService(event.target.value); // Update the state when selection changes
      // };
      // Function to format the date (optional)
      // const formatDate = (date) => {
      //   const options = { year: "numeric", month: "short", day: "numeric" };
      //   return new Date(date).toLocaleDateString(undefined, options);
      // };
  return (
    <div
      className="fixed inset-0 z-40 flex justify-center items-center md:items-start md:justify-end bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg w-full md:w-[60vw] rounded-l-[10px] h-[90%] md:h-full overflow-y-auto  scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover  scroll-smooth flex flex-col gap-8"
        onClick={(e) => e.stopPropagation()}
      >
      <ModalHeader
        header='Edit Appointment'
        onClick={closeModal}
        icon={ <IoIosClose className="w-full h-full" />}
      />
        
        <form className="flex flex-col gap-[26px] px-6 md:px-[60px] w-full pt-[20px] md:mt-0 bg-white">
          <div className="flex flex-col gap-[7px] w-full">
            {/* Service */}
            <InputField
              label="Service"
              asteriks={true}
              name="service"
              type="select"
              options={options}
              placeholder="Select Service"
              className="w-full flex flex-col gap-2"
              selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
              onChange={handleSelectChange}
              searchable={true}
            />
            {/* Display the selected value */}
            {selectedOption && (
              <div className="mt-[8px] flex items-center">
                <div className="h-[39px] w-auto py-[10px] px-[10px] bg-[#DBE2EF] rounded-[12px] flex gap-[10px] items-center">
                  <p className="text-[15px] leading-[19px] text-black font-[500] font-montserrat">
                    {selectedOption.label}
                  </p>
                  <button
                    className="text-[17px]"
                    onClick={handleDeleteSelected}
                  >
                    x
                  </button>
                </div>
              </div>
            )}
            {/* <label className="font-[400] text-[15px] leading-[19px] text-black">
              Service <span className="text-[#FF0000]">*</span>
            </label>
            <input
              type="text"
              className="w-[455px] h-[69px] rounded-[13px] bg-[#FAFAFA] py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
            /> */}
          </div>
          <InputField
            label="Description"
            asteriks={true}
            name="businessDescription"
            type="textarea"
            placeholder="Recurring noise"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full rounded-[12px] md:rounded-[13px]"
          />

          <InputField
          asteriks={true}
            label="Date"
            type="calendar"
            name="date"
            value={dateValue}
            handleValue={setDateValue}
            placeholder="Select a date"
            onClick={handleDateClick}
          />

          <InputField
            label="Time"
            name="time"
            type="text"
            asteriks={true}
            placeholder="Pick a time"
            postfix={<img src={clock} alt="Clock icon" className="w-5 h-5" />}
            className="w-full flex flex-col gap-2"
            inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px]"
          />
 <InputField
            label="Notes"
            asteriks={true}
            name="businessDescription"
            type="textarea"
            placeholder="For internal use of taking notes about the customer issues"
            className="w-full flex flex-col gap-2"
            inputClassName="w-full rounded-[12px] md:rounded-[13px] "
          />
          <div className="flex  items-center justify-center md:justify-around my-8 md:mt-[60px] md:mb-[100px]">
          <Button
              type="secondary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Cancel"
              className="w-[119px] hidden md:block"
            />
            <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="S"
              label="Submit"
              className="md:w-[186px] w-full"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditAppointment