const customers = [
  {
    id: 1,
    customerId: "012345",
    customerName: "Jane Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'In-progress',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
    
  },
  {
    id: 2,
    customerId: "012345",
    customerName: "Joan Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'In-progress',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Transfer',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 3,
    customerId: "012345",
    customerName: "Nola Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 4,
    customerId: "012345",
    customerName: "Rihanna Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Tire repair',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 5,
    customerId: "012345",
    customerName: "Simone Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 6,
    customerId: "012345",
    customerName: "Ashley Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 7,
    customerId: "012345",
    customerName: "Veronica Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'In-progress',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 8,
    customerId: "012345",
    customerName: "Ryan Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'In-progress',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 9,
    customerId: "012345",
    customerName: "Chloe Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  {
    id: 10,
    customerId: "012345",
    customerName: "Anna Josh",
    phoneNumber: "012333445",
    email: 'johndoe@gmail.com',
    address: '69 Toronto, Canada',
    date: "25 Jun, 24",
    carModel: 'Honda Civic (2019)',
    plateNumber: 'ABC-1234',
    make: 'Camry',
    model: 'Toyota',
    year: 2019,
    service: 'Oil Change & Tire  ...',
    dateCollection: '25 Jun, 24',
    mileage: 'Name',
    status: 'Completed',
    cost: '500,000',
    notes:'The engine needs repair',
    reminder: 'Every month',
    parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
    paymentMethod: 'Card Payment',
    amountPaid: '1500',
    description: 'The engine needs repair...',
    time: '08:00 PM',
  },
  // {
  //   id: 11,
  //   customerId: "012345",
  //   customerName: "Jane Josh",
  //   phoneNumber: "012333445",
  //   date: "25 Jun, 24",
  //   carModel: 'Honda Civic (2019)',
  //   plateNumber: 'ABC-1234',
  // service: 'Oil Change & Tire  ...',
  // dateCollection: '25 Jun, 24',
  // mileage: 'Name',
  // status: 'In-progress',
  // cost: '500,000',
  // notes:'The engine needs repair',
  // reminder: 'Every month',
  // parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
  // paymentMethod: 'Card Payment',
  // amountPaid: '1500',
  // description: 'The engine needs repair...',
  //   time: '08:00 PM',
  // },
  // {
  //   id: 12,
  //   customerId: "012345",
  //   customerName: "Jane Josh",
  //   phoneNumber: "012333445",
  //   date: "25 Jun, 24",
  //   carModel: 'Honda Civic (2019)',
  //   plateNumber: 'ABC-1234',
  // service: 'Oil Change & Tire  ...',
  // dateCollection: '25 Jun, 24',
  // mileage: 'Name',
  // status: 'In-progress',
  // cost: '500,000',
  // notes:'The engine needs repair',
  // reminder: 'Every month',
  // parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
  // paymentMethod: 'Card Payment',
  // amountPaid: '1500',
  // description: 'The engine needs repair...',
  //   time: '08:00 PM',
  // },
  // {
  //   id: 13,
  //   customerId: "012345",
  //   customerName: "Jane Josh",
  //   phoneNumber: "012333445",
  //   date: "25 Jun, 24",
  //   carModel: 'Honda Civic (2019)',
  //   plateNumber: 'ABC-1234',
  // service: 'Oil Change & Tire  ...',
  // dateCollection: '25 Jun, 24',
  // mileage: 'Name',
  // status: 'In-progress',
  // cost: '500,000',
  // notes:'The engine needs repair',
  // reminder: 'Every month',
  // parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
  // paymentMethod: 'Card Payment',
  // amountPaid: '1500',
  // description: 'The engine needs repair...',
  //   time: '08:00 PM',
  // },
  // {
  //   id: 14,
  //   customerId: "012345",
  //   customerName: "Jane Josh",
  //   phoneNumber: "012333445",
  //   date: "25 Jun, 24",
  //   carModel: 'Honda Civic (2019)',
  //   plateNumber: 'ABC-1234',
  // service: 'Oil Change & Tire  ...',
  // dateCollection: '25 Jun, 24',
  // mileage: 'Name',
  // status: 'In-progress',
  // cost: '500,000',
  // notes:'The engine needs repair',
  // reminder: 'Every month',
  // parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
  // paymentMethod: 'Card Payment',
  // amountPaid: '1500',
  // description: 'The engine needs repair...',
  //   time: '08:00 PM',
  // },
  // {
  //   id: 15,
  //   customerId: "012345",
  //   customerName: "Jane Josh",
  //   phoneNumber: "012333445",
  //   date: "25 Jun, 24",
  //   carModel: 'Honda Civic (2019)',
  //   plateNumber: 'ABC-1234',
  // service: 'Oil Change & Tire  ...',
  // dateCollection: '25 Jun, 24',
  // mileage: 'Name',
  // status: 'In-progress',
  // cost: '500,000',
  // notes:'The engine needs repair',
  // reminder: 'Every month',
  // parts: ['Oil Filter', 'Drain Plug Gasket','Calibrator','Oil Filter'],
  // paymentMethod: 'Card Payment',
  // amountPaid: '1500',
  // description: 'The engine needs repair...',
  //   time: '08:00 PM',
  // },
 
];

export default customers;
