import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const Supplier = () => {
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Supplier</title>
                <meta name="description" content="Hamzaa Supplier page" />
              </Helmet>
    <div className=" ">
   <h1 className=''>Supplier</h1> 
    </div>
    </HelmetProvider>
  )
}

export default Supplier