import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import { FaLongArrowAltDown } from "react-icons/fa";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";
const chartData = [
  { month: "M", desktop: 186 },
  { month: "T", desktop: 305 },
  { month: "W", desktop: 237 },
  { month: "T", desktop: 73 },
  { month: "F", desktop: 209 },
  { month: "S", desktop: 214 },
  { month: "S", desktop: 214 },
];

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#1A2233",
  },
};

export function Component() {
  return (
    <Card className="w-[25vw] 2xl:w-[17vw] h-[310px] flex flex-col 2xl:flex-1">
      <CardHeader className="flex flex-col justify-center items-center gap-[25px] ">
        <CardTitle className="text-center font-[700] text-[14px] leading-[18px] text-[#2E2E30]">
          Average Repair Time per Job
        </CardTitle>
        <div className="flex flex-col items-center gap-1">
          <div className="flex gap-2 items-center">
            {" "}
            <h2 className="font-[700] text-[20px] leading-[26px] text-[#2E2E30]">2h 20m</h2>
            <FaLongArrowAltDown className="text-[#1A2233]" />
          </div>
          <CardDescription className="font-[400] text-[12px] leading-[16px] text-[#2E2E30]">Average time you spent per day</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="flex-1">
        <ChartContainer config={chartConfig} className="max-h-[150px] w-full" >
          <BarChart accessibilityLayer data={chartData} width="100%"   
            height="100%" >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="desktop" fill="var(--color-desktop)" radius={8} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
