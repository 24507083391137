import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from './button';
import { Clock04Icon } from 'hugeicons-react';

const TodayAppointment = () => {
  const navigate = useNavigate();
  const appointments = [
{
  name: 'Bola Ege',
  car: 'Honda Civic (2019)',
  number: 'ABC-1234',
  time: '12:30 PM'
},
{
  name: 'Bola Ege',
  car: 'Honda Civic (2019)',
  number: 'ABC-1234',
  time: '12:30 PM'
},

  ];
  const handleViewAll = () => {
    navigate(`/appointments`);}
  return (
    <div className='w-full h-[354px] rounded-[4px] shadow-md bg-[#F8FAFF] font-dmsans'>
      <h2 className='px-[16px] py-[16px] font-[500] text-[16px] leading-[20px] text-[#161414]'>Today’s Appointment</h2>

  {appointments.map((appointment, index) => (
    <div className={`flex flex-col gap-5 p-4 ${index === 0 ? "bg-[#E8EAED]" : "bg-[#F8FAFF]"}`}>
<div className='flex flex-col gap-[6px]' key={index}>
<div className='flex justify-between items-start'>
<div className='flex gap-1 items-center'>
<Clock04Icon className='w-5 h-5'/>
<h3 className='font-[700] text-[14px] leading-[18px] tracking-[0.5px] text-primary'>{appointment.time}</h3>
</div>
<button className="flex items-center bg-[#A3D4FF]  w-auto h-[17px] rounded-[10px] py-1 px-2">
            <span className="font-[400] text-[10px] leading-[13px] text-center text-[#00325E]">
              Upcoming
            </span>
          </button>
 </div>
 
 
</div>
<div className='flex flex-col gap-2'>
<p className='font-[500] text-[14px] leading-[18px] tracking-[0.5px] text-[#1C1C1C]'>{appointment.name}</p>
<div className='flex gap-[10px]'>
  <p className='font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-lightGreyBg'>{appointment.number}</p>
  <p className='font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-lightGreyBg'>{appointment.time}</p>
  </div>
</div>
</div>
  ))}
 
  <div className='flex justify-center items-center py-4'>
  <Button
              type="tertiary"
              borderRadius="rounded-[5px]"
              size="XXS"
              label=" View All"
              className="w-[91px] "
              onClick={handleViewAll}
            />

</div>
    </div>
  )
}

export default TodayAppointment