const timeZones = [
    "UTC-12:00 — Baker Island, Howland Island",
    "UTC-11:00 — Niue, American Samoa, Samoa",
    "UTC-10:00 — Hawaii-Aleutian Standard Time (HST), Cook Islands",
    "UTC-09:00 — Alaska Standard Time (AKST), Gambier Islands",
    "UTC-08:00 — Pacific Standard Time (PST), Clipperton Island",
    "UTC-07:00 — Mountain Standard Time (MST)",
    "UTC-06:00 — Central Standard Time (CST)",
    "UTC-05:00 — Eastern Standard Time (EST), Cuba Standard Time",
    "UTC-04:00 — Atlantic Standard Time (AST), Bolivia, Venezuela",
    "UTC-03:00 — Argentina, Brazil (some areas), Uruguay",
    "UTC-02:00 — South Georgia and the South Sandwich Islands",
    "UTC±00:00 — Western European Time (WET), Coordinated Universal Time (UTC)",
    "UTC+01:00 — West Africa Time (WAT), Central European Time (CET), West Africa Time (WAT)",
    "UTC+02:00 — Eastern European Time (EET), Central Africa Time (CAT)",
    "UTC+03:00 — Moscow Time (MSK), East Africa Time (EAT)",
    "UTC+04:00 — Azerbaijan Time (AZT), Samara Time (SAMT)",
    "UTC+05:00 — Pakistan Standard Time (PKT), Yekaterinburg Time (YEKT)",
    "UTC+06:00 — Bangladesh Standard Time (BST), Omsk Time (OMST)",
    "UTC+07:00 — Indochina Time (ICT), Krasnoyarsk Time (KRAT)",
    "UTC+08:00 — China Standard Time (CST), Australian Western Standard Time (AWST)",
    "UTC+09:00 — Japan Standard Time (JST), Korea Standard Time (KST)",
    "UTC+10:00 — Australian Eastern Standard Time (AEST), Papua New Guinea Time (PGT)",
    "UTC+11:00 — Solomon Islands Time (SBT), Vanuatu Time (VUT)",
    "UTC+12:00 — Fiji Standard Time (FJT), Kamchatka Time (PETT)"
  ];
  
  export default timeZones