import React, {useState} from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subTabs = [
    { name: "My Account", path: "/settings/account" },
    { name: "Plan and Billings", path: "/settings/plan-billings" },
    { name: "Notifications & Alerts", path: "/settings/notifications-alerts" },
    { name: "Integrations", path: "/settings/integrations" },
    { name: "System settings", path: "/settings/system-settings" },
  ];
  const handleSubTabClick = (path) => {
    navigate(path);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(subTabs[0]);

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    handleSubTabClick(tab.path);
    setDropdownOpen(false);
  };

  return (
    <div className=" absolute top-[85px] lg:top-[90px] lg:pt-[40px] w-full lg:w-[calc(100%-319px)] z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF]">
  
    <div className="block lg:hidden relative bg-[#F8FAFF] py-[16px] w-full">
      {/* Dropdown Trigger */}
      <div className="flex justify-center items-center">
      <button
        className="flex justify-center items-center gap-4 text-[16px] leading-[32px] font-[500] text-primary"
        onClick={handleDropdownToggle}
      >
        {selectedTab.name}
      
        {isDropdownOpen ?  <IoIosArrowUp className="w-5 h-5 text-primary"/> : <IoIosArrowDown className="w-5 h-5 text-primary" />}
    
      </button>
</div>
      {/* Dropdown Options */}
      {isDropdownOpen && (
        <ul className="absolute z-10 w-full bg-white rounded-[10px] shadow-lg mt-2">
          {subTabs.map((tab) => (
            <li key={tab.path}>
              <button
                onClick={() => handleTabSelect(tab)}
                className={`w-full text-left px-4 py-2 text-[14px] leading-[18px] hover:bg-gray-100 ${
                  location.pathname.startsWith(tab.path)
                    ? "font-[600] text-[#00325E]"
                    : "font-[400] text-lightGreyBg"
                }`}
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
 
    <div className="bg-white px-6 lg:px-0 lg:bg-[#F8FAFF]">
      <div className="hidden lg:flex flex-col gap-1 mb-4">
        <h1 className="font-[700] text-[24px] leading-[31px] text-[#1C1C1C]">
          Settings
        </h1>
        <h2 className="font-[400] text-[12px] leading-[16px] text-lightGreyBg">
          Manage your shop's preferences, and system configurations.
        </h2>
      </div>
      <div className="hidden lg:block mb-[21px] w-full border border-[#D9D9D9]"></div>

      <div className="mt-[22px] hidden lg:flex justify-between items-center w-full bg-white h-auto  rounded-[10px] ">
        {subTabs.map((tab) => (
          <button
            key={tab.path}
            onClick={() => handleSubTabClick(tab.path)}
            className={` text-[14px] leading-[18px] py-[10px] px-6 w-auto h-auto  ${
              location.pathname.startsWith(tab.path)
                ? " font-[600] text-[#00325E] bg-settingsActive border-b-2 border-[#00325E] "
                : " text-lightGreyBg font-[400]"
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="">
        <Outlet />
      </div>
      </div>
    </div>
  );
};

export default Settings;
