import React from "react";
import { LuDot } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import { HelmetProvider, Helmet } from "react-helmet-async";


const Notification = () => {
  const notifications = [
    {
      id: 1,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 2,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 3,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 4,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 5,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 6,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 7,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 8,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 9,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
    {
      id: 10,
      message: "You have added a new customer to your database.",
      name: "Adora",
      date: "March 4",
    },
  ];
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Notification</title>
                <meta name="description" content="Hamzaa Notification page" />
              </Helmet>
    <div className="lg:absolute lg:top-[90px] z-10 lg:px-[46px] w-full lg:w-[calc(100%-319px)] scroll-smooth font-dmsans lg:pt-[60px] bg-white lg:bg-[#F8FAFF] min-h-[100vh] pb-[17px] lg:pb-[90px] ">
      <div className="w-full h-full lg:h-[702px]  pt-[92px] lg:pt-0 bg-white shadow-lg lg:rounded-[7px] rounded-b-none border border-[#F1F1F1]">
        <div className="px-[35px] py-[35px]">
          <div className="flex justify-between pt-2 pb-6 sticky top-0 z-20">
            <h2 className="text-[#00325E] font-[700] text-[16px] lg:text-[20px] leading-[25px]">
              New for you
            </h2>

            <button className="font-[400] text-[14px] lg:text-[16px] leading-[20px] text-[#575757]">
              Mark all as read
            </button>
          </div>
          <div className="h-[595px] flex flex-col gap-[29px] lg:gap-0 overflow-y-auto no-scrollbar">
            {notifications.map((not, index) => (
              <div
                className={`w-full h-auto lg:h-[66px] rounded-[5px] py-[10px] px-[10px] flex items-center ${
      index === 0 ? 'bg-[#F8F8F8]' : 'bg-white'
    }`}
                key={index}
              >
                <div className="flex justify-between items-center w-full ">
                  <div className="flex flex-col gap-[5px]">
<h3 className="font-[400] text-[14px] lg:text-[20px] leading-[24px] text-[#575757]">{not.message}</h3>
<div className="flex items-center font-[400] text-[12px] lg:text-[14px] leading-[16px] text-[#8E8E93]">
<span>{not.name}</span>
<div className="flex items-center">
<LuDot />
<span>{not.date}</span>
</div>
</div>
                  </div>
                  <GoDotFill className="w-[12px] h-[12px] text-[#00325E]"/>
                </div>
              </div>
            ))}
          </div>
        </div>
       
      </div>
      <div className="h-[63px] w-full py-[20px] lg:pt-[25px] lg:pb-[13px] px-[35px] border bg-[#F8F8F8] rounded-b-[7px] flex flex-col items-center justify-center lg:items-start">
          <h2 className="font-[700] text-[16px] lg:text-[20px] leading-[24px] text-[#575757] lg:text-left text-center">Previous Notifications</h2>
        </div>
    </div>
    </HelmetProvider>
  );
};

export default Notification;
