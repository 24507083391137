import React, { useState } from "react";
import InputField from "../components/inputField";
import mail from "../assets/images/mail.svg";
import user from "../assets/images/user.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
import AuthHeader from "../components/authHeader";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { signupUser } from "../store/authSlice";
import { FaSpinner } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = [
    { label: "Automobile Repair Shop", value: "Automobile Repair Shop" },
    { label: "Automobile Tire shop", value: "Automobile Tire shop" },
    {
      label: "Automobile body and collision",
      value: "Automobile body and collision",
    },
    {
      label: "Automobile Oil change shop",
      value: "Automobile Oil change shop",
    },
    { label: "Mobile Auto repair shop", value: "Mobile auto repair shop" },
    { label: "Motorcycle repair shop", value: "Motorcycle repair shop" },
    { label: "Bicycle shop", value: "Bicycle shop" },
    { label: "Heavy equipment shop", value: "Heavy equipment shop" },
    { label: "Electric vehicle shop", value: "Electric vehicle shop" },
    {
      label: "Medium and Heavy-duty shop",
      value: "Medium and Heavy-duty shop",
    },
    { label: "Marine shop", value: "	Marine shop" },
    { label: "Parts and accessories", value: "Parts and accessories" },
    { label: "Performance shop", value: "Performance shop" },
    { label: "RV Shop", value: "RV Shop" },
    { label: "Others", value: "Others" },
  ];

  const sizes = [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessSize, setBusinessSize] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (
      !email ||
      !password ||
      !confirmPassword ||
      !firstName ||
      !lastName ||
      !businessName ||
      !businessCategory ||
      !businessSize ||
      !phone
    ) {
      toast.info("All fields are required!");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.info("Please enter a valid email address!");
      return;
    }
    if (password.length < 7) {
      toast.info("Password must be at least 7 characters long!");
      return;
    }
    if (password !== confirmPassword) {
      toast.info("Passwords do not match!");
      return;
    }
    if (!termsAccepted) {
      // Check if terms are accepted
      toast.info("You must accept the terms and conditions.");
      return;
    }
    setIsLoading(true);
    try {
      await dispatch(
        signupUser({
          firstName,
          lastName,
          email,
          password,
          businessName,
          businessCategory,
          businessSize,
          phone,
        })
      ).unwrap();

      toast.success("Sign up successful!");
      setTimeout(() => {
        toast.success("Email verification has been sent");
      }, 2000);

      navigate("/signin");
    } catch (error) {
      toast.info(error || "Sign up failed, please try again later."); // Assuming API provides detailed error messages
    } finally {
      setIsLoading(false); // Always stop loading state, whether success or error
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Hamzaa Sign up</title>
        <meta name="description" content="Hamzaa Sign up page" />
      </Helmet>
      <div className="flex w-full min-h-[100vh] font-dmsans">
        {/* Background image container */}
        <div className="md:fixed top-0 left-0 md:w-[30vw] h-[100vh] bg-signup-image bg-cover bg-no-repeat md:flex justify-end">
          {/* Content box aligned at the bottom */}
          <div className="hidden absolute bottom-5 left-5 w-[20vw]  min-h-[25vh] border-[2px] bg-signup-gradient backdrop-blur-[40px] border-[#F5F5F533]  rounded-[20px] md:flex justify-center items-center p-[20px] lg:p-[45px] text-white font-[400] text-[16px] leading-[24px] 2xl:text-[20px] 2xl:leading-[28px]">
            "I can effectively manage my auto repair shop while retaining my
            customers”
          </div>
        </div>
        <div className="md:absolute w-full md:ml-[30%] md:w-[70%] md:mt-0 mt-[50px] flex flex-col md:p-[80px] overflow-hidden">
          <div className="md:px-0 px-6">
            <AuthHeader />
          </div>
          <div className="flex flex-col px-6 md:px-0 gap-[6px] mt-[44px] mb-[50px] md:mt-[60px] md:mb-[80px]">
            <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] tracking-[0.3px] text-[#1C1C1C]">
              Create an account
            </h2>
            <p className="font-[400] text-[12px] leading-[18px] md:text-[16px] md:leading-[28px] tracking-[0.09px] text-lightGreyBg">
              Join now and get exclusive discount on your first plan
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 px-6 md:px-0 w-full md:w-[80%]"
          >
            <div className="flex flex-col md:flex-row items-center gap-[12px] w-full">
              <InputField
                required={true}
                label="First Name"
                name="firstName"
                type="text"
                placeholder="Enter your name"
                postfix={
                  <img
                    src={user}
                    alt="User icon"
                    className="w-[20px] h-[24px]"
                  />
                }
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px] "
                value={firstName}
                handleValue={setFirstName}
              />
              <InputField
                label="Last Name"
                name="lastName"
                type="text"
                required={true}
                placeholder="Enter your name"
                postfix={
                  <img
                    src={user}
                    alt="User icon"
                    className="w-[20px] h-[24px]"
                  />
                }
                className="w-full flex flex-col gap-2"
                inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
                value={lastName}
                handleValue={setLastName}
              />
            </div>
            <InputField
              required={true}
              label="Email"
              name="Email"
              type="email"
              placeholder="Enter your email"
              postfix={
                <img
                  src={mail}
                  alt="Email icon"
                  className="w-[20px] h-[16px]"
                />
              }
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
              value={email}
              handleValue={setEmail}
            />
            <div className="flex flex-col gap-2">
              <label
                htmlFor="phoneNumber"
                className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block"
              >
                Phone Number
              </label>
              <PhoneInput
                required
                country={"ca"} // Default country code
                value={phone}
                onChange={setPhone}
                placeholder="Enter your number"
                enableSearch={true} // Enables search functionality
                searchPlaceholder="Search countries"
                inputStyle={{
                  width: "100%",
                  height: "55px",
                  borderRadius: "10px",
                  padding: "16px 16px 16px 90px",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#1A2233" /* replace with your primary color */,
                  borderColor: "#D9D9D9",
                }}
                buttonStyle={{
                  width: "73px",
                  height: "55px",
                  padding: "12px 10px 12px 10px",
                  borderRight: "1px solid #e3e3e3",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "10px 0 0 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transition: "background-color 0.3s ease",
                }}
                dropdownStyle={{ left: "80px" }}
                buttonClass="custom-flag-dropdown"
              />
            </div>
            <InputField
              required={true}
              label="Business Name"
              name="businessName"
              type="text"
              placeholder="Enter your business name"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[60px]  md:h-[56px] rounded-[10px]"
              value={businessName}
              handleValue={setBusinessName}
            />
            <InputField
              required={true}
              label="Business Size"
              name="businessSize"
              handleValue={setBusinessSize}
              value={businessSize}
              type="select"
              options={sizes}
              placeholder="Select"
              className="w-full flex flex-col gap-2"
              selectClassName="w-full h-[60px] md:h-[56px] rounded-[10px] flex justify-center items-center"
            />
            <InputField
              required={true}
              label="Business Category"
              name="businessName"
              handleValue={setBusinessCategory}
              value={businessCategory}
              type="select"
              options={categories}
              placeholder="Select"
              className="w-full flex flex-col gap-2"
              selectClassName="w-full h-[60px] md:h-[56px] rounded-[10px] flex justify-center items-center"
            />

            <InputField
              required={true}
              label="Password"
              placeholder="Enter your password"
              value={password}
              handleValue={setPassword}
              name="password"
              type="password"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
            />
            <InputField
              required={true}
              label="Confirm Password"
              placeholder="Enter your password"
              value={confirmPassword}
              handleValue={setConfirmPassword}
              name="confirmPassword"
              type="password"
              className="w-full flex flex-col gap-2"
              inputClassName="w-full h-[60px] md:h-[56px] rounded-[10px]"
            />

            <div className="flex items-center gap-2 md:gap-4 w-full">
              <div>
                <input
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  type="checkbox"
                  className="form-checkbox h-[20px] w-[20px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#D9D9D9] text-primary focus:ring-0  border-black"
                />
              </div>
              <p className="font-[600] text-[12px] leading-[16px] md:text-[14px] md:leading-[18px] text-[#6B6666]">
                By creating your account, you agree to the{" "}
                <a href="https://hamzaa.ca/terms" className="text-[#FD6B22]">
                  {" "}
                  Terms of Service{" "}
                </a>
                and{" "}
                <a href="https://hamzaa.ca/privacy" className="text-[#FD6B22]">
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="flex justify-center items-center w-full mt-[48px] mb-[10px] md:mt-[64px] ">
              <button
                type="submit"
                disabled={isLoading}
                className={`md:mt-[28px] w-full h-[52px] md:h-[60px] border-[2px] rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-[#007BD1] border-[#0A0A0A] font-[700] text-[16px] leading-[24px] text-center text-white ${
                  isLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                } transition-all duration-300 ease-in-out}`}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin mx-auto" />
                ) : (
                  " Sign Up"
                )}
              </button>
            </div>
            <div className="mt-4 mb-8 flex gap-1 justify-center items-center font-[500] text-[14px] text-center leading-[18px] text-secondary">
              Already have an account?{" "}
              <a
                href="https://dashboard.hamzaa.ca/signin"
                className="text-[#00325E]"
              >
                {" "}
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default SignUp;
