import vinDark from "../../assets/images/vinDark.svg";
import vinWhite from "../../assets/images/vinWhite.svg";
import { LayoutDashboard } from "lucide-react";
import { UserGroupIcon } from "hugeicons-react";
import { CalendarCheckIn01Icon } from "hugeicons-react";
import { Icon } from "@iconify/react";
import { Car01Icon } from "hugeicons-react";
import { HelpSquareIcon } from "hugeicons-react";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import { Login03Icon } from "hugeicons-react";

const handleLogout = () => {
  // Clear authentication-related data (e.g., localStorage)
  localStorage.removeItem("authToken");
  localStorage.removeItem("merchant");
  // Navigate to the login page
  window.location.href = "/signin";
};

const tabs = [
  { subHeading: "Menu",  },
  {
    tabName: "Dashboard",
    headerTitle: "Overview",
    mobileActiveIcon: <LayoutDashboard className="text-primary w-[18px] h-[18px]" />,
    mobileInactiveIcon: <LayoutDashboard className="text-white w-[18px] h-[18px]" />,
    path: "/",
    subHeading: "Menu",
  },
  {
    tabName: "Customers",
    headerTitle: "Customer",
    mobileActiveIcon: <UserGroupIcon className="text-primary w-[18px] h-[18px]"  variant={"stroke"}/>,
    mobileInactiveIcon: <UserGroupIcon className="text-white w-[18px] h-[18px]"  variant={"stroke"}/>,
    path: "/customers/list",
    subHeading: "Menu",
    subTabs: [
      { name: "Customer List", path: "/customers/list" },
      { name: "Service History", path: "/customers/service-history" },
      { name: "Invoice", path: "/customers/invoice" },
    ],
  },
  { subHeading: "Manage" },
  {
    tabName: "Appointments",
    headerTitle: "Appointments",
    path: "/appointments",
    mobileActiveIcon: <CalendarCheckIn01Icon className="text-primary w-[18px] h-[18px]"  variant={"stroke"}/>,
    mobileInactiveIcon:  <CalendarCheckIn01Icon className="text-white w-[18px] h-[18px]"  variant={"stroke"}/>,
    subHeading: "Manage",
  },
  {
    tabName: "Inventory",
    headerTitle: "Inventory",
    path: "/inventory",
    mobileActiveIcon:<Icon icon="charm:clipboard-tick" className="text-primary w-[18px] h-[18px]" />,
    mobileInactiveIcon: <Icon icon="charm:clipboard-tick" className="text-white w-[18px] h-[18px]" />,
    subHeading: "Manage",
  },
  {
    tabName: "Part Ordering",
    headerTitle: "Part Ordering",
    mobileActiveIcon: <Car01Icon className="text-primary w-[18px] h-[18px]"  variant={"stroke"}/>,
    mobileInactiveIcon: <Car01Icon className="text-white w-[18px] h-[18px]"  variant={"stroke"}/>,
    path: "/part-ordering",
    subHeading: "Manage",
    subTabs: [
      { name: "Order History", path: "/part-ordering" },
      { name: "Supplier", path: "/part-ordering/supplier" },
    ],
  },
  {
    tabName: "VIN Lookup",
    headerTitle: "VIN Lookup",
    path: "/vin-lookup",
    mobileActiveIcon: vinDark,
    mobileInactiveIcon: vinWhite,
    subHeading: "Manage",
  },
  {
    tabName: "Referral",
    headerTitle: "Referral",
    path: "/",
    mobileActiveIcon: <Icon icon="mdi:users-group-outline" className="text-white w-[18px] h-[18px]"/>,
    mobileInactiveIcon: <Icon icon="mdi:users-group-outline" className="text-white w-[18px] h-[18px]"/>,
  
  },
  {
    tabName: "Upgrade Plan",
    headerTitle: "Upgrade Plan",
    path: "/",
    mobileActiveIcon:<HiOutlineArrowUpTray className="text-white w-[18px] h-[18px]" />,
    mobileInactiveIcon:<HiOutlineArrowUpTray className="text-white w-[18px] h-[18px]" />,
  
  },

  {
    tabName: "Help",
    headerTitle: "Help",
    path: "/help",
    mobileActiveIcon: <HelpSquareIcon className="text-white w-[18px] h-[18px]"/>,
    mobileInactiveIcon:<HelpSquareIcon className="text-white w-[18px] h-[18px]"/>,
    subHeading: null,
  },
  {
    tabName: "Logout",
    headerTitle: null,
    onClick: handleLogout,
    mobileActiveIcon: <Login03Icon className="text-[#C21616] w-[18px] h-[18px]"/>,
    mobileInactiveIcon: <Login03Icon className="text-[#C21616] w-[18px] h-[18px]"/>,
    subHeading: null,
  },
];

export default tabs;
