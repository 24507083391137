import React from 'react';


const ModalHeader = ({
	header,
	onClick,
	icon,
	className,
}) => {
	return (
		<div
			className={`flex justify-center top-0  md:justify-between md:items-center px-6 md:px-[60px] py-[24px]  md:pt-8  w-full sticky  z-30 bg-white  ${className} `}>
			<h1 className='font-[500] text-[20px] md:text-[32px] leading-[40px] -tracking-[3%] text-[#1C1C1C]'>
				{header} 
			</h1>

			<button
				onClick={onClick}
				className={`absolute right-4 md:right-[60px] shadow-md md:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  md:justify-center md:items-center`}>
				{icon}
			</button>
		</div>
	);
};

export default ModalHeader;
