import React, { useState } from "react";
import Switch from "../switch";
import { HelmetProvider, Helmet } from "react-helmet-async";

const NotifcationBox = ({ heading, paragraph, isOn, toggleSwitch }) => {
  return (
    <div className="flex justify-between gap-[40px] md:gap-0 items-center w-full">
      <div className="flex flex-col gap-[2px] mt-2">
        <h2 className="font-[500] text-[16px] leading-[21px] md:text-[20px] md:leading-[26px] text-[#1C1C1C]">
          {heading}
        </h2>
        <p className="font-[400] text-[14px] leading-[18px] md:text-[16px] md:leading-[21px] text-lightGreyBg">
          {paragraph}
        </p>
      </div>
      <Switch toggleSwitch={toggleSwitch} isOn={isOn} />
    </div>
  );
};
const NotifcationsAlerts = () => {
  const [state, setState] = useState({
    isAppointmentReminderOn: false,
    isInventoryAlertsOn: false,
    isDesktopNotificationOn: false,
    isEmailNotificationOn: false
  });
  const toggleSwitch = (key) => {
    setState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Settings - Notifcations & Alerts</title>
                <meta name="description" content="Hamzaa Settings Notifications & Alerts page" />
              </Helmet>
    <div className="w-full h-auto rounded-[8px] mt-8 mb-4 flex justify-between items-center bg-white p-4 md:p-8 shadow-md">
      <div className="flex flex-col gap-10 w-full">
      <NotifcationBox
      heading="Appointment Reminders"
      paragraph="Notifications for upcoming appointments will be enabled if turned on"
        isOn={state.isAppointmentReminderOn}
        toggleSwitch={() => toggleSwitch("isAppointmentReminderOn")}
      />
    
      <NotifcationBox
      heading="Inventory Alerts"
      paragraph="Get low-stock alerts for parts, will be enabled if turned on"
        isOn={state.isInventoryAlertsOn}
        toggleSwitch={() => toggleSwitch("isInventoryAlertsOn")}
      />
      <NotifcationBox
      heading="Desktop Notification"
      paragraph="Receive desktop notifications when you are away from the app , will be enabled when turned on"
        isOn={state.isDesktopNotificationOn}
        toggleSwitch={() => toggleSwitch("isDesktopNotificationOn")}
      />
      <NotifcationBox
      heading="Email Notification"
      paragraph="Receive notifications on your registered email when you get a notification , will be enabled when turned on"
        isOn={state.isEmailNotificationOn}
        toggleSwitch={() => toggleSwitch("isEmailNotificationOn")}
      />
      </div>
    </div>
    </HelmetProvider>
  );
};

export default NotifcationsAlerts;
