import React, {useState, useRef} from "react";
import { IoIosArrowDown, IoIosArrowUp  } from "react-icons/io";
import calendar from "../assets/images/solarcalendar.svg";
import search from "../assets/images/search.svg";
import orders from "../utils/dummyData/orderData";
import Table from "../components/Table";
import { IoSearch } from "react-icons/io5";
import Pagination from "../components/pagination";
import { BsDownload } from "react-icons/bs";
import Dropdown from "../components/dropdown";
import DownloadDropdown from "../components/downloadDropdown";
import useClickOutside from "../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";

const OrderHistory = () => {
   // State for dropdown visibility
   const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
   const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const downloadDropdownRef = useRef(null);
  const downloadButtonRef = useRef(null);

  const statusDropdownRef = useRef(null);
  const statusButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Status dropdown
  useClickOutside([statusDropdownRef, statusButtonRef], () =>
    setIsStatusDropdownOpen(false));

  // Handle click outside for Download dropdown
  useClickOutside([downloadDropdownRef, downloadButtonRef], () =>
    setIsDownloadDropdownOpen(false));
   // Dropdown actions
   const dateActions = [
     { label: "Today", onClick: () => console.log("Filter: Today") },
     { label: "This Week", onClick: () => console.log("Filter: This Week") },
     { label: "This Month", onClick: () => console.log("Filter: This Month") },
   ];
 
   const statusActions = [
     { label: "Completed", onClick: () => console.log("Filter: Completed") },
     { label: "In-progress", onClick: () => console.log("Filter: In-progress") },
     { label: "Pending", onClick: () => console.log("Filter: Pending") },
   ];
   const downloadActions = [
    { label: "Download as PDF", onClick: () => console.log("Download as PDF") },
    { label: "Download as Plain Text", onClick: () => console.log("Download as Plain Text") },
    { label: "Download as Microsoft word", onClick: () => console.log("Download as Microsoft word") },
  ];
  const downloadMobileActions = [
    { label: "PDF", onClick: () => console.log("Download as PDF") },
    { label: "Plain Text", onClick: () => console.log("Download as Plain Text") },
    { label: "Microsoft word", onClick: () => console.log("Download as Microsoft word") },
  ];
  const headers = [
    "S/N",
    "Order ID",
    "Date ",
    "Supplier",
    "Quantity Ordered",
    "Cost",
    "Total Cost",
    "Status"
  ];
  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
<td className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20 `}
      style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}>
                    {item.id}
                  </td>
                  <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 py-4 my-2">
                    {item.orderId}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    {item.date}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    {item.supplier}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    {item.qty}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    ${item.price}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    ${item.totalAmount}
                  </td>
                  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    <div className="flex justify-center">
                      <div
                        className={`w-auto h-auto text-nowrap lg:h-[21px] rounded-[16px] py-[2px] px-[8px] text-right flex justify-center items-center font-[400] text-[12px] leading-[17px]
      ${
        item.status === "Order Completed"
          ? "text-[#027A48] bg-[#ECFDF3]" // Styles for 'completed'
          : item.status === "Awaiting Delivery"
          ? "text-[#FF9500] bg-progressBg"
          : item.status === "Order Cancelled"
          ? "text-[#EC5252] bg-canceledBg" // Styles for 'inprogress'
          : "text-gray-500 bg-gray-200" // Default styles for any other status
      }`}
                      >
                        {item.status}
                      </div>
                    </div>
                  </td>
    </tr>
  );
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Order History</title>
                <meta name="description" content="Hamzaa Order History page" />
              </Helmet>
    <div className="">
     <div className="pt-[100px] lg:pt-[24px]  flex gap-5 lg:gap-0 lg:justify-between items-center">
     <h1 className="text-primary font-[700] text-[20px] leading-[32px] lg:text-left text-center">
        Service History
      </h1>
      <div className="relative">
           <button ref={downloadButtonRef}  onClick={() => setIsDownloadDropdownOpen((prev) => !prev)} className="flex justify-between lg:justify-center items-center gap-1 border-[1px] border-[#D9D9D9] w-full lg:w-auto h-[50px] rounded-[5px] py-[4px] px-[8px] shadow-sm bg-[#FAFDFF]">
                    <div className="block lg:hidden"><BsDownload className='text-[#1C1C1C] w-4 h-4'/></div>
                    <div className="flex items-center gap-8 lg:gap-1">
                    <span className="text-center text-[#1C1C1C]">
                     <span className="md:block hidden font-dmsans font-[400] text-[12px] leading-[18px]"> Download </span> 
                     {/* <span className="block lg:hidden font-montserrat font-[400] text-[14px] leading-[21px] ">PDF</span> */}
                    </span>
                    <button className="flex justify-center items-center">
                     {isDownloadDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" />  : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
                    </button>
                    </div>
                  </button>
                  <div className="hidden md:block">
                  {isDownloadDropdownOpen && (
                    <div ref={downloadDropdownRef}>
              <DownloadDropdown
                actions={downloadActions}
                onClose={() => setIsDownloadDropdownOpen(false)}
                position="top-[60px] right-[10px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
            </div>
                  <div className="block md:hidden">
                  {isDownloadDropdownOpen && (
                    <div ref={downloadDropdownRef}>
              <DownloadDropdown
                actions={downloadMobileActions}
                onClose={() => setIsDownloadDropdownOpen(false)}
                position="top-[60px] right-[10px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
            </div>
                  </div>
          </div>
     
    
      <div className="flex justify-between  pt-[40px] pb-[32px] w-full z-30 bg-white lg:bg-[#F8FAFF]">
        {/* Search input */}
        <div className="relative lg:block hidden">
          <input
            type="search"
            className="w-[323px] h-[50px] rounded-[10px] border border-[#F1F1F1] py-4 pr-5 pl-10 shadow-sm outline-primary"
            placeholder="Search by Service"
          />
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 pointer-events-none">
            <img src={search} alt="search icon" className="w-[18px] h-[18px]" />
          </div>
        </div>
       {/* Date and Status */}
       <div className="flex items-center gap-4">
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-[122px] h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date
              </span>
              <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <Dropdown
                actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>

          <div className="relative">
            <button ref={statusButtonRef}
              onClick={() => setIsStatusDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[12px] leading-[18px] text-center text-[#1C1C1C]">
                Status
              </span>
                           {isStatusDropdownOpen? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]"/> :<IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isStatusDropdownOpen && (
              <div ref={statusDropdownRef}>
              <Dropdown
                actions={statusActions}
                onClose={() => setIsStatusDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
              />
              </div>
            )}
          </div>
            {/* Mobile Search */}
            <div className="md:hidden flex justify-center items-center bg-primary  w-[52px] h-[44px] py-[12px] px-4 rounded-[8px] cursor-pointer">
          <IoSearch className="text-[#FAFDFF] w-full h-full" />
          </div>
        </div>
      </div>
      <div className="overflow-hidden h-[609px] w-full">
      <Table headers={headers} data={orders} renderRow={renderRow} boxClassName='overflow-y-auto h-full'/>
        
      </div>
     <Pagination/>
    </div>
    </HelmetProvider>
  );
};

export default OrderHistory;
