import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className="flex flex-col gap-6 justify-center items-center h-[100vh] pt-[50px] bg-[#F8FAFF]">
    <h2 className="text-center text-[30px] text-primary">Error...</h2>
     <Link to="/" >Redirect to home page...</Link>
  </div>
  )
}

export default Error