import React from "react";
import { IoIosClose } from "react-icons/io";

const VinDetails = ({ closeModal }) => {
  return (
    <div
      className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans"
      onClick={closeModal}
    >
      <div
        className="bg-white shadow-lg lg:p-[44px] w-full h-full lg:h-auto md:w-[50vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center lg:justify-between pb-[64px] lg:pb-[56px] bg-white sticky top-0 py-6 z-30">
          <h1 className="font-[700] text-[20px] lg:text-[32px] leading-[32px] -tracking-[3%] text-primary">
            VIN: 4Y1SL65848Z411439
          </h1>
          <button
            className="absolute right-4 lg:static shadow-md md:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
            onClick={closeModal}
          >
            <IoIosClose className="w-full h-full" />
          </button>
        </div>
        <div className="flex flex-col gap-4 px-[40px] lg:px-0">
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
                Country:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
                Canada
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
            Manufacturer:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
                June
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
               Model:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
               AB 2020
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
               Class:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
               T Class
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
               Region:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
               The region
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-strokeColor">
               Year:
              </h2>
              <p className="text-[#1C1C1C] font-[400] text-[14px] leading-[18px] tracking-[0.5px]">
               2020
              </p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default VinDetails;
