import React , {useState, useRef} from 'react'
import AnalyticsDropdown from '../analyticsDropdown';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../../assets/images/solarcalendar.svg"
import Table from '../Table';
import ExportDocumentButton from '../exportDocumentButton';
import {Component as MultipleChart} from "../../components/charts/MultipleChart"
import useClickOutside from '../../utils/customhooks/useClickOutside';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Inventory = () => {
  const inventory = [
    {id: 1, part: "Engine Oil Filter (ACME)", qtyStock: "1258", date: "25 Jun, 24", supplier: "Acme Auto Parts", stockLevel : "Low Stock"},
    {id: 2, part: "Engine Oil Filter (ACME)", qtyStock: "1258", date: "25 Jun, 24", supplier: "Acme Auto Parts", stockLevel : "In Stock"},
    {id: 3, part: "Engine Oil Filter (ACME)", qtyStock: "1258", date: "25 Jun, 24", supplier: "Acme Auto Parts", stockLevel : "Out of Stock"},
    {id: 4, part: "Engine Oil Filter (ACME)", qtyStock: "1258", date: "25 Jun, 24", supplier: "Acme Auto Parts", stockLevel : "Low Stock"},
    {id: 5, part: "Engine Oil Filter (ACME)", qtyStock: "1258", date: "25 Jun, 24", supplier: "Acme Auto Parts", stockLevel : "Low Stock"},
   
]
 // State for dropdown visibility
const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
const [isStockLevelDropdownOpen, setIsStockLevelDropdownOpen] = useState(false);
const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const stockDropdownRef = useRef(null);
  const stockButtonRef = useRef(null);

  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Status dropdown
  useClickOutside([stockDropdownRef, stockButtonRef], () =>
    setIsStockLevelDropdownOpen(false));

// Dropdown actions
const dateActions = [
{ label: "<Custom Range>", onClick: () => console.log("<Custom Range>") },
{ label: "Today", onClick: () => console.log("Filter: Today") },
{ label: "Yesterday", onClick: () => console.log("Filter: Yesterday") },
{ label: "Last 7 days", onClick: () => console.log("Filter: Last 7 days") },
{ label: "Last 30 days", onClick: () => console.log("Filter: Last 30 days") },
{ label: "This Week", onClick: () => console.log("Filter: This Week") },
{ label: "This Month", onClick: () => console.log("Filter: This Month") },
{ label: "Last Month", onClick: () => console.log("Filter: Last Month") },
];

const stockLevelActions = [
{ label: "Out of stock", onClick: () => console.log("Filter: Out of stock") },
{ label: "Low Stock", onClick: () => console.log("Filter: Low Stock") },
{ label: "In Stock", onClick: () => console.log("Filter: In Stock") },
];

const headers = [
"S/N",
"Part",
"Quantity in Stock",
"Last Reordered Date",
"Supplier",
"Stock Level",
];

const renderRow = (item, index, rowClass) => (
<tr key={item.id} className={rowClass}>
  <td
    className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
    style={{
      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
    }}
  >
    {item.id}
  </td>
  <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
    {item.part}
  </td>
  <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
    {item.qtyStock}
  </td>
  <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
    {item.date}
  </td>
  <td className="lg:py-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
    <div className="flex justify-center">
    {item.supplier}
    </div>
  </td>
  <td className="py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
                    <div className="flex justify-center">
                      <div
                        className={`w-auto h-auto text-nowrap lg:h-[21px] rounded-[16px] py-[2px] px-[8px] text-right flex justify-center items-center font-[400] text-[12px] leading-[17px]
      ${
        item.stockLevel === "In Stock"
          ? "text-[#185BC3] bg-[#E4EDFC]" // Styles for 'completed'
          : item.stockLevel === "Low Stock"
          ? "text-[#FF9500] bg-progressBg"
          : item.stockLevel === "Out of Stock"
          ? "text-[#EC5252] bg-canceledBg" // Styles for 'inprogress'
          : "text-gray-500 bg-gray-200" // Default styles for any other status
      }`}
                      >
                        {item.stockLevel}
                      </div>
                    </div>
                  </td>
</tr>
);
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Analytics - Inventory</title>
                <meta name="description" content="Hamzaa Analytics Inventory page" />
              </Helmet>
    <div className=''>
    <div className='my-[28px] flex justify-between items-center'>
          <div className="flex items-center gap-4">
          <h2 className='font-[400] text-[20px] leading-[32px] text-[#023979]'>Filters:</h2>
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date Range
              </span>
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <AnalyticsDropdown  actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>

          <div className="relative">
            <button ref={stockButtonRef}
              onClick={() => setIsStockLevelDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[14px] leading-[21px] text-center text-[#1C1C1E]">
                Stock Level
              </span>
              {isStockLevelDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" /> : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isStockLevelDropdownOpen && (
              <div ref={stockDropdownRef}>
              <AnalyticsDropdown  actions={stockLevelActions}
                onClose={() => setIsStockLevelDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>
         
        </div>
       <ExportDocumentButton/>
  
        </div>
        <div>
          <MultipleChart/>
        </div>
        <div className='mt-8 mb-6'>
        <h2 className='font-[600] text-[16px] leading-[21px] text-[#26262A]'>Inventory List</h2>
        </div>
        <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={inventory}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
             
      </div>

    </div>
    </HelmetProvider>
  )
}

export default Inventory