import React, {useState} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import InputField from '../components/inputField';
import AuthHeader from '../components/authHeader';

const VerifyOTP = () => {
    const [otp, setOtp] = useState(new Array(4).fill(''));

    const handleChange = (e, index) => {
      const { value } = e.target;
  
      // Only allow numbers, and update state if a single digit is entered
      if (/^[0-9]?$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        // Move to the next input if the current one is filled
        if (value && index < otp.length - 1) {
          e.target.nextSibling.focus();
        }
      }
    };
  
    const handleBackspace = (e, index) => {
      if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
        e.target.previousSibling.focus();
      }
    };
  return (
    <HelmetProvider>
    <Helmet>
						<title>
						 Hamzaa OTP
						</title>
						<meta
							name='description'
							content= "Hamzaa OTP page"
						/>
					</Helmet>
                    <div className="flex w-full min-h-[100vh] font-dmsans">
      {/* Background image container */}
      <div className="fixed top-0 left-0 md:w-[30vw] h-[100vh] bg-signup-image bg-cover bg-no-repeat hidden md:flex justify-end">
        {/* Content box aligned at the bottom */}
        <div className="hidden absolute bottom-5 left-5  w-[25vw] min-h-[35vh]  border-[2px] bg-signup-gradient backdrop-blur-[40px] border-[#F5F5F533]  rounded-[20px] md:flex justify-center items-center p-[20px] lg:p-[45px] text-white font-[400] text-[16px] leading-[24px] 2xl:text-[20px] 2xl:leading-[28px]">
        "I really like that I can place orders remotely with my trusted supplier without needing to visit in person—everything from delivery to payments is seamlessly handled."
        </div>
      </div>
      <div className="md:absolute w-full mt-[50px] md:mt-0 md:ml-[30%] md:w-[70%] flex flex-col px-6 md:p-[80px] overflow-hidden">
       <AuthHeader/>
        <div className="flex flex-col gap-[6px] mt-[44px] mb-[50px] md:mt-[60px] md:mb-[100px]">
          <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:leading-[52px] tracking-[0.3px] text-[#1C1C1C]">
          Verify Email
          </h2>
          <p className="font-[400] text-[12px] leading-[18px] md:text-[16px] md:leading-[28px] tracking-[0.09px] text-lightGreyBg">
          An Authentication code has been sent to <span className='text-[#FD6B22]'>user@hamzaa.ng</span> 
          </p>
        </div>
        <form className='w-full md:w-[80%] flex flex-col justify-center items-center px-6 md:px-0'>
        <div className='flex justify-center items-center gap-[13px]'>
        {otp.map((data, index) => (
          <InputField  
          key={index}
          type="text"
          maxLength="1"
          value={data}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          inputClassName='w-12 h-[62px] rounded-[8px] text-center rounded-[8px]  font-[700] text-[36px] leading-[46px] text-[#1B1D21] focus:outline-none focus:ring-2 '
           />
      ))}
      </div>
      <div className='mt-[48px]'>
      <p className='font-[300] text-[12px] leading-[24px] tracking-[0.3px] text-lightGreyBg'>Code Sent. Resend Code in <span className='text-[#FD6B22]'>00:50</span></p>
      </div>
      <div className="flex justify-center items-center w-full mt-[48px] mb-[20px]">
            <button className="md:mt-[28px] w-full h-[52px] md:h-[60px] border-[2px] rounded-[8px] py-[18px] px-[18px] flex justify-center items-center bg-[#007BD1] border-[#0A0A0A] font-[700] text-[16px] leading-[24px] text-center text-white">
              Login
            </button>
          </div>
        </form>

      </div>
    </div>
    </HelmetProvider>
  )
}

export default VerifyOTP