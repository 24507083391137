import React, { useState, useRef } from "react";
import AnalyticsDropdown from "../analyticsDropdown";
import calendar from "../../assets/images/solarcalendar.svg";
import Table from "../Table";
import { GoGraph } from "react-icons/go";
import ExportDocumentButton from "../exportDocumentButton";
import {Component as PieChartOne} from "../charts/CustomersPieChart"
import {Component as PieChartTwo} from "../charts/CustomerSatisfactionChart"
import {Component as RepairTimeChart} from "../../components/charts/RepairTimeChart"
import {Component as CustomerRetentionChart} from "../charts/CustomerRetentionChart";
import { TradeDownIcon, TradeUpIcon } from "hugeicons-react";
import useClickOutside from "../../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";

const ShopPerformance = () => {
  const employee = [
    {
      id: 1,
      name: "Employee Name One",
      orders: "258",
      time: "04h 56m",
      performance: 8,
    },
    {
      id: 2,
      name: "Employee Name Two",
      orders: "8",
      time: "04h 56m",
      performance: 3,
    },
    {
      id: 3,
      name: "Employee Name Three",
      orders: "58",
      time: "04h 56m",
      performance: 8,
    },
    {
      id: 4,
      name: "Employee Name Four",
      orders: "258",
      time: "04h 56m",
      performance: 10,
    },
    {
      id: 5,
      name: "Employee Name Five",
      orders: "25",
      time: "04h 56m",
      performance: 3,
    },
  ];
  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  const dateDropdownRef = useRef(null);
    const dateButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );


  // Dropdown actions
  const dateActions = [
    { label: "<Custom Range>", onClick: () => console.log("<Custom Range>") },
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "Yesterday", onClick: () => console.log("Filter: Yesterday") },
    { label: "Last 7 days", onClick: () => console.log("Filter: Last 7 days") },
    {
      label: "Last 30 days",
      onClick: () => console.log("Filter: Last 30 days"),
    },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
    { label: "Last Month", onClick: () => console.log("Filter: Last Month") },
  ];

  
  const headers = [
    "S/N",
    "Employee Name",
    "Orders Processed",
    "Average Completion Time",
    "Performance",
  ];

  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.name}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.orders}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.time}
      </td>
      <td className="lg:py-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
        <div className="flex gap-2 justify-center">{item.performance}{item.performance < 5 ? <TradeDownIcon className="text-[#FF0000]"/> : <TradeUpIcon className="text-[#00A672]"/>}</div>
      </td>
    </tr>
  );
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Analytics - Shop Performance</title>
                <meta name="description" content="Hamzaa Analytics Shop Performance page" />
              </Helmet>
    <div className="">
      <div className="my-[28px] flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="font-[400] text-[20px] leading-[32px] text-[#023979]">
            Filter:
          </h2>
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date Range
              </span>
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <AnalyticsDropdown  actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>
        </div>
 <ExportDocumentButton/>
      </div>
     
      <div className="flex flex-col gap-4 xl:flex-row xl:justify-between">
        <div className="flex flex-col gap-1">
          <h3 className="font-[600] text-[20px] leading-[26px] text-[#8E8E93]">Revenue</h3>
          <div className="flex gap-[29px]">
            <h2 className="text-primary font-[700] text-[24px] leading-[31px]">$678,409.09</h2>
            <div className="flex gap-4">
              <div className="w-auto h-[33px] border border-[#00A672] rounded-[16px] py-[6px] px-[10px] bg-lightGreen flex items-center gap-[10px] text-[#00A672]"><GoGraph />
              <span>27%</span></div>
              <div className="w-auto h-[33px] border border-[#00A672] rounded-[16px] py-[6px] px-[10px] bg-lightGreen font-[700] text-[16px] leading-[21px] text-[#00A672]">$183,170.43</div>
            
            </div>
          </div>
          <div className="flex gap-4 pt-[7px]">
            <p className="font-[600] text-[16px] leading-[21px] text-[#4F5459]">prev: 495,238.66</p>
           <p className="font-[400] text-[16px] leading-[21px] text-[#4F5459]">September - October, 2024.</p>
          </div>
        </div>
        <div className="flex gap-[19px] items-center">
          
          <div className="flex flex-col gap-[2px] border py-[6px] px-[14px] border-[#48494D] bg-white w-[20vw] xl:w-[15vw] h-auto xl:h-[74px] rounded-[8px]">
          <h4 className="font-[600] text-[16px] leading-[21px] text-[#8E8E93] ">Parts sale expense</h4>
            <h3 className="text-primary font-[600] text-[16px] leading-[21px]">$178,409.<span className="text-[12px] leading-[16px]">09</span></h3>
            <p className="text-[#00A672] text-[12px] leading-[16px] font-[300]">30% of Total revenue</p>
          </div>
          <div className="flex flex-col gap-[2px] border py-[6px] px-[14px] border-[#48494D] bg-primary w-[20vw] xl:w-[15vw] h-auto xl:h-[74px] rounded-[8px]">
          <h4 className="font-[600] text-[16px] leading-[21px] text-[#F2F2F7CC] ">Job Revenue</h4>
            <h3 className="text-[#F2F2F7] font-[600] text-[16px] leading-[21px]">$500,000.<span className="text-[12px] leading-[16px]">00</span></h3>
            <p className="text-[#00FFAF] text-[12px] leading-[16px] font-[300]">70% of Total revenue</p>
          </div>
         
        </div>
      </div>
      <div className="flex flex-wrap 2xl:justify-normal  justify-center gap-[19px] items-center mt-8">
        <PieChartOne/>
     <PieChartTwo/>
     <CustomerRetentionChart/>
     <RepairTimeChart/>
      </div>
      <div className="mt-8 mb-6">
        <h2 className="font-[600] text-[16px] leading-[21px] text-[#26262A]">
          Employee Productivity
        </h2>
      </div>
      <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={employee}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
      </div>
    </div>
    </HelmetProvider>
  );
};

export default ShopPerformance;
