import React from 'react';

const DownloadDropdown = ({ actions, onClose, position, width}) => {
     
  return (
    <div className={`absolute ${position} bg-white shadow-lg min-w-[250px] z-30 border border-gray-200 rounded-lg flex flex-col w-full items-center`}  onClick={(e) => e.stopPropagation()}>
    {actions.length > 0 && actions?.map((action, index) => (
        <button
        type='button'
            key={index}
            className={`${width} bg-white p-3  text-[500] text-primary text-[16px] hover:bg-[#FAFAFA] leading-[18px] -tracking-[3%] text-left text-nowrap`}
            onClick={() => {
                action.onClick();
                onClose();
            }}>
            {action.label}
        </button>
    ))}
</div>
  )
}

export default DownloadDropdown