const carOptions = [
    {
      label: "Rolls-Royce",
      value: "rolls-royce",
      subActions: [
        { label: "Phantom (Luxury Sedan)",  },
        { label: "Ghost (Executive Sedan)",    },
        { label: "Wraith (Luxury Coupe)",}  ,
        { label: "Cullinan (Luxury SUV)",    },
        { label: "Spectre (Electric Coupe)",  },
      ],
    },
    {
      label: "BMW",
      value: "bmw",
      subActions: [
        { label: "3 Series (Sedan, Wagon)",   },
        { label: "5 Series (Sedan)",   },
        { label: "7 Series (Luxury Sedan)",  },
        { label: "X5 (SUV)",    },
        { label: "X7 (Luxury SUV)",   },
        { label: "iX (Electric SUV)",  },
        {label: "Z4 (Roadster)"}
      ],
    },
    {
      label: "Mercedes-Benz",
      value: "Mercedes-Benz",
      subActions: [
        {label: "C-Class (Sedan, Coupe, Convertible)",},
        {label: "E-Class (Sedan, Coupe, Wagon)"},
        {label: "S-Class (Luxury Sedan)"},
        {label: "GLC (Compact SUV)"},
        {label: "GLE (Midsize SUV)"},
        {label: "EQE/EQS (Electric Sedans/SUVs)"},
      ]
    },
    {
      label: " Audi",
      value: " Audi",
      subActions: [
        {label: "A4 (Sedan)",},
        {label: "A6 (Sedan)"},
        {label: "A8 (Luxury Sedan)"},
        {label: "Q5 (SUV)"},
        {label: "Q7 (Luxury SUV)"},
        {label: "e-tron (Electric SUV)"},
        {label: "R8 (Supercar)"},
      ]
    },
    {
      label: "Tesla",
      value: "esla",
      subActions: [
        {label: "Model 3 (Electric Sedan)",},
        {label: "Model Y (Electric SUV)"},
        {label: "Model S (Luxury Electric Sedan)"},
        {label: "Model X (Electric SUV)"},
       
      ]
    },
    {
      label: "Toyota",
      value: "Toyota",
      subActions: [
        {label: "Corolla (Sedan, Hatchback)",},
        {label: "Camry (Sedan)"},
        {label: "RAV4 (SUV)"},
        {label: "Highlander (SUV)"},
        {label: "Tacoma (Pickup Truck)"},
        {label: "Prius (Hybrid)"},
      ]
    },
    {
      label: "Ford",
      value: "Ford",
      subActions: [
        {label: "F-150 (Pickup Truck)",},
        {label: "Mustang (Sports Car)"},
        {label: "Explorer (SUV)"},
        {label: "Escape (SUV"},
        {label: "Maverick (Compact Truck)"},
        {label: "Mach-E (Electric SUV)"},
      ]
    },
    {
      label: "Chevrolet",
      value: "Chevrolet",
      subActions: [
        {label: "Silverado (Pickup Truck)",},
        {label: "Equinox (SUV)"},
        {label: "Malibu (Sedan)"},
        {label: "Traverse (SUV)"},
        {label: "Bolt EV/EUV (Electric Vehicle)"},
        {label: "Corvette (Sports Car)"},
      ]
    },
    {
      label: " Honda",
      value: " Honda",
      subActions: [
        {label: "Civic (Sedan, Hatchback)",},
        {label: "Accord (Sedan)"},
        {label: "CR-V (SUV)"},
        {label: "Pilot (SUV)"},
        {label: "Ridgeline (Truck)"},
      ]
    },
    {
      label: "Hyundai",
      value: "Hyundai",
      subActions: [
        {label: "Elantra (Sedan)",},
        {label: "Sonata (Sedan)"},
        {label: "Santa Fe (SUV)"},
        {label: "Kona (Compact SUV)"},
        {label: "IONIQ 5 (Electric Vehicle)"},
      ]
    },

  ];
  export default carOptions