import React from 'react'
import napa from '../assets/images/napa.png'
import amazon from '../assets/images/amazon.png'

const CompanyLogo = () => {
  return (
    <>
        <div className="w-full h-[111px] md:h-[169px] bg-white shadow-sm rounded-[4px] py-[8px] px-[30px]  flex justify-between md:justify-center items-center gap-[54px] border border-[#F1F1F1]">
    <img src={napa} alt="NAPA Logo" className="md:w-[50px] md:h-[50px] w-[80px] h-[73px]"/>
    <img src={amazon} alt="Amazon logo" className="md:w-[50px] md:h-[27px] w-[119px] h-[60px]"/>
  </div>
    </>
  )
}

export default CompanyLogo