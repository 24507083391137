import React, {useState} from 'react'
import { FaPlus } from "react-icons/fa6";
import Switch from "../switch";
import QuickBooks from "../../assets/images/quickbooks.png"
import Napa from "../../assets/images/napa.png"
import NexPart from "../../assets/images/nexpart.png"
import Oreilly from "../../assets/images/oreilly.png"
import CarFax from "../../assets/images/carfax.png"
import AutoCheck from "../../assets/images/autocheck.png"
import { HelmetProvider, Helmet } from 'react-helmet-async';

const IntegrationHeading = ({CardHeading, icon}) => {
  return (
    <div className="flex justify-between items-center w-full pb-6">
    <div className="flex flex-col gap-[2px]">
      <h2 className="font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px] text-[#BCBCBC]">
      {CardHeading}
      </h2>
    </div>
    {icon}
   
  </div>
  )
}

const IntegrationBox = ({ heading, paragraph, isOn, toggleSwitch, image, isLast}) => {
  return (
    <div className='w-full pb-6'>
    
    <div className='flex flex-col gap-6'>
    <div className="flex gap-[40px] md:gap-0 justify-between items-center w-full">
    <div className='flex items-center gap-4 md:gap-6'>
    <img src={image} alt='Company logo' className='w-[40px] md:w-[56px] h-auto'/>
      <div className="flex flex-col gap-[2px] mt-2">
        <h2 className="font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px] text-[#1C1C1C]">
          {heading}
        </h2>
        <p className="font-[400] text-[14px] leading-[18px] md:text-[16px] md:leading-[21px] text-lightGreyBg">
          {paragraph}
        </p>
      </div>
      </div>
      <Switch toggleSwitch={toggleSwitch} isOn={isOn} />
    </div>
    {!isLast && <div className="w-full border-b border-[#D9D9D9]"></div>}
    </div>
    </div>
  );
};

const Integrations = () => {
  const account = [
    {
        heading: "Accounting Software Integration",
        content: {
          id: "quickbooks",
            image: QuickBooks,
            heading: "QuickBooks",
            paragraph: "Track expenses, customise invoices, run reports and even more all from one place."
        }
    }
];
  const suppliers = [
    {
        heading: "Parts Suppliers Integration:",
        content: [
          {
            id:"napa",
            image: Napa,
            heading: "NAPA",
            paragraph: "Trusted source for automotive parts, accessories. Shop online for original OEM & replacement parts."
        },
          {
            id:"nexpart",
            image: NexPart,
            heading: "NexPart",
            paragraph: "Save time and increase productivity Buy from all your part sellers in one place"
        },
          {
            id:"oreilly",
            image: Oreilly,
            heading: "O’Reilly Auto parts",
            paragraph: "Find auto parts, tools, FREE Next Day shipping or pick up your order at one of more than 6000 stores."
        },
      ]
    }
];
  const vinlookup = [
    {
        heading: "VIN Lookup Integration",
        content: [
          {
            id:"carfax",
            image: CarFax,
            heading: "CarFax",
            paragraph: "Learn more about the vehicle's history and avoid costly hidden problems"
        },
          {
            id:"autocheck",
            image: AutoCheck,
            heading: "AutoCheck",
            paragraph: "Making the journey of car ownership easier for everyone"
        },
      ]
    }
];

  const [state, setState] = useState({
    quickbooks: false,
  nexpart: false,
  napa: false,
  oreilly: false,
  carfax: false,
  autocheck: false,
  });
  const toggleSwitch = (key) => {
    setState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Settings - Integrations</title>
                <meta name="description" content="Hamzaa Settings Integrations page" />
              </Helmet>
    <div className="w-full h-auto rounded-[8px] mt-8 mb-4 flex flex-col  bg-white p-4 md:p-8 shadow-md">
    <div>
   {account.map((item, index) => (
    <IntegrationHeading
        key={index}
        CardHeading={item.heading}
        icon={ <button className="flex justify-center items-center gap-[2px]">
          <FaPlus className="text-[#007AFF] w-4 h-4" />
            <span className="hidden md:block text-center font-[500] text-[14px] leading-[18px] text-[#007AFF] text-nowrap">
            Add new Integration
            </span>
          </button>} 
    />
))}
   {account.map((item, index) => (
    <IntegrationBox
        key={index}
        heading={item.content.heading}
        paragraph={item.content.paragraph}
        image={item.content.image}
        CardHeading={item.heading}
        icon={ <button className="flex justify-center items-center gap-[2px]">
          <FaPlus className="text-[#007AFF] w-4 h-4" />
            <span className="text-center font-[500] text-[14px] leading-[18px] text-[#007AFF] text-nowrap">
            Add new Integration
            </span>
          </button>} 
      isOn={state[item.content.id]}
      toggleSwitch={() => toggleSwitch(item.content.id)}
    />
))}
</div>
<div>
{suppliers.map((supplier, supplierIndex) => (
  <div key={supplierIndex} className="w-full">
      <IntegrationHeading
        CardHeading={supplier.heading}
      />
  
  </div>
))}
{suppliers.map((supplier, supplierIndex) => (
  <div key={supplierIndex} className="w-full pb-6">
    {supplier.content.map((contentItem, contentIndex) => (
      <IntegrationBox
        key={contentIndex}
        heading={contentItem.heading}
        paragraph={contentItem.paragraph}
        image={contentItem.image}
        isOn={state[contentItem.id]}
        toggleSwitch={() => toggleSwitch(contentItem.id)}
      />
    ))}
  </div>
))}
</div>
<div>
{vinlookup.map((vinlookup, vinlookupIndex) => (
  <div key={vinlookupIndex} className="w-full">
      <IntegrationHeading
        CardHeading={vinlookup.heading}
      />
  
  </div>
))}
{vinlookup.map((vinlookup, vinlookupIndex) => (
  <div key={vinlookupIndex} className="w-full">
    {vinlookup.content.map((contentItem, contentIndex) => (
      <IntegrationBox
        key={contentIndex}
        heading={contentItem.heading}
        paragraph={contentItem.paragraph}
        image={contentItem.image}
        isOn={state[contentItem.id]}
        toggleSwitch={() => toggleSwitch(contentItem.id)}
        isLast={contentIndex === vinlookup.content.length - 1}
      />
    ))}
  </div>
))}
</div>
    </div>
    </HelmetProvider>
  )
}

export default Integrations