import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import PricingBox from "./pricingBox";
import InputField from "./inputField";
import { FaPlus } from "react-icons/fa6";
import Visa from "../assets/images/visa.png";
import Master from "../assets/images/master-card.png";
import Button from "./button";
import ReactFlagsSelect from "react-flags-select";

const CardBox = ({ image, name, number, selected, onSelect }) => {
  return (
    <div
      className={`flex justify-between items-center px-5 h-[58px] py-4 border ${
        selected ? "border-primary" : "border-[#E4E8F1]"
      } rounded-[8px] w-full`}
      onClick={onSelect}
    >
      <div className="flex items-center gap-[12px] w-full">
        <input
          type="radio"
          checked={selected}
          readOnly
          className="form-radio h-[15px] w-[15px] accent-[#1A2233] focus:ring-0  border-[#E4E8F1]"
        />
        <div className="flex gap-2 items-center w-full">
          <div className="flex flex-col gap-[2px]">
            <h2 className="font-[700] text-[16px] leading-[21px] text-[#1C1C1C]">
              {name}
            </h2>
            <p className="font-[300] text-[16px] leading-[21px] text-cardNumberColor">
              {number}
            </p>
          </div>
        </div>
      </div>
      <img src={image} alt="" className="h-auto w-[38px]" />
    </div>
  );
};
const UpgradePlan = ({ closeModal }) => {
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedCardId, setSelectedCardId] = useState(1);
  const card = [
    { id: 1, image: Visa, name: "Jane Daniel", number: "***** 7856" },
    { id: 2, image: Master, name: "Jane Daniel", number: "***** 7856" },
  ];
  const pricings = [
    {
      price: 99,
      month: "",
      plan: "Basic",
      description: "Perfect plan for starters",
      button: "Choose Plan",
      perks: [
        "Scheduling",
        "Invoicing",
        "Basic reporting",
        "Industry News",
        "Customer retention",
        "VIN lookup",
      ],
    },
    // {
    //   price: 199,
    //   month: "",
    //   plan: "Standard",
    //   description: "For smaller shops that wants to streamline workflows",
    //   button: "Choose Plan",
    //   popular: "MOST POPULAR",
    //   perks: [
    //     "Scheduling", "Invoicing", "Advanced reporting", "Industry News", "Inventory management", "Customer retention", "Customer Relationship Management (CRM)" , "Car parts ordering", "VIN lookup"

    //   ],
    // },
    // {
    //   price: 299,
    //   month: "",
    //   plan: "Unlimited",
    //   description: "For larger shops needing advanced to do more",
    //   button: "Choose Plan",
    //   perks: [
    //     "Scheduling", "Invoicing", "Advanced reporting", "Industry News", "Customer Relationship Management (CRM)", "Purchase car part ordering", "Lead generation and dedicated support", "VIN lookup", "Free advertising"
    //   ],
    // },
  ];
  const handleCardModal = () => {
    setCardModalOpen(true);
  };
  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans">
      <div
        className="bg-white shadow-lg px-6 lg:px-[44px] w-full md:w-[70vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col pb-[50px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center bg-white  sticky top-0 pt-[44px] pb-4 z-30">
          <h1 className="font-[500] text-[20px] lg:text-[32px] leading-[44px] -tracking-[3%] text-[#1C1C1C]">
            Upgrade Plan
          </h1>
          <button
            className="absolute right-4  shadow-md lg:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
            onClick={closeModal}
          >
            <IoIosClose className="w-full h-full" />
          </button>
        </div>
        <div className="flex justify-center items-center pt-[64px] lg:pt-[430px] 2xl:pt-[400px] 3xl:pt-[100px] md:gap-4 md:px-4 2xl:px-[50px] xl:gap-[60px] overflow-y-auto">
          <div className="lg:block hidden">
            {pricings.map((pricing, index) => (
              <PricingBox
                key={index}
                price={pricing.price}
                month={pricing.month}
                plan={pricing.plan}
                description={pricing.description}
                button={pricing.button}
                perks={pricing.perks}
                isPopular={pricing.popular || null}
              />
            ))}
          </div>
          {!cardModalOpen &&
            pricings.map((pricing, index) => (
              <div className="lg:hidden flex flex-col gap-[64px]  w-full">
                <PricingBox
                  key={index}
                  price={pricing.price}
                  month={pricing.month}
                  plan={pricing.plan}
                  description={pricing.description}
                  button={pricing.button}
                  perks={pricing.perks}
                  isPopular={pricing.popular || null}
                />
                <Button
                  type="primary"
                  borderRadius="rounded-[8px]"
                  size="M"
                  label="Continue"
                  className="lg:hidden block w-full"
                  onClick={handleCardModal}
                />
              </div>
            ))}

          <div className="hidden lg:block lg:w-[20vw]  h-full">
            <div className="flex flex-col gap-4">
              <h1 className="font-[500] text-[18px] leading-[23px] text-[#BCBCBC]">
                Summary
              </h1>
              <div className="flex justify-between items-center">
                <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                  Monthly
                </h2>
                <p className="text-customerNameColor font-[400] text-[16px] leading-[18px]">
                  $50.00
                </p>
              </div>
              <div className="flex justify-between items-center">
                <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                  Estimated tax
                </h2>
                <p className="text-customerNameColor font-[400] text-[16px] leading-[18px">
                  $50.00
                </p>
              </div>
              <div className="w-full border border-[#BCBCBC]"></div>
              <div className="flex justify-between items-center">
                <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                  Total
                </h2>
                <p className="text-customerNameColor font-[400] text-[16px] leading-[18px">
                  $50.00
                </p>
              </div>
              <div className="w-full border border-[#BCBCBC] mt-[12px] mb-[28px]"></div>
            </div>
            <div className="flex flex-col gap-4">
              <h1 className="font-[500] text-[18px] leading-[23px] text-[#BCBCBC]">
                Billing Address
              </h1>
              <div className="flex flex-col gap-1">
                <label className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block">
                  Country
                </label>
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  searchable
                  selectButtonClassName="custom-select-button"
                />
              </div>
              <InputField
                label="Postal Code"
                name="postalCode"
                type="text"
                placeholder="e.g. 100001"
                className="w-full flex flex-col gap-1"
                inputClassName="w-full h-[60px]  md:h-[56px] rounded-[10px]"
              />
            </div>
            <div className="flex flex-col gap-4 mt-[28px]">
              <div className="flex justify-between items-center w-full">
                <h2 className="font-[600] text-[16px] leading-[21px] text-[#BCBCBC]">
                  Payment method
                </h2>

                <button className="flex justify-center items-center gap-[2px]">
                  <FaPlus className="text-[#007AFF] w-4 h-4" />
                  <span className="text-center font-[500] text-[14px] leading-[18px] text-[#007AFF] text-nowrap">
                    Add new card
                  </span>
                </button>
              </div>
              {card.map((card, index) => (
                <CardBox
                  key={index}
                  image={card.image}
                  name={card.name}
                  number={card.number}
                  selected={selectedCardId === card.id}
                  onSelect={() => setSelectedCardId(card.id)}
                />
              ))}
              <div className="flex justify-center items-baseline my-[28px]">
                <Button
                  type="tertiary"
                  borderRadius="rounded-[8px]"
                  size="M"
                  label="Upgrade Plan"
                  className="lg:w-[245px] w-full"
                />
              </div>
            </div>
          </div>
          {cardModalOpen && (
            <div className="lg:hidden mt-[64px] block w-full  h-full">
              <div className="flex flex-col gap-4">
                <h1 className="font-[500] text-[18px] leading-[23px] text-[#BCBCBC]">
                  Summary
                </h1>
                <div className="flex justify-between items-center">
                  <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                    Monthly
                  </h2>
                  <p className="text-customerNameColor font-[400] text-[16px] leading-[18px]">
                    $50.00
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                    Estimated tax
                  </h2>
                  <p className="text-customerNameColor font-[400] text-[16px] leading-[18px">
                    $50.00
                  </p>
                </div>
                <div className="w-full border border-[#BCBCBC]"></div>
                <div className="flex justify-between items-center">
                  <h2 className="text-[#1C1C1C] font-[500] text-[16px] leading-[18px]">
                    Total
                  </h2>
                  <p className="text-customerNameColor font-[400] text-[16px] leading-[18px">
                    $50.00
                  </p>
                </div>
                <div className="w-full border border-[#BCBCBC] mt-[12px] mb-[28px]"></div>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="font-[500] text-[18px] leading-[23px] text-[#BCBCBC]">
                  Billing Address
                </h1>
                <div className="flex flex-col gap-1">
                  <label className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block">
                    Country
                  </label>
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    searchable
                    selectButtonClassName="custom-select-button"
                  />
                </div>
                <InputField
                  label="Postal Code"
                  name="postalCode"
                  type="text"
                  placeholder="e.g. 100001"
                  className="w-full flex flex-col gap-1"
                  inputClassName="w-full h-[60px]  md:h-[56px] rounded-[10px]"
                />
              </div>
              <div className="flex flex-col gap-4 mt-[28px]">
                <div className="flex justify-between items-center w-full">
                  <h2 className="font-[600] text-[16px] leading-[21px] text-[#BCBCBC]">
                    Payment method
                  </h2>

                  <button className="flex justify-center items-center gap-[2px]">
                    <FaPlus className="text-[#007AFF] w-4 h-4" />
                    <span className="text-center font-[500] text-[14px] leading-[18px] text-[#007AFF] text-nowrap">
                      Add new card
                    </span>
                  </button>
                </div>
                {card.map((card, index) => (
                  <CardBox
                    key={index}
                    image={card.image}
                    name={card.name}
                    number={card.number}
                    selected={selectedCardId === card.id}
                    onSelect={() => setSelectedCardId(card.id)}
                  />
                ))}
                <div className="flex justify-center items-baseline my-[28px]">
                  <Button
                    type="tertiary"
                    borderRadius="rounded-[8px]"
                    size="M"
                    label="Upgrade Plan"
                    className="lg:w-[245px] w-full"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
