import React from "react";
import { Outlet } from "react-router-dom";

const PartOrderingPage = () => {
  
  return (
    <div className="lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF] ">
     <Outlet />
    </div>
  );
};

export default PartOrderingPage;
