import React, { useState } from "react";
import useCameraScanner from "../utils/customhooks/CameraScanner";
import Webcam from "react-webcam";
import Button from "../components/button";
import { Camera01Icon } from "hugeicons-react";
import { HelmetProvider, Helmet } from "react-helmet-async";

const VINLookup = ({ openVinModal }) => {
  const [vinNumber, setVinNumber] = useState("");
  const {
    isCameraOpen,
    toggleCamera,
    webcamRef,
    captureImage,
    isProcessing,
    scannedText,
  } = useCameraScanner();

  // Update the VIN number when scanning is complete
  React.useEffect(() => {
    if (scannedText) {
      setVinNumber(scannedText);
    }
  }, [scannedText]);

  return (
    <>
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | VIN Lookup</title>
                <meta name="description" content="Hamzaa VIN Lookup page" />
              </Helmet>
    <div className="lg:absolute lg:top-[90px] w-full lg:w-[calc(100%-319px)] pt-4 lg:pt-[80px] px-6 z-10 lg:px-[48px] pb-[40px] min-h-[100vh] font-dmsans bg-white lg:bg-[#F8FAFF]">
      <form className="flex flex-col gap-[40px] lg:mt-0 mt-[100px]">
        <h1 className="font-[700] text-[16px] leading-[21px] lg:text-[20px] lg:leading-[32px] text-primary text-center lg:text-left">
          VIN Number
        </h1>

        <div className="flex flex-col gap-[7px]">
          <label className="font-[400] text-[15px] leading-[19px] text-black">
            Input the VIN Number
          </label>
          <div className="relative w-full lg:w-[808px] flex items-center">
            <input
              type="text"
              value={vinNumber}
              onChange={(e) => setVinNumber(e.target.value)}
              placeholder="4Y1SL65848Z411439"
              className="w-full lg:w-[808px] h-[58px] lg:h-[69px] rounded-[13px] bg-white py-4 px-[14px] border border-[#E7E7E7] outline-[#00325E]"
            />
            <span
              className="absolute right-3 cursor-pointer"
              onClick={toggleCamera}
            >
              <Camera01Icon className="w-[24px] h-[24px]" />
            </span>
          </div>
        </div>

        <Button
          type="tertiary"
          borderRadius="rounded-[8px]"
          size="S"
          label="Search"
          className="lg:w-[227px] w-full"
          onClick={(e) => {
            e.stopPropagation();
            openVinModal();
          }}
        />
      </form>
    </div>
    {isCameraOpen && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <div className="bg-white rounded-[8px] w-full lg:w-[50vw] max-h-[90vh] p-3">
            <div className="flex flex-col items-center gap-4">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full h-[300px] rounded-[13px] border border-[#E7E7E7]"
              />
              <Button
                type="primary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Capture Photo"
                onClick={captureImage}
              />
              <Button
                type="secondary"
                borderRadius="rounded-[8px]"
                size="M"
                label="Cancel"
                onClick={toggleCamera}
              />
            </div>
          </div>
        </div>
      )}

      {isProcessing && (
        <div className="fixed inset-0 z-40 flex justify-center items-center  bg-black bg-opacity-40 font-dmsans">
          <p className="text-gray-500">Processing image...</p>
        </div>
      )} 
       </HelmetProvider>
    </>
  
  );
};

export default VINLookup;
