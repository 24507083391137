import { useEffect } from "react";

/**
 * Custom hook to detect clicks outside of the specified refs
 * @param {React.RefObject} refs - Array of refs to monitor
 * @param {Function} onClickOutside - Callback to trigger when a click is outside
 */
const useClickOutside = (refs, onClickOutside) => {
  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target)
      );
      if (isOutside) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [refs, onClickOutside]);
};

export default useClickOutside;
