import React from 'react'

const OverviewBox = ({value, update, title}) => {
  const updateColor = update?.startsWith("-")
  ? "text-[#C21616]" // Red for negative updates
  : "text-[#8CDAAA]";
  
  return (
    <div className='w-full h-[106px] md:w-[244px] md:h-[155px] flex flex-col items-start justify-center gap-2 flex-1 rounded-[4px] bg-[#F8FAFF] p-3 md:p-4 mx-2 md:mx-0'>
    <div className='flex flex-col gap-1'>
    <h2 className='text-[#161414] font-montserrat md:text-[32px] text-[16px] leading-[20px] md:leading-[39px] font-[600]'>{value}</h2>
    <p className='text-[#656568] text-[14px] md:text-[16px] font-[400] leading-[20px]'>{title}</p>
    </div>
    <span  className={`${updateColor} text-[12px] md:text-[14px] font-[400] leading-[20px]`}>{update}</span>
    </div>
  )
}

export default OverviewBox