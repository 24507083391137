import React from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

const Analytics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subTabs = [
    { name: "Customers", path: "/reports/analytics/customers" },
    { name: "Inventory", path: "/reports/analytics/inventory" },
    { name: "Shop Performance", path: "/reports/analytics/shop-performance" },
    { name: "Finance", path: "/reports/analytics/finance" },
    { name: "Parts Ordering", path: "/reports/analytics/parts-ordering" },
  ];
  const handleSubTabClick = (path) => {
    navigate(path);
  };
  return (
    <div className="hidden lg:block absolute top-[90px] pt-[60px] w-[calc(100%-319px)] z-10 px-[48px] pb-[40px] min-h-[100vh] font-dmsans  bg-[#F8FAFF]">
      <h1 className="font-[700] text-[20px] leading-[32px] text-primary">
        Reports
      </h1>
      <div className="mt-[22px] flex justify-between items-center max-w-full overflow-auto xl:overflow-hidden bg-white h-auto xl:h-[56px] rounded-[10px] border border-[#D9D9D9] p-1">
        {subTabs.map((tab) => (
          <button
            key={tab.path}
            onClick={() => handleSubTabClick(tab.path)}
            className={`font-[500] text-[20px] leading-[32px] py-2 px-6 ${
              location.pathname.startsWith(tab.path) ? "bg-primary w-auto rounded-[8px] h-auto  text-[#FAFDFF] " : "text-black"
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Analytics;
