import React, {useState, useRef} from 'react'
import { IoIosClose } from "react-icons/io";
import Button from './button';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useClickOutside from '../utils/customhooks/useClickOutside';


const AppointmentDetails = ({closeModal, customer, openScheduleModal}) => {
    const buttonRef = useRef(null);
    const [dropDown, setDropDown] = useState(false);
    const dropdownRef = useRef(null);
  
    useClickOutside([dropdownRef, buttonRef], () =>  setDropDown(false));
    
      const handleDropDown = () => {
        setDropDown(!dropDown);
      };
    const handleScheduleAppointmentModalOpen = () =>{
      openScheduleModal();
    closeModal();}
  return (
    <div
    className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-40 font-dmsans"
    onClick={closeModal}
  >
    <div
      className="bg-white shadow-lg lg:px-[44px]  w-full md:w-[50vw] rounded-[10px] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-center lg:justify-between bg-white  lg:pb-[56px] sticky top-0 pt-[44px] z-30">
        <h1 className="font-[500] text-[20px] lg:text-[32px] leading-[44px] -tracking-[3%] text-[#1C1C1C]">
          Appointment Details
        </h1>
        <button
          className="absolute right-4 lg:static shadow-md lg:shadow-none w-[32px] h-[32px] rounded-[12px] bg-[#F9F9F9]  lg:justify-center lg:items-center"
          onClick={closeModal}
        >
          <IoIosClose className="w-full h-full" />
        </button>
      </div>
      <div className="mt-[40px] lg:mt-0 flex flex-col lg:px-0 px-[44px] mb-[40px] lg:flex-row lg:justify-between  rounded-[10px]  lg:min-h-[600px]">
      <div className="lg:hidden flex gap-[14px] items-center mb-[40px]">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Reschedule"
            className="w-auto "
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Send Reminder"
            className="w-auto  h-[48px]"
            
          />
          <button ref={buttonRef} onClick={handleDropDown} className="ml-1">
             <FaEllipsisVertical className="text-primary"/>
          </button>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Personal Information
            </h1>

            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Name:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.customerName}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Email:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.email}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Phone Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.phoneNumber}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Address:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.address}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Date Created:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.date}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="font-[700] text-[20px] leading-[32px] text-[#273240]">
              Car Details
            </h1>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Make:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.make}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Model:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.model}
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Year:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                ({customer?.year})
              </p>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-normal">
              <h2 className="font-[400] text-[14px] leading-[18px] tracking-[0.5px] text-customerNameColor">
                Plate Number:
              </h2>
              <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] tracking-[0.5px]">
                {customer?.plateNumber}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex flex-col items-center gap-4">
          <Button
            type="tertiary"
            borderRadius="rounded-[5px]"
            size="XS"
            label=" Reschedule"
            className="lg:w-auto w-full"
            onClick={handleScheduleAppointmentModalOpen}
          />

          <Button
            type="secondary"
            borderRadius="rounded-[6px]"
            size="XS"
            label="Send Reminder"
            className="w-auto hidden lg:block h-[48px]"
            
          />
        
        </div>
        <div>
          <button ref={buttonRef} onClick={handleDropDown} className="lg:flex hidden">
            <FaEllipsisVertical className="text-primary"/>
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AppointmentDetails