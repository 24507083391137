import React from 'react'

const Pagination = () => {
  return (
    <div className="py-[40px] flex justify-center items-center gap-[9px]">
        <button className="font-inter font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[10px] text-center">Prev</button>
        <button className="w-[27px] h-[27px] bg-[#00325E] rounded-[3px] flex justify-center items-center text-white font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter">1</button>
        <button className="w-[27px] h-[27px] rounded-[3px] flex justify-center items-center border text-[#BCBCBC] border-[#BCBCBC] font-[800] text-[12px] lg:text-[8px]leading-[14px] lg:leading-[9px] text-center font-inter">2</button>
        <button className="w-[27px] h-[27px] rounded-[3px] flex justify-center items-center border text-[#BCBCBC] border-[#BCBCBC] font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter">3</button>
        <button className="w-[27px] h-[27px] rounded-[3px] flex justify-center items-center border text-[#BCBCBC] border-[#BCBCBC] font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter">4</button>
        <button className="w-[27px] h-[27px] rounded-[3px] flex justify-center items-center border text-[#BCBCBC] border-[#BCBCBC] font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[9px] text-center font-inter">5</button>
        <button className="font-inter font-[800] text-[12px] lg:text-[8px] leading-[14px] lg:leading-[10px] text-center">Next</button>
      </div>
  )
}

export default Pagination