import React from "react";
import { IoMdCheckmark } from "react-icons/io";


const PricingBox = ({ price, description, month, plan, perks, isPopular }) => {
  return (
    <div data-aos="zoom-in" className={`font-dmsans flex flex-col items-start gap-[33px] h-auto w-full lg:w-[20vw] rounded-[30px] py-[60px] px-[40px] relative bg-[#F6F9FF] border border-[#D9D9D9] shadow-md `}>
    {isPopular && (
        <div className="absolute top-[27px] right-[26px] w-[110px] h-[36px] rounded-[10px] flex justify-center items-center text-center py-[12px] px-[15px] bg-popularBg text-[#161414] font-[400] text-[10px] leading-[12px]">
          {isPopular}
        </div>
      )}
      <h2 className="text-[#007BD1] font-[700] text-[20px] leading-[25px] md:text-[32px] md:leading-[40px]">
      ${price}{" "}
        <span className="font-[400] text-[14px] leading-[17px] md:text-[16px] md:leading-[20px] text-[#091B3D]">
          /{month}Month
        </span>
      </h2>
      <div className="flex flex-col gap-4">
        <h3 className="font-[700] text-[18px] leading-[22px] md:text-[24px] md:leading-[30px] text-[#161414]">
          {plan}
        </h3>
        <p className="text-[#48494D] font-[400] text-[16px] leading-[24px] md:text-[20px] md:leading-[30px] tracking-[0.3px]">
          {description}
        </p>
      </div>
      <div>
        {perks.map((perk, index) => (
          <div key={index} className="flex items-center gap-[19px] w-full">
            <div className="w-[38px] h-[37px] py-[12px] px-[10px] bg-perkBg rounded-[24px] flex justify-center items-center">
            <IoMdCheckmark className="w-[18px] h-[18px] text-[#161414]"/>
            </div>

            <p className="font-[400] text-[16px] leading-[20px] text-[#5B617A] py-[13px] w-[90%]">
              {perk}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingBox;
