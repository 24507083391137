import React from 'react'
import { IoChevronBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const AuthHeader = () => {
    const navigate = useNavigate();
    const handleBackButton = () => {
      navigate(-1);
    }
  return (
    <div className="relative flex md:gap-[37px] gap-0 md:justify-normal justify-center items-center">
    
    <button className="absolute left-0 top-0 md:static flex justify-center items-center  w-[54px] h-[54px] rounded-[12px] md:border border-[#00325E] bg-[#F9F9F9]">
    <IoChevronBack onClick={handleBackButton} className="w-6 h-6" />
    </button>

      <h1 className="text-[32px] font-[400] leading-[29px] font-miltonian text-[#00325E] my-[10px] text-center md:text-left">
        Hamzaa
      </h1>
      </div>
  )
}

export default AuthHeader