import React , {useState, useRef} from 'react'
import calendar from "../../assets/images/solarcalendar.svg"
import Table from '../Table';
import ExportDocumentButton from '../exportDocumentButton';
import {Component as RevenueChart} from "../../components/charts/RevenueChart"
import { Component as ProfitMarginChart } from '../charts/ProfitMarginChart';
import AnalyticsDropdown from '../analyticsDropdown';
import useClickOutside from '../../utils/customhooks/useClickOutside';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Finance = () => {
  const orders = [
    {id: 1, month: "January", procurementCost: "150,000", operationalCost: "350,000", profit: "100,000", },
    {id: 2, month: "February", procurementCost: "150,000", operationalCost: "350,000", profit: "100,000", },
    {id: 3, month: "March", procurementCost: "150,000", operationalCost: "350,000", profit: "100,000", },
    {id: 4, month: "April", procurementCost: "150,000", operationalCost: "350,000", profit: "100,000", },
    {id: 5, month: "May", procurementCost: "150,000", operationalCost: "350,000", profit: "100,000", },
   
   
]
 // State for dropdown visibility
const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
 const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

// Dropdown actions
const dateActions = [
{ label: "<Custom Range>", onClick: () => console.log("<Custom Range>") },
{ label: "Today", onClick: () => console.log("Filter: Today") },
{ label: "Yesterday", onClick: () => console.log("Filter: Yesterday") },
{ label: "Last 7 days", onClick: () => console.log("Filter: Last 7 days") },
{ label: "Last 30 days", onClick: () => console.log("Filter: Last 30 days") },
{ label: "This Week", onClick: () => console.log("Filter: This Week") },
{ label: "This Month", onClick: () => console.log("Filter: This Month") },
{ label: "Last Month", onClick: () => console.log("Filter: Last Month") },
];


const headers = [
"Serial",
"Month",
"Parts Procurement Costs",
"Operational Costs",
"Total Profits",
];

const renderRow = (item, index, rowClass) => (
<tr key={item.id} className={rowClass}>
  <td
    className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
    style={{
      backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
    }}
  >
    {item.id}
  </td>
  <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
  {item.month}
  </td>
  <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
  ${item.procurementCost}
  </td>
  <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
  ${item.operationalCost}
  </td>
  <td className="lg:py-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
    <div className="flex justify-center">
    ${item.profit}
    </div>
  </td>
</tr>
);
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Analytics - Finance</title>
                <meta name="description" content="Hamzaa Analytics Finance page" />
              </Helmet>
    <div className=''>
    <div className='my-[28px] flex justify-between items-center'>
          <div className="flex items-center gap-4">
          <h2 className='font-[400] text-[20px] leading-[32px] text-[#023979]'>Filter:</h2>
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date Range
              </span>
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <AnalyticsDropdown  actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>

         
        </div>
   <ExportDocumentButton/>
        </div>
        <div className='flex flex-col xl:flex-row items-center gap-8 max-w-full'>
          <RevenueChart/>
          <ProfitMarginChart/>
        </div>
        <div className='mt-8 mb-6'>
        <h2 className='font-[600] text-[16px] leading-[21px] text-[#26262A]'>Orders List</h2>
        </div>
        <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={orders}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
             
      </div>

    </div>
    </HelmetProvider>
  )
}


export default Finance