import React, {useState, useRef} from 'react'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../../assets/images/solarcalendar.svg"
import Table from '../Table';
import {Component as AreaChart }from "../../components/charts/AreaChart"
import ExportDocumentButton from '../exportDocumentButton';
import AnalyticsDropdown from '../analyticsDropdown';
import useClickOutside from '../../utils/customhooks/useClickOutside';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Customers = () => {
    const customers = [
        {id: 1, name: "Saint John Wick", orders: "25 Order(s)", visit: "25 Jun, 24", amount: "500,000"},
        {id: 2, name: "James Gordon Sinner", orders: "25 Order(s)", visit: "25 Jun, 24", amount: "500,000"},
        {id: 3, name: "Saint John Wick", orders: "25 Order(s)", visit: "25 Jun, 24", amount: "500,000"},
        {id: 4, name: "James Gordon Sinner", orders: "25 Order(s)", visit: "25 Jun, 24", amount: "500,000"},
        {id: 5, name: "Saint John Wick", orders: "25 Order(s)", visit: "25 Jun, 24", amount: "500,000"},
    ]
     // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isCustomerTypeDropdownOpen, setIsCustomerTypeDropdownOpen] = useState(false);
 
 const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const customerDropdownRef = useRef(null);
  const customerButtonRef = useRef(null);

  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );
  
  // Handle click outside for Customer dropdown
  useClickOutside([customerDropdownRef, customerButtonRef], () =>
    setIsCustomerTypeDropdownOpen(false));
  // Dropdown actions
  const dateActions = [
    { label: "<Custom Range>", onClick: () => console.log("<Custom Range>") },
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "Yesterday", onClick: () => console.log("Filter: Yesterday") },
    { label: "Last 7 days", onClick: () => console.log("Filter: Last 7 days") },
    { label: "Last 30 days", onClick: () => console.log("Filter: Last 30 days") },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
    { label: "Last Month", onClick: () => console.log("Filter: Last Month") },
  ];

  const customerTypeActions = [
    { label: "New", onClick: () => console.log("Filter: New") },
    { label: "Returning", onClick: () => console.log("Filter: Returning") },
  ];

const headers = [
    "S/N",
    "Customer Name",
    "Orders Placed ",
    "Last Visit Date",
    "Total Amount Spent",
  ];

  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.name}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.orders}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.visit}
      </td>
      <td className="lg:py-4 text-center border-b">
        <div className="flex justify-center">
        ${item.amount}
        </div>
      </td>
    </tr>
  );

  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Analytics - Customer</title>
                <meta name="description" content="Hamzaa Analytics Customer page" />
              </Helmet>
    <div className=''>
    <div className='my-[28px] flex justify-between items-center'>
          <div className="flex items-center gap-4">
          <h2 className='font-[400] text-[20px] leading-[32px] text-[#023979]'>Filters:</h2>
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date Range
              </span>
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <AnalyticsDropdown  actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>

          <div className="relative">
            <button ref={customerButtonRef}
              onClick={() => setIsCustomerTypeDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[14px] leading-[21px] text-center text-[#1C1C1E]">
                Customer Type
              </span>
             {isCustomerTypeDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" /> : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isCustomerTypeDropdownOpen && (
              <div ref={customerDropdownRef}>
              <AnalyticsDropdown  actions={customerTypeActions}
                onClose={() => setIsCustomerTypeDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"/>
                  </div>
            )}
          </div>
         
        </div>
        <ExportDocumentButton/>
        </div>
        <div>
          <AreaChart/>
        </div>
        <div className='mt-8 mb-6'>
        <h2 className='font-[600] text-[16px] leading-[21px] text-[#26262A]'>Customer Activity</h2>
        </div>
        <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={customers}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
             
      </div>

    </div>
    </HelmetProvider>
  )
}

export default Customers