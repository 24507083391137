import React from "react";
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

  const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');

  if (!token) {
    // Redirect to login if not authenticated
    return <Navigate to="/signin" replace />;
  }

  // Render the protected component
  return children;
};

export default ProtectedRoute;
