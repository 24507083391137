import React, { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../avatar";
import { LuUpload } from "react-icons/lu";
import { TbEdit } from "react-icons/tb";
import InputField from "../inputField";
import { VscEyeClosed } from "react-icons/vsc";
import Switch from "../switch";
import { HelmetProvider, Helmet } from "react-helmet-async";

const Account = () => {
  const merchant = useSelector((state) => state.auth.merchant);
  const firstName = merchant?.firstName;
  const lastName = merchant?.lastName;
  const email = merchant?.email;
  const phone = merchant?.phone;
  const [state, setState] = useState({
    isOn: false,
  });
  const toggleSwitch = () => {
    setState((prev) => ({ ...prev, isOn: !prev.isOn }));
  };

  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Settings - My Account</title>
                <meta name="description" content="Hamzaa Settings My Account page" />
              </Helmet>
    <div className="">
      {/* Profile Photo */}
      <div className="w-full h-auto rounded-[8px] mt-6 md:mt-8 mb-4 flex justify-between items-center bg-white p-4 md:p-8 shadow-md">
        <div className="flex items-center gap-[10px] md:gap-8 w-full">
          <Avatar
            firstName={firstName}
            lastName={lastName}
            width="w-[73px] md:w-[111px]"
            height="h-[74px] md:h-[114px]"
            text="text-[20px] md:text-[32px]"
            leading="leading-[26px] md:leading-[42px]"
          />
          <div className="flex flex-col gap-[10px]">
            <h2 className="font-[500] text-[16px] md:text-[24px] leading-[21px] md:leading-[31px] text-[#1C1C1C]">
              Profile Photo
            </h2>
            <p className="font-[400] text-[12px] md:text-[20px] leading-[16px] md:leading-[26px] text-lightGreyBg">
              PNG, JPEG, Max size 10MB
            </p>
          </div>
        </div>
        <div>
          <button className="flex justify-center items-center gap-2 w-auto h-[44px] md:h-[52px] rounded-[8px] p-4 bg-primary">
            <LuUpload className="text-[#FFFCFC] w-[15px] h-[15px] md:w-[20px] md:h-[20px]" />
            <span className="hidden md:block text-center font-[400] text-[12px] leading-[18px] text-[#FFFCFC] text-nowrap">
              Upload Photo
            </span>
          </button>
        </div>
      </div>
      {/* Personal Information */}
      <div className="w-full h-auto rounded-[8px] mt-8 mb-4 flex flex-col gap-8 bg-white p-4 md:p-8 shadow-md">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-[2px]">
            <h2 className="font-[500] text-[20px] leading-[21px] md:text-[24px] md:leading-[31px] text-[#1C1C1C]">
              Personal Information
            </h2>
            <p className="font-[400] text-[14px] md:text-[16px] leading-[18px] md:leading-[21px] text-lightGreyBg">
              Manage your personal information
            </p>
          </div>
          <button className="flex justify-center items-center gap-[10px] w-auto h-[44px] rounded-[8px] py-[10px] px-4 bg-white md:border md:border-[#8E8E93]">
            <span className="hidden md:block text-center font-[500] text-[16px] leading-[21px] text-[#8E8E93] text-nowrap">
              Edit
            </span>
            <TbEdit className="text-[#00325E] w-6 h-6" />
          </button>
        </div>
        <div className="flex">
        <div className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-[6px]">
            <h3 className="font-[400] text-[14px] md:text-[16px] leading-[18px] md:leading-[21px] text-lightGreyBg">
              First Name
            </h3>
            <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px]">
              {firstName}
            </p>
          </div>
          <div className="flex flex-col gap-[6px]">
            <h3 className="font-[400] text-[14px] md:text-[16px] leading-[18px] md:leading-[21px] text-lightGreyBg">
              Email Address
            </h3>
            <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px]">
              {email}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col gap-[6px]">
            <h3 className="font-[400] text-[14px] md:text-[16px] leading-[18px] md:leading-[21px] text-lightGreyBg">
              Last Name
            </h3>
            <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px]">
              {lastName}
            </p>
          </div>
         
          <div className="flex flex-col gap-[6px]">
            <h3 className="font-[400] text-[14px] md:text-[16px] leading-[18px] md:leading-[21px] text-lightGreyBg">
              Phone
            </h3>
            <p className="text-[#1C1C1C] font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px]">
             {phone}
            </p>
          </div>
        </div>
        </div>
      </div>
      {/* Password and Security */}
      <div className="w-full h-auto rounded-[8px] mt-8 mb-4 flex flex-col gap-8 bg-white p-4 md:p-8 shadow-md">
        <h2 className="font-[500] text-[16px] leading-[21px] md:text-[24px] md:leading-[31px] text-[#1C1C1C]">
          Password and Security
        </h2>
        <div className="flex flex-col max-w-full 2xl:flex-row gap-4 2xl:items-end">
          <InputField
            label="Current Password"
            type="text"
            postfix={<VscEyeClosed size={20} className="text-[#949292]" />}
            placeholder="Enter Current Password"
            inputClassName="w-full 2xl:w-[18vw]"
          />

          <InputField
            label="New Password"
            type="text"
            postfix={<VscEyeClosed size={20} className="text-[#949292]" />}
            placeholder="Enter New Password"
            inputClassName="w-full 2xl:w-[18vw]"
          />
          <InputField
            label="Confirm Password"
            type="text"
            postfix={<VscEyeClosed size={20} className="text-[#949292]" />}
            placeholder="Confirm Password"
            inputClassName="w-full 2xl:w-[18vw]"
          />
          <button className="flex justify-center items-center gap-2 w-auto h-[52px] rounded-[8px] p-4 bg-primary">
            <span className="text-center font-[400] text-[12px] leading-[18px] text-[#FFFCFC] text-nowrap">
              Change Password
            </span>
          </button>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-[2px] mt-2">
            <h2 className="font-[500] text-[16px] leading-[21px] md:text-[20px] md:leading-[26px] text-[#1C1C1C]">
              Two-factor authentication (2FA)
            </h2>
            <p className="font-[400] text-[14px] leading-[18px] md:text-[16px] md:leading-[21px] text-lightGreyBg">
              Add an extra layer of Security to your account, along with your
              password, you will need to enter a code
            </p>
          </div>
          <Switch toggleSwitch={toggleSwitch} isOn={state.isOn} />
        </div>
      </div>
    </div>
    </HelmetProvider>
  );
};

export default Account;
