import React, { useState, useRef } from "react";
import tabs from "../utils/dummyData/sidenavTabs";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import ReferHamzaa from "./ReferHamzaa";
import CompanyLogo from "./CompanyLogo";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { GoDotFill } from "react-icons/go";

const Tab = ({
  tabName,
  tabPath,
  activeTab,
  onClick,
  activeIcon,
  inActiveIcon,
  subTabs,
  isOpen,
  toggleDropdown,
  tab
}) => {
  const isActive =
    activeTab === tabName || subTabs?.some((subTab) => subTab.name === activeTab);
    const navigate = useNavigate();
    const handleNavigation = () => {
      navigate(tabPath); // Programmatic navigation
    };
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(tabName); // Activate the tab when clicked
    handleNavigation();
  };

  const handleToggleDropdown = (e) => {
    e.stopPropagation();
    toggleDropdown(tabName); // Toggle dropdown and activate the tab
  };

   // Helper to render icons based on their type
   const renderIcon = (icon) => {
    return typeof icon === "string" ? (
      <img src={icon} alt={`${tabName} icon`} className="w-[20px] h-[20px]" />
    ) : (
      icon // Render JSX directly if it's a React component
    );
  };
 

  return (
    <li className="w-full cursor-pointer">
      <div className="flex flex-col items-center w-full">
        <div
          className={`${
            isActive ? "bg-primary text-white " : "text-primary"
          } rounded-lg w-[230px] flex items-center h-[51px] gap-[19px] py-[15px] px-[10px] transition duration-150`}
          onClick={tab.onClick || handleClick}
        >
        <div>
          <div className="w-full flex items-center gap-2">
          {renderIcon(isActive ? activeIcon : inActiveIcon)}
            <span
              className={` ${
                isActive ? "text-[#FAFDFF]" : "text-[#161414]"
              } text-[16px] leading-[21px] font-normal`}
            >
              {tabName}
            </span>
          </div>
          </div>
          {subTabs && subTabs.length > 0 && (
            <button onClick={handleToggleDropdown} className="ml-auto w-[20px] h-[20px] flex justify-center items-center">
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          )}
        </div>

        {/* Dropdown menu for subtabs */}
        {isOpen && subTabs &&  (
          <ul className="px-[20px] w-full ">
            {subTabs.map((subTab, index) => {
              const isSubTabActive = activeTab === subTab.name;
              return (
                <Link to={subTab.path} className="w-full cursor-pointer" key={index}>
                  <li
                    onClick={() => onClick(subTab.name)} // Activate the sub-tab on click
                    className={`w-full flex items-center rounded-[10px] gap-[10px] h-[51px] px-[10px] py-[15px]  transition duration-150 my-[16px] ${
                      isSubTabActive ? "bg-[#DBE2EF] text-[#1C1C1C]" : "text-primary"
                    } `}
                  >
                  <GoDotFill className="w-[12px] h-[12px] text-primary"/>
               
                    <span className={`font-[400] text-[16px] leading-[21px] ${isSubTabActive ? "bg-[#DBE2EF] text-[#1C1C1C]" : "text-[#1C1C1C]"}`}>
                      {subTab.name}
                    </span>
                  </li>
                </Link>
              );
            })}
          </ul>
        )}
      </div>
    </li>
  );
};

const renderReferralItems = ({openReferralModal}) => [
  // Slide 1: Refer on Hamzaa and Earn
  <ReferHamzaa openReferralModal={openReferralModal} />,
  // Slide 2: Company Logos
  <CompanyLogo />,
];
const SideNav = ({ handleTabChange, activeTab, openReferralModal }) => {
  const [referIndex, setReferIndex] = useState(0);
  const referCarouselRef = useRef(null);
  const nameReferCarouselRef = useRef(null);
  const [openTabs, setOpenTabs] = useState({});
  const mainTabs = tabs.filter(tab => tab.tabName);
  const subHeadingTabs = tabs.filter(tab => !tab.tabName && tab.subHeading);
  const bottomTabs = mainTabs.slice(-3);
  const splitIndex = 2;
  const firstTwoTabs = mainTabs.slice(0, splitIndex);
  const remainingTabs = mainTabs.slice(splitIndex, -3);
  const toggleDropdown = (tabName) => {
    setOpenTabs((prevState) => ({
      ...prevState,
      [tabName]: !prevState[tabName], // Toggle the open state for the specific tab
    }));
    handleTabChange(tabName); // Activate the tab when dropdown is toggled
  };

  const syncReferCarousels = (e) => {
    setReferIndex(e.item);
    if (referCarouselRef.current) {
      referCarouselRef.current.slideTo(e.item);
    }
    if (nameReferCarouselRef.current) {
      nameReferCarouselRef.current.slideTo(e.item);
    }
  };
  const handleTabClick = (tabName) => {
    handleTabChange(tabName); // Activate the main tab
    if (mainTabs.find((tab) => tab.tabName === tabName && tab.subTabs)) {
      toggleDropdown(tabName); // Only toggle dropdown if the tab has sub-tabs
    }
  };

  
  return (
    <div
      className={`fixed hidden overflow-hidden border-r top-0 left-0 w-[319px] z-30 bg-white h-screen overflow-y-auto scrollbar-thin scrollbar-thumb-scrollbar-thumb hover:scrollbar-thumb-scrollbar-thumb-hover scroll-smooth transition-transform pt-8 px-[45px] lg:flex flex-col items-center`}
    >
      <div className="flex flex-col flex-grow ">
        <h1 className="pb-[80px] text-[40px] font-[400] leading-[48px] font-miltonian text-[#00325E]">
          Hamzaa
        </h1>
        <ul className="space-y-6 w-full">
          {/* Render first subHeading */}
          {subHeadingTabs.find(tab => tab.subHeading === "Menu") && (
            <li
              className="text-sm text-[#1C1C1C66] font-[400] text-[14px] leading-[18px] -tracking-[0-43px]"
            >
              Menu
            </li>
          )}
          {/* Render first two tabs */}
          {firstTwoTabs.map((tab) => (
           
            <Tab
              key={tab.tabName}
              tab={tab}
              tabName={tab.tabName}
              tabPath={tab.path}
              activeIcon={tab.activeIcon}
              inActiveIcon={tab.inActiveIcon}
              activeTab={activeTab}
              subTabs={tab.subTabs}
              isOpen={openTabs[tab.tabName]}
              toggleDropdown={() => toggleDropdown(tab.tabName)}
              onClick={handleTabClick}
            />
          ))}
          {/* Render second subHeading */}
          {subHeadingTabs.find(tab => tab.subHeading === "Manage") && (
            <li
              className="text-sm text-[#1C1C1C66] font-[400] text-[14px] leading-[18px] -tracking-[0-43px]"
            >
              Manage
            </li>
          )}
         
          {/* Render remaining tabs */}
          {remainingTabs.map((tab) => (
        
            <Tab
              key={tab.tabName}
              tab={tab}
              tabName={tab.tabName}
              tabPath={tab.path}
              activeIcon={tab.activeIcon}
              inActiveIcon={tab.inActiveIcon}
              activeTab={activeTab}
              subTabs={tab.subTabs}
              isOpen={openTabs[tab.tabName]}
              toggleDropdown={() => toggleDropdown(tab.tabName)}
              onClick={handleTabClick}
            />
          ))}
        </ul>
      </div>
      <div className="hidden md:flex justify-center items-center mt-[50px] w-full">
        <AliceCarousel
          mouseTracking
          items={renderReferralItems({openReferralModal})}
          controlsStrategy="responsive"
          keyboardNavigation={true}
          disableButtonsControls={true}
          autoPlay={true}
          autoPlayInterval={3000}
          activeIndex={referIndex}
          onSlideChanged={syncReferCarousels}
          ref={referCarouselRef}
          infinite={true}
          responsive={{
            0: { items: 1 }, // Show 1 combined item on smaller screens
            768: { items: 1 }, // Show 1 combined item on medium and larger screens
          }}
        />
      </div>
      <div className="flex items-end mt-[40px]">
        {/* Place the last two items here */}
        <ul className="space-y-6 w-full py-[32px]">
          {bottomTabs.map((tab, index) =>
           tab.subHeading ? (
              <li
                key={index}
                className="text-sm text-[#1C1C1C66] font-[400] text-[14px] leading-[18px] -tracking-[0-43px]"
              >
                {tab.subHeading}
              </li>
            ) : (
              <Tab
                key={tab.tabName}
                tab={tab}
                tabName={tab.tabName}
                activeIcon={tab.activeIcon}
                inActiveIcon={tab.inActiveIcon}
                activeTab={activeTab}
                subTabs={tab.subTabs}
                isOpen={openTabs[tab.tabName]}
                toggleDropdown={() => toggleDropdown(tab.tabName)}
                onClick={handleTabClick}
              />
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
