import React, {useState, useRef} from 'react'
import { IoArrowRedoOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Csv02Icon } from 'hugeicons-react';
import { Pdf02Icon } from 'hugeicons-react';
import { Icon } from '@iconify/react';
import AnalyticsDropdown from './analyticsDropdown';
import useClickOutside from '../utils/customhooks/useClickOutside';



const ExportButton = ({name, icon}) => {
  return (
      <button className="flex items-center gap-2 rounded-[8px] bg-white">
{icon}
          <span className="text-center font-[400] text-[12px] leading-[18px] text-[#00325E]">
         {name}
          </span>
        </button>
  )
}
const ExportDocumentButton = ({exportActions}) => {
  const exportDefaultActions = [
    { label: <ExportButton name="CSV" icon={<Csv02Icon className='text-primary'/>} />, onClick: () => console.log("Filter: CSV") },
    { label:<ExportButton name="PDF" icon={<Pdf02Icon className='text-primary'/>} />, onClick: () => console.log("Filter: PDF") },
    { label: <ExportButton name="Excel" icon={<Icon icon="healthicons:excel-logo-outline" className='text-primary w-5 h-5'/>} />, onClick: () => console.log("Filter: Excel") },
   
]
 const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

     const [isExportDropdownOpen, setIsExportDropdownOpen] = useState(false);
     useClickOutside([dropdownRef, buttonRef], () => setIsExportDropdownOpen(false));
  return (
    <div className='relative'>
        <button ref={buttonRef} onClick={() => setIsExportDropdownOpen((prev) => !prev)} className="flex justify-around items-center gap-2 w-auto h-[52px] rounded-[8px] p-4 shadow-sm bg-primary">
        <IoArrowRedoOutline className="text-[#FFFCFC] w-[16px] h-[16px]" />
            <span className="text-center font-[400] text-[12px] leading-[18px] text-[#FFFCFC]">
           Export
            </span>
            <button className="flex justify-center items-center">
             {isExportDropdownOpen ?  <IoIosArrowUp className="text-[#FFFCFC] w-[16px] h-[16px]" /> : <IoIosArrowDown className="text-[#FFFCFC] w-[16px] h-[16px]" />}
            </button> 
          </button>
          {isExportDropdownOpen && 
          <div ref={dropdownRef}>
          <AnalyticsDropdown actions={exportDefaultActions || exportActions}
                onClose={() => setIsExportDropdownOpen(false)}
                position="top-[60px]"
                searchable={false} 
                  width="w-full"
                    exportButton={true}
                  /> 
                  </div>
          }
          </div>
  )
}

export default ExportDocumentButton