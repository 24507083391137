import React, { useState } from "react";
import Switch from "../switch";
import ISO6391 from "iso-639-1";
import InputField from "../inputField";
import { CiGlobe } from "react-icons/ci";
import timeZones from "../../utils/dummyData/timeZones";
import { HelmetProvider, Helmet } from "react-helmet-async";


const LanguageDropdown = () => {
  const [language, setLanguage] = useState("");
  const languages = ISO6391.getAllNames();
  return (
    <InputField
    name="language"
    type="select"
    options={languages.map((language) => ({
    label: language,
    value: language.toLowerCase(), 
  }))}
    searchable={true}
    placeholder="Select Language"
    className="w-full flex flex-col gap-2"
    selectClassName="w-full text-nowrap md:w-[20vw] flex justify-center items-center gap-[6px] md:gap-0 rounded-[10px] h-[56px] border-none md:border"
    handleValue={setLanguage}
    value={language}
    prefix={<CiGlobe className="hidden md:block w-5 h-5" />}
  />
  
  );
};
const TimeDropdown = () => {
  const [time, setTime] = useState("");

  return (
    <InputField
    name="timezone"
    type="select"
    options={timeZones.map((timeZone) => ({
    label: timeZone,
    value: timeZone.toLowerCase(), 
  }))}
    searchable={true}
    placeholder="Select Time Zone"
    className="w-full flex flex-col gap-2"
    selectClassName="w-full md:w-[20vw] flex justify-center items-center text-nowrap border-none gap-[6px] md:gap-0 md:border rounded-[10px] h-[56px]"
    handleValue={setTime}
    value={time}
  />
  
  );
};
const SystemSettingsBox = ({ heading, paragraph, content }) => {
  return (
    <div className="flex justify-between gap-[50px] items-center w-full">
      <div className="flex flex-col gap-[2px] mt-2">
        <h2 className="font-[500] text-[14px] leading-[18px] md:text-[20px] md:leading-[26px] text-[#1C1C1C]">
          {heading}
        </h2>
        <p className="font-[400] text-[14px] leading-[18px] md:text-[16px] md:leading-[21px] text-lightGreyBg">
          {paragraph}
        </p>
      </div>
      <div>
      {content}
      </div>
    </div>
  );
};
const SystemSettings = () => {
  const [state, setState] = useState({
    isTimeZoneOn: false,
    isAppearanceOn: false,
  });
  const toggleSwitch = (key) => {
    setState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Settings - System Settings</title>
                <meta name="description" content="Hamzaa Settings - System Settings page" />
              </Helmet>
    <div className="w-full h-auto rounded-[8px] mt-8 mb-4 flex justify-between items-center bg-white p-8 shadow-md">
      <div className="flex flex-col gap-10 w-full">
      <SystemSettingsBox
          heading="Language"
          paragraph="Select the language of your choice from the list"
          content={
           <LanguageDropdown/>
          }
        />
        <SystemSettingsBox
          heading="Automatic Time Zone"
          paragraph="Automatically set time zone using location, toggle to change."
          content={
            <Switch
              isOn={state.isTimeZoneOn}
              toggleSwitch={() => toggleSwitch("isTimeZoneOn")}
            />
          }
        />
         <SystemSettingsBox
          heading="Manual Time Zone"
          paragraph="Select the time zone of your choice from the list"
          content={
           <TimeDropdown/>
          }
        />
        <SystemSettingsBox
          heading="Appearance"
          paragraph="Toggle to change to your desired look, toggle on to switch from current mode"
          content={
            <Switch
            appearance={true}
              isOn={state.isAppearanceOn}
              toggleSwitch={() => toggleSwitch("isAppearanceOn")}
            />
          }
        />
      </div>
    </div>
    </HelmetProvider>
  );
};

export default SystemSettings;
