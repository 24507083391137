import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate, Outlet, Link } from "react-router-dom";
import SideNav from "./components/sideNav";
import Header from "./components/header";
import Overview from "./pages/overview";
import Appointments from "./pages/appointment";
import Inventory from "./pages/inventory";
import VINLookup from "./pages/vinLookup";
import Analytics from "./pages/analytics";
import AddCustomer from "./components/addCustomer";
import CustomerList from "./pages/customerList";
import CustomersServiceHistory from "./components/customersServiceHistory";
import Invoice from "./components/invoiceList";
import PartOrderingPage from "./pages/partOrdering";
import Supplier from "./components/supplier";
import tabs from "./utils/dummyData/sidenavTabs";
import Notification from "./components/notification";
import CustomerDetails from "./components/customerDetails";
import ScheduleAppointmentModal from "./components/scheduleAppointmentModal";
import CustomerServiceHistory from "./components/customerServiceHistory";
import AddService from "./components/addService";
import AddInventory from "./components/addInventory";
import ServiceDetailsModal from "./components/serviceDetailsModal";
import SignUp from "./auth/SignUp";
import SignIn from "./auth/SignIn";
import VerifyOTP from "./auth/VerifyOTP";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import MobileHeader from "./components/MobileHeader";
import SideNavModal from "./components/SideNavModal";
import EditAppointment from "./components/editAppointment";
import AppointmentDetails from "./components/appointmentDetails";
import EditInventory from "./components/editInventory";
import QuickBooks from "./components/QuickBooks";
import CreateInvoice from "./components/createInvoice";
import EditCustomer from "./components/editCustomer";
import CreateCustomerInvoice from "./components/createCustomerInvoice";
import UserDropdown from "./components/userDropdown";
import VinDetails from "./components/vinDetails";
import ReferralModal from "./components/referralModal";
import { Toaster } from "sonner";
import InvoiceDetails from "./components/invoiceDetails";
import Customers from "./components/analytics/Customers";
import AnalyticsInventory from "./components/analytics/Inventory";
import ShopPerformance from "./components/analytics/ShopPerformance";
import Finance from "./components/analytics/Finance";
import PartOrdering from "./components/analytics/PartOrdering";
import Settings from "./pages/settings";
import Account from "./components/settings/account";
import PlanBillings from "./components/settings/planBillings";
import NotifcationsAlerts from "./components/settings/notifcationsAlerts";
import Integrations from "./components/settings/integrations";
import SystemSettings from "./components/settings/systemSettings";
import PlanOrderReceipt from "./components/planOrderReceipt";
import UpgradePlan from "./components/upgradePlan";
import OrderHistory from "./components/orderHistory";
import { Provider } from "react-redux";
import store from "./store";
import VerifyEmail from "./auth/VerifyEmail";
import ProtectedRoute from "./routes/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import Error from "./error";
import IdleLogout from "./idleLogout";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404 - Page Not Found</h1>
      <Link to="/" >Redirect to home page...</Link>
    </div>
  );
};

const App = () => {
  const [isBlackMode, setIsBlackMode] = useState(() => {
    // Check localStorage for a saved theme, default to light mode
    return localStorage.getItem("theme") === "light";
  });

  // Apply theme to the root element whenever it changes
  useEffect(() => {
    const root = document.documentElement;
    if (!isBlackMode) {
      root.classList.add("black");
      localStorage.setItem("theme", "black");
    } else {
      root.classList.remove("black");
      localStorage.setItem("theme", "light");
    }
  }, [isBlackMode]);

  const location = useLocation();
  const [userDropdown, setUserDropdown] = useState(false);
  const toggleUserDropdown = (event) => {
    event.stopPropagation();
    setUserDropdown(!userDropdown);
  };
  // Function to find the current active tab based on path
  const findActiveTab = (path) => {
    const activeTab = tabs.find(
      (tab) =>
        tab.path === path ||
        (tab.subTabs &&
          tab.subTabs.some((subTab) => path.startsWith(subTab.path)))
    );

    if (activeTab) {
      const activeSubTab = activeTab.subTabs?.find((subTab) =>
        path.startsWith(subTab.path)
      );
      return activeSubTab ? activeSubTab.name : activeTab.tabName;
    }
    return null;
  };

  const findHeaderTitle = (path) => {
    const tab = tabs.find(
      (t) =>
        t.path === path ||
        (t.subTabs && t.subTabs.some((subTab) => path.startsWith(subTab.path)))
    );

    if (tab) {
      const activeSubTab = tab.subTabs?.find((subTab) =>
        path.startsWith(subTab.path)
      );
      return {
        title: tab.headerTitle || "",
        subTab: activeSubTab?.name || "",
        subHeading: tab.subHeading || "",
      };
    }
    return { title: "Overview", subTab: "", subHeading: "Menu" };
  };

  const {
    title: headerTitle,
    subHeading,
    subTab,
  } = findHeaderTitle(location.pathname);
  const activeTab = findActiveTab(location.pathname);

  useEffect(() => {
    localStorage.setItem("headerTitle", JSON.stringify(headerTitle));
  }, [headerTitle]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [modalStates, setModalStates] = useState({
    addCustomerModalOpen: false,
    addServiceModalOpen: false,
    scheduleAppointmentModalOpen: false,
    serviceDetailsModalOpen: false,
    addInventoryModalOpen: false,
    editAppointmentModalOpen: false,
    appointmentDetailsModalOpen: false,
    editInventoryModalOpen: false,
    navbarModalOpen: false,
    createInvoiceModalOpen: false,
    editCustomerModalOpen: false,
    vinDetailsModalOpen: false,
    referralModalOpen: false,
    invoiceDetailsModalOpen: false,
    planOrderReceiptModalOpen: false,
    upgradePlanModalOpen: false,
  });

  const setModalState = (modalName, value) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: value,
    }));
  };

  const closeModal = (modalName) => setModalState(modalName, false);
  const openModal = (modalName) => setModalState(modalName, true);

  useEffect(() => {
    const anyModalOpen = Object.values(modalStates).some(Boolean);
    document.body.style.overflow = anyModalOpen ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [modalStates]);

  return (
    <Provider store={store}>
    <AuthProvider>
    <IdleLogout/>
      <div className="max-w-full ">
        <Toaster position="top-right" />
        {modalStates.referralModalOpen && (
          <ReferralModal closeModal={() => closeModal("referralModalOpen")} />
        )}
        {modalStates.vinDetailsModalOpen && (
          <VinDetails closeModal={() => closeModal("vinDetailsModalOpen")} />
        )}
        {userDropdown && (
          <UserDropdown
            openReferralModal={() => openModal("referralModalOpen")}
            onClose={() => setUserDropdown(false)}
          />
        )}
        {modalStates.addCustomerModalOpen && (
          <AddCustomer closeModal={() => closeModal("addCustomerModalOpen")} />
        )}
        {modalStates.addServiceModalOpen && (
          <AddService closeModal={() => closeModal("addServiceModalOpen")} />
        )}
        {modalStates.scheduleAppointmentModalOpen && (
          <ScheduleAppointmentModal
            closeModal={() => closeModal("scheduleAppointmentModalOpen")}
          />
        )}
        {modalStates.serviceDetailsModalOpen && (
          <ServiceDetailsModal
            closeModal={() => closeModal("serviceDetailsModalOpen")}
            customer={selectedCustomer}
          />
        )}
        {modalStates.editAppointmentModalOpen && (
          <EditAppointment
            closeModal={() => closeModal("editAppointmentModalOpen")}
          />
        )}
        {modalStates.appointmentDetailsModalOpen && (
          <AppointmentDetails
            closeModal={() => closeModal("appointmentDetailsModalOpen")}
            customer={selectedCustomer}
            openScheduleModal={() => openModal("scheduleAppointmentModalOpen")}
          />
        )}
        {modalStates.addInventoryModalOpen && (
          <AddInventory
            closeModal={() => closeModal("addInventoryModalOpen")}
          />
        )}
        {modalStates.editInventoryModalOpen && (
          <EditInventory
            closeModal={() => closeModal("editInventoryModalOpen")}
          />
        )}
        {modalStates.createInvoiceModalOpen && (
          <CreateInvoice
            closeModal={() => closeModal("createInvoiceModalOpen")}
          />
        )}
        {modalStates.editCustomerModalOpen && (
          <EditCustomer
            closeModal={() => closeModal("editCustomerModalOpen")}
          />
        )}
        {modalStates.planOrderReceiptModalOpen && (
          <PlanOrderReceipt
            closeModal={() => closeModal("planOrderReceiptModalOpen")}
          />
        )}
        {modalStates.upgradePlanModalOpen && (
          <UpgradePlan closeModal={() => closeModal("upgradePlanModalOpen")} />
        )}
      
        <Routes>
  <Route path="/signup" element={<SignUp />} />
  <Route path="/signin" element={<SignIn />} />
  <Route path="/otp" element={<VerifyOTP />} />
  <Route path="/verify-email" element={<VerifyEmail />} />
  <Route path="/forgot-password" element={<ForgotPassword />} />
  <Route path="/reset-email" element={<ResetPassword />} />
<Route path="/error" element={<Error/>} />
  {/* Protected Routes */}
  <Route element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
 
                  <Route
              path="*"
              element={
                <>
                  {modalStates.navbarModalOpen && (
                    <SideNavModal
                      handleTabChange={() => {}}
                      activeTab={activeTab}
                      closeModal={() => closeModal("navbarModalOpen")}
                      openReferralModal={() => openModal("referralModalOpen")}
                    />
                  )}
                  <SideNav
                    openReferralModal={() => openModal("referralModalOpen")}
                    handleTabChange={() => {}}
                    activeTab={activeTab}
                  />
                  <div className="ml-0 lg:ml-[319px]">
                    <MobileHeader
                      openNavbarModal={() => openModal("navbarModalOpen")}
                    />
                    <Header
                      headerTitle={headerTitle}
                      subHeading={subHeading}
                      subTab={subTab}
                      toggleDropdown={toggleUserDropdown}
                      tabs={tabs}
                      setIsDarkMode={setIsBlackMode}
                      isDarkMode={isBlackMode}
                    />
               
      <Routes>        
    <Route path="/" element={<Overview openReferralModal={() => openModal("referralModalOpen")} openScheduleModal={() => openModal("scheduleAppointmentModalOpen")} />} />
    <Route path="/overview" element={<Overview openAddCustomerModal={() => openModal("addCustomerModalOpen")} />} />
    <Route path="/notifications" element={<Notification />} />
    <Route path="/customers/list" element={<CustomerList openAddCustomerModal={() => openModal("addCustomerModalOpen")} />} />
    <Route path="/customers/list/details/:id" element={<CustomerDetails openCreateInvoiceModal={() => openModal("createInvoiceModalOpen")} openScheduleModal={() => openModal("scheduleAppointmentModalOpen")} openEditCustomerModal={() => openModal("editCustomerModalOpen")} />} />
    <Route path="/customers/list/details/:id/service-history" element={<CustomerServiceHistory openServiceDetailsModal={() => openModal("serviceDetailsModalOpen")} setSelectedCustomer={setSelectedCustomer} openAddServiceModal={() => openModal("addServiceModalOpen")} />} />
    <Route path="/customers/service-history" element={<CustomersServiceHistory openAddServiceModal={() => openModal("addServiceModalOpen")} />} />
    <Route path="/customers/invoice" element={<Invoice />} />
    <Route path="/customers/invoice/create" element={<CreateCustomerInvoice />} />
    <Route path="/customers/invoice/details/:id" element={<InvoiceDetails />} />
    <Route path="/appointments" element={<Appointments setSelectedCustomer={setSelectedCustomer} openScheduleModal={() => openModal("scheduleAppointmentModalOpen")} openEditAppointmentModal={() => openModal("editAppointmentModalOpen")} openAppointmentDetailsModal={() => openModal("appointmentDetailsModalOpen")} />} />
    <Route path="/inventory" element={<Inventory openAddInventoryModal={() => openModal("addInventoryModalOpen")} openEditInventoryModal={() => openModal("editInventoryModalOpen")} />} />
    <Route path="/part-ordering/*" element={<PartOrderingPage />}>
      <Route index element={<Navigate to="history" replace />} />
      <Route path="history" element={<OrderHistory />} />
      <Route path="supplier" element={<Supplier />} />
    </Route>
    <Route path="/vin-lookup" element={<VINLookup openVinModal={() => openModal("vinDetailsModalOpen")} />} />
    <Route path="/settings" element={<Settings />}>
      <Route index element={<Navigate to="account" replace />} />
      <Route path="account" element={<Account />} />
      <Route path="plan-billings" element={<PlanBillings openUpgradePlanModal={() => openModal("upgradePlanModalOpen")} openReceiptModal={() => openModal("planOrderReceiptModalOpen")} />} />
      <Route path="notifications-alerts" element={<NotifcationsAlerts />} />
      <Route path="integrations" element={<Integrations />} />
      <Route path="system-settings" element={<SystemSettings />} />
    </Route>
    <Route path="/reports/analytics/*" element={<Analytics />}>
      <Route index element={<Navigate to="customers" replace />} />
      <Route path="customers" element={<Customers />} />
      <Route path="inventory" element={<AnalyticsInventory />} />
      <Route path="shop-performance" element={<ShopPerformance />} />
      <Route path="finance" element={<Finance />} />
      <Route path="parts-ordering" element={<PartOrdering />} />
    </Route>
    <Route path="/reports/quickbooks" element={<QuickBooks />} />
    </Routes>  
    </div>
    </>
              }
            />
            
                   
          </Route>

  {/* Fallback Route */}
  <Route path="*" element={<NotFound />} />
</Routes>
      </div>
      </AuthProvider>
    </Provider>
  );
};

export default App;
