import React, { useState, useRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import calendar from "../../assets/images/solarcalendar.svg";
import Table from "../Table";
import ExportDocumentButton from "../exportDocumentButton";
import { Component as MultipleOrderChart } from "../../components/charts/MultipleOrderChart";
import AnalyticsDropdown from "../analyticsDropdown";
import useClickOutside from "../../utils/customhooks/useClickOutside";
import { HelmetProvider, Helmet } from "react-helmet-async";

const PartOrdering = () => {
  const inventory = [
    {
      id: 1,
      part: "Engine Oil Filter (ACME)",
      qtyOrdered: "125 Order(s)",
      date: "25 Jun, 24",
      supplier: "Acme Auto Parts",
    },
    {
      id: 2,
      part: "Engine Oil Filter (ACME)",
      qtyOrdered: "125 Order(s)",
      date: "25 Jun, 24",
      supplier: "Acme Auto Parts",
    },
    {
      id: 3,
      part: "Engine Oil Filter (ACME)",
      qtyOrdered: "125 Order(s)",
      date: "25 Jun, 24",
      supplier: "Acme Auto Parts",
    },
    {
      id: 4,
      part: "Engine Oil Filter (ACME)",
      qtyOrdered: "125 Order(s)",
      date: "25 Jun, 24",
      supplier: "Acme Auto Parts",
    },
    {
      id: 5,
      part: "Engine Oil Filter (ACME)",
      qtyOrdered: "125 Order(s)",
      date: "25 Jun, 24",
      supplier: "Acme Auto Parts",
    },
  ];
  // State for dropdown visibility
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isSupplierDropdownOpen, setIsSupplierDropdownOpen] = useState(false);

  const dateDropdownRef = useRef(null);
  const dateButtonRef = useRef(null);

  const categoryDropdownRef = useRef(null);
  const categoryButtonRef = useRef(null);

  const supplierDropdownRef = useRef(null);
  const supplierButtonRef = useRef(null);
  // Handle click outside for Date dropdown
  useClickOutside([dateDropdownRef, dateButtonRef], () =>
    setIsDateDropdownOpen(false)
  );

  // Handle click outside for Category dropdown
  useClickOutside([categoryDropdownRef, categoryButtonRef], () =>
    setIsCategoryDropdownOpen(false));

  // Handle click outside for Supplier dropdown
  useClickOutside([supplierDropdownRef, supplierButtonRef], () =>
    setIsSupplierDropdownOpen(false));
  
  // Dropdown actions
  const dateActions = [
    { label: "<Custom Range>", onClick: () => console.log("<Custom Range>") },
    { label: "Today", onClick: () => console.log("Filter: Today") },
    { label: "Yesterday", onClick: () => console.log("Filter: Yesterday") },
    { label: "Last 7 days", onClick: () => console.log("Filter: Last 7 days") },
    {
      label: "Last 30 days",
      onClick: () => console.log("Filter: Last 30 days"),
    },
    { label: "This Week", onClick: () => console.log("Filter: This Week") },
    { label: "This Month", onClick: () => console.log("Filter: This Month") },
    { label: "Last Month", onClick: () => console.log("Filter: Last Month") },
  ];

  const categoryActions = [
    { label: "Category 1", onClick: () => console.log("Filter: Category 1") },
    { label: "Category 2", onClick: () => console.log("Filter: Category 2") },
  ];
  const supplierActions = [
    { label: "Supplier 1", onClick: () => console.log("Filter: Supplier 1") },
    { label: "Supplier 2", onClick: () => console.log("Filter: Supplier 2") },
  ];

  const headers = ["S/N", "Part", "Quantity Ordered", "Order Date", "Supplier"];

  const renderRow = (item, index, rowClass) => (
    <tr key={item.id} className={rowClass}>
      <td
        className={`border-b font-[400] text-[14px] leading-[21px] text-strokeColor px-6 py-4 my-2 text-left text-nowrap sticky left-0 z-20`}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
        }}
      >
        {item.id}
      </td>
      <td className="border-b font-[400] text-[14px] text-center leading-[21px] text-strokeColor text-nowrap px-4 lg:py-4 my-2">
        {item.part}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.qtyOrdered}
      </td>
      <td className="lg:py-4 px-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap ">
        {item.date}
      </td>
      <td className="lg:py-4 text-center border-b font-[400] text-[14px] leading-[21px] text-strokeColor text-nowrap">
        <div className="flex justify-center">{item.supplier}</div>
      </td>
    </tr>
  );
  return (
      <HelmetProvider>
              <Helmet>
                <title>Hamzaa Merchant | Analytics - Parts Ordering</title>
                <meta name="description" content="Hamzaa Analytics Parts Ordering page" />
              </Helmet>
    <div className="">
      <div className="my-[28px] flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="font-[400] text-[20px] leading-[32px] text-[#023979]">
            Filters:
          </h2>
          <div className="relative">
            <button ref={dateButtonRef}
              onClick={() => setIsDateDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <img
                src={calendar}
                alt="Calendar icon"
                className="w-[20px] h-[20px]"
              />
              <span className="font-[300] text-[14px] leading-[21px] text-center font-montserrat text-[#1C1C1E]">
                Date Range
              </span>
            </button>
            {isDateDropdownOpen && (
              <div ref={dateDropdownRef}>
              <AnalyticsDropdown
                actions={dateActions}
                onClose={() => setIsDateDropdownOpen(false)}
                position="top-[60px]"
                searchable={false}
                width="w-full"
              />
              </div>
            )}
          </div>

          <div className="relative">
            <button ref={categoryButtonRef}
              onClick={() => setIsCategoryDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[14px] leading-[21px] text-center text-[#1C1C1E]">
                Category
              </span>
             {isCategoryDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" /> : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isCategoryDropdownOpen && (
              <div ref={categoryDropdownRef}>
              <AnalyticsDropdown
                actions={categoryActions}
                onClose={() => setIsCategoryDropdownOpen(false)}
                position="top-[60px]"
                searchable={false}
                width="w-full"
              />
              </div>
            )}
          </div>
          <div className="relative">
            <button ref={supplierButtonRef}
              onClick={() => setIsSupplierDropdownOpen((prev) => !prev)}
              className="flex items-center gap-1 border-[1px] border-dateBorder w-auto h-[45px] rounded-[8px] py-[12px] px-[30px] shadow-sm bg-[#FAFDFF]"
            >
              <span className="font-[400] text-[14px] leading-[21px] text-center text-[#1C1C1E]">
                Supplier
              </span>
             {isSupplierDropdownOpen ? <IoIosArrowUp className="text-[#1C1C1E] w-[16px] h-[16px]" /> : <IoIosArrowDown className="text-[#1C1C1E] w-[16px] h-[16px]" />}
            </button>
            {isSupplierDropdownOpen && (
              <div ref={supplierDropdownRef}>
              <AnalyticsDropdown
                actions={supplierActions}
                onClose={() => setIsSupplierDropdownOpen(false)}
                position="top-[60px]"
                searchable={false}
                width="w-full"
              />
              </div>
            )}
          </div>
        </div>
        <ExportDocumentButton />
      </div>
      <div>
        <MultipleOrderChart />
      </div>
      <div className="mt-8 mb-6">
        <h2 className="font-[600] text-[16px] leading-[21px] text-[#26262A]">
          Orders List
        </h2>
      </div>
      <div className=" w-full bg-white ">
        <Table
          headers={headers}
          data={inventory}
          renderRow={renderRow}
          boxClassName="overflow-y-auto h-full overflow-x-auto"
          pagination={true}
        />
      </div>
    </div>
    </HelmetProvider>
  );
};

export default PartOrdering;
