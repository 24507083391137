import { useState, useRef } from "react";
import Tesseract from "tesseract.js";

const useCameraScanner = () => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [scannedText, setScannedText] = useState("");
  const webcamRef = useRef(null);

  const toggleCamera = () => {
    setIsCameraOpen((prev) => !prev);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      await processImage(imageSrc);
      toggleCamera(); // Close the camera after capturing
    }
  };

  const processImage = async (imageSrc) => {
    setIsProcessing(true);
    try {
      const { data: { text } } = await Tesseract.recognize(imageSrc, "eng", {
        logger: (info) => console.log(info), // Optional: Log OCR progress
      });
      setScannedText(text.trim());
    } catch (error) {
      console.error("OCR Error:", error);
      setScannedText("Error scanning image.");
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isCameraOpen,
    toggleCamera,
    webcamRef,
    captureImage,
    isProcessing,
    scannedText,
  };
};

export default useCameraScanner;
